<template>
  <el-select v-model="data.__config__.defaultValue" ref="mySelect" :placeholder="data.placeholder"
    :multiple="data.multiple" :filterable="data.filterable" :clearable="data.clearable" :style="data.style"
    :disabled="data.disabled" @change="sendValue">
    <el-option v-for="(item, index) in data.__slot__.options" :key="index" :label="item.label" :value="item.value"
      :disabled="item.disabled"></el-option>
  </el-select>
</template>

<script>
import { getFieldData } from "@/api/app";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  name: "MySelect",
  data () {
    return {};
  },
  watch: {
    data: {
      handler (val) { },
    },
  },

  created () {
    if (this.data.valueType === "dataActive") {
      this.$bus.$emit("dataLink", this.data);
    }
    if (this.data.valueType === "relation") {
      // this.$bus.$emit("relatedFun", this.data)

      this.getselectData();
    }
    this.sendValue();
    setTimeout(() => {
      this.xygzFun();
    }, 300);
  },
  mounted () { },
  methods: {
    getselectData () {
      getFieldData(
        this.data.rlatedFields.formId,
        this.data.rlatedFields.fileds
      ).then((res) => {
        if (res.code == 200) {
          this.data.__slot__.options = [];
          res.data.forEach((item) => {
            if (item) {
              if (item.child) {
                item.child.forEach((itemA) => {
                  if (itemA) {
                    this.data.__slot__.options.push({
                      label: itemA,
                      value: item,
                    });
                  }
                });
              } else {
                this.data.__slot__.options.push({
                  label: item,
                  value: item,
                });
              }
            }
          });
        }
      });
    },
    xygzFun () {
      if (
        this.data.regulation &&
        this.data.regulation.length > 0 &&
        this.data.regulation[0].name !== ""
      ) {
        let arr = [];
        let nowobj = {};
        this.data.regulation.forEach((item, index) => {
          if (item.name !== "") {
            if (item.name === this.data.__config__.defaultValue) {
              nowobj = {
                value: item.value,
                isshow: true,
              };
            } else {
              arr.push({
                value: item.value,
                isshow: false,
              });
            }
          }
        });
        if (nowobj.value) {
          arr.push(nowobj);
        }
        this.$bus.$emit("xygz", arr, this.data);
      }
    },
    sendValue (val) {
      let dataArr = [];
      if (this.data.multiple) {
        this.data.__slot__.options.forEach((item) => {
          let str = this.data.__config__.defaultValue.indexOf(item.value);
          if (str !== -1) {
            dataArr.push(item.value);
          }
        });
        const list = [...new Set(dataArr)];
        this.$set(this.data.__config__, "defaultValue", list);
      }
      let obj = {
        name: this.data.__vModel__,
        value: this.data.__config__.defaultValue,
      };
      this.xygzFun();
      this.$emit("value", obj);
    },
  },
};
</script>

<style scoped></style>
