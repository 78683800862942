<template>
  <div>
    <div class="btn" @click="openDataselect">
      点击选择数据
    </div>
    <div>
      <dataDetails :data="valueData" :headData="headData" :formId="formId"></dataDetails>
    </div>
    <el-dialog title="选择数据" :visible.sync="dataVisible" width="30%" append-to-body :modal-append-to-body="false"
      class="dialogBox">
      <relatedDatatable :valueDataId="valueDataId" :queryParams="queryParams" :dataVisible="dataVisible"
        @handleClose="handleClose" @handleSubmit="handleSubmit" @getData="getData" :formId="gl_formId"
        :appId="data.gl_table_appId">
      </relatedDatatable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getAppFormData } from '@/api/app'
import relatedDatatable from "@/components/relatedDatatable"
import dataDetails from "@/components/relatedDatatable/dataDetails.vue"
export default {
  data () {
    return {
      dataVisible: false,
      queryParams: {},
      valueData: [],
      headData: [],
      selectList: [],
      // 被关联的表单id
      gl_formId: '',
      gl_data: {},
      valueDataId: ""

    }
  },
  components: {
    relatedDatatable,
    dataDetails
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    formDataFields: {
      type: Object,
    },
    formId: {
      type: String,
    },
  },
  watch: {
    'data': {
      handler (val) {
        if (val) {
          this.headData = val.gl_field_filter

        }
      },
      immediate: true,
    },
    formDataFields: {
      handler (val) {
      }
    },
    "data.__config__.defaultValue.valueData": {
      handler (val) {
        if (val) {
          this.valueData = val
          this.valueDataId = this.valueData._id

        }
      }
    }
  },
  methods: {
    openDataselect () {
      this.gl_formId = this.data.gl_table_value
      this.queryParams = {
        app_id: this.data.gl_table_appId,
        form_id: this.data.gl_table_value,
        fields: this.data.gl_field_filter,
      }
      if (this.data.relationData.conditionList.length > 0) {
        this.data.relationData.conditionList.forEach(item => {
          this.formDataFields.fields.forEach(item1 => {
            if (item.filterData.zd_data === item1.__vModel__) {
              if (item1.__config__.defaultValue) {
                item.filterData.value = item1.__config__.defaultValue
              }
            }
          })
        })
        this.queryParams.filter = this.data.relationData
      }

      if (this.valueData) {
        this.valueDataId = this.valueData._id
      }
      this.dataVisible = true
    },
    handleClose (val) {
      this.dataVisible = false
    },
    getData (val) {
      this.selectList = val
    },
    handleSubmit () {
      this.valueData = this.selectList
      this.dataVisible = false
      this.gl_data = {
        entryId: this.gl_formId,
        id: this.valueData._id,
        primaryKey: this.data.primaryKey,
        valueData: this.valueData
      }
      let obj = {
        name: this.data.__vModel__,
        value: this.gl_data
      }

      this.$emit("value", obj)
    }

  },
  mounted () {

  },

}
</script>
<style lang="scss" scoped>
.btn {
  width: 70%;
  min-width: 82px;
  height: 30px;
  // line-height: 30px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
}

.dialogBox {
  ::v-deep .el-dialog {
    position: unset !important;
    margin: 0 auto !important;
    height: 80vh !important;
    width: 60% !important;
    margin-top: 50px !important;


  }

  ::v-deep .el-dialog__body {
    height: 60vh !important;
    padding: 20px 30px !important;
    box-sizing: border-box !important;
  }

  ::v-deep .el-dialog__header {
    position: relative;
  }

  ::v-deep .el-dialog__headerbtn {
    position: absolute;
    top: 0;
    right: 20px;
  }
}
</style>
