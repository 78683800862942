<template>
  <div class="formSubmit" v-if="show">
    <el-form
      v-if="!isSubmit"
      ref="elForm"
      :model="rulesData"
      :rules="rules"
      label-position="top"
      :validate-on-rule-change="false"
    >
      <!-- <el-col :span="item.__config__.span" v-for="(item, index) in getFormFileds.length > 0 ? getFormFileds : formDataList" :key="index"> -->
      <div></div>
      <el-col
        :span="item.__config__.span"
        v-for="(item, index) in getFormFileds"
        :key="index"
      >
        <el-form-item
          :ref="item.__vModel__"
          v-if="
            item.__config__.showLabel &&
            item.isshow !== false &&
            item.__config__.isDisplay !== false
          "
          :label="item.__config__.showLabel ? item.__config__.label : ''"
          :prop="item.__vModel__"
        >
          <div
            v-if="item.__config__.describe && item.__config__.tag !== 'el-line'"
            class="describe"
            v-html="item.__config__.describe"
          ></div>
          <div>
            <module-screen
              :module-list="item"
              @value="handleValue"
              :formId="formId"
              :appId="appId"
              :tableRuleType="tableRuleType"
              :flowRecord="flowRecord"
              @clickModuleScreen="clickModuleScreen"
              @databack="databack"
              :formDataFields="formData"
            ></module-screen>
            <!-- <el-button @click="clickModuleScreen(item)"
              v-show="item.__config__.extendData.trigger && item.__config__.extendData.trigger === 2">{{
                item.__config__.extendData.btnName }}</el-button>  -->
          </div>
          <!-- <module-screen
            :module-list="item"
            @value="handleValue"
            :formId="formId"
            :appId="appId"
            :tableRuleType="tableRuleType"
            :flowRecord="flowRecord"
          ></module-screen> -->
        </el-form-item>
        <el-form-item
          :ref="item.__vModel__"
          v-else-if="
            item.isshow !== false && item.__config__.isDisplay !== false
          "
          :label="item.__config__.showLabel ? item.__config__.label : ''"
          :prop="item.__vModel__"
        >
          <div
            v-if="item.__config__.describe && item.__config__.tag !== 'el-line'"
            class="describe"
          >
            {{ item.__config__.describe }}
          </div>
          <module-screen
            :module-list="item"
            @value="handleValue"
            :formId="formId"
            :appId="appId"
            :tableRuleType="tableRuleType"
            :flowRecord="flowRecord"
            :formDataFields="formData"
          ></module-screen>
        </el-form-item>
        <div v-if="item.__config__.tag === 'el-tabs'">
          <el-tabs type="border-card" v-model="item.__config__.defaultValue">
            <el-tab-pane
              :label="itemA.name"
              :name="itemA.name"
              v-for="(itemA, indexA) in item.children"
              :key="indexA"
            >
              <el-col
                :span="itemB.__config__.span"
                v-for="(itemB, indexB) in itemA.children"
                :key="indexB"
              >
                <el-form-item
                  :ref="item.__vModel__"
                  v-if="
                    itemB.__config__.showLabel &&
                    itemB.isshow !== false &&
                    itemB.__config__.isDisplay !== false
                  "
                  :label="
                    itemB.__config__.showLabel ? itemB.__config__.label : ''
                  "
                  :prop="itemB.__vModel__"
                >
                  <div v-if="itemB.__config__.describe" class="describe">
                    {{ itemB.__config__.describe }}
                  </div>
                  <module-screen
                    :module-list="itemB"
                    @value="handleValue"
                    :formId="formId"
                    :appId="appId"
                    :tableRuleType="tableRuleType"
                    :flowRecord="flowRecord"
                    :formDataFields="formData"
                  ></module-screen>
                </el-form-item>
                <el-form-item
                  :ref="item.__vModel__"
                  v-else-if="
                    itemB.isshow !== false &&
                    itemB.__config__.isDisplay !== false
                  "
                  :label="
                    itemB.__config__.showLabel ? itemB.__config__.label : ''
                  "
                  :prop="itemB.__vModel__"
                >
                  <div v-if="itemB.__config__.describe" class="describe">
                    {{ itemB.__config__.describe }}
                  </div>
                  <module-screen
                    :module-list="itemB"
                    @value="handleValue"
                    :formId="formId"
                    :appId="appId"
                    :tableRuleType="tableRuleType"
                    :flowRecord="flowRecord"
                    :formDataFields="formData"
                  ></module-screen>
                </el-form-item>
              </el-col>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-form>
    <div
      class="btn"
      v-if="!onlyLook && !isSubmit && nodeControlsList.length > 0"
    >
      <el-button
        type="primary"
        @click="submitForm"
        v-loading.fullscreen.lock="loading"
        element-loading-text="提交中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        v-if="nodeControlsList.length > 0 && nodeControlsList[0].value == true"
        >{{
          nodeControlsList[0].lable ? nodeControlsList[0].lable : "提交"
        }}</el-button
      >
      <el-button
        type="primary"
        @click="submitForm('print')"
        v-loading.fullscreen.lock="loading"
        element-loading-text="提交中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        v-if="nodeControlsList.length > 0 && nodeControlsList[2].value == true"
        >{{
          nodeControlsList[2].lable ? nodeControlsList[2].lable : "提交并打印"
        }}</el-button
      >
      <el-button
        @click="saveDraft"
        v-if="nodeControlsList.length > 0 && nodeControlsList[1].value == true"
        >{{
          nodeControlsList[1].lable ? nodeControlsList[1].lable : "保存草稿"
        }}</el-button
      >
    </div>
    <div
      class="btn"
      v-if="!onlyLook && !isSubmit && nodeControlsList.length === 0"
    >
      <el-button
        type="primary"
        @click="submitForm"
        v-loading.fullscreen.lock="loading"
        element-loading-text="提交中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        >提交</el-button
      >
      <el-button @click="saveDraft" v-if="!isDataAssis">保存草稿</el-button>
      <!-- <el-button @click="clickModuleScreen">查询</el-button> -->
    </div>
    <div class="submit-success" v-if="!onlyLook && isSubmit">
      <img src="../../assets/submit.png" />
      <div class="success-btn">
        <div>信息提交成功!</div>
        <el-button
          type="primary"
          @click="handleAdd"
          style="background-color: #398bfb"
          >继续添加</el-button
        >
      </div>
    </div>

    <el-drawer
      :visible.sync="drawer"
      :modal-append-to-body="false"
      :modal="false"
      direction="rtl"
      size="80%"
    >
      <print
        :formId="formId"
        :printId="printId"
        :printType="printType"
        :printWordType="printWordType"
        :printTemplateId="printTemplateId"
        :appId="appId"
      ></print>
    </el-drawer>
    <!-- 扩展按钮 -->
    <el-dialog
      title="添加"
      :visible.sync="addDialogVisible"
      width="100%"
      class="add-form-box"
      :before-close="addHandleClose"
      :modal-append-to-body="false"
      :modal="false"
      v-if="addDialogVisible"
    >
      <div
        class="submit-form"
        style="
          padding: 0 24px;
          background-color: #fff;
          height: calc(100% - 36px);
        "
      >
        <formSubmit
          :formData="innerformData"
          @form-submitted="handleFormSubmitted"
          :btnFormId="btnFormId"
          :btnFiled="btnFiled"
          :formId="innerformId"
          :appId="$route.query.id"
          :onlyLook="false"
          :btnType="btnType"
        >
        </formSubmit>
      </div>
    </el-dialog>

    <el-dialog
      title="添加"
      :visible.sync="biDialogVisible"
      width="100%"
      class="add-form-box"
      :before-close="biHandleClose"
      :modal-append-to-body="false"
      :modal="false"
    >
      <div
        class="submit-form"
        style="
          padding: 0 24px;
          background-color: #fff;
          height: calc(100% - 36px);
        "
      >
        <gridLayout
          :islook="true"
          :appId="$route.query.id"
          :formId="innerformId"
        ></gridLayout>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ModuleScreen from "./components/moduleScreen.vue";
import { mapGetters } from "vuex";
import { regularDict } from "@/utils/regular";
import print from "./print.vue";
import formulamethods from "./formula.js";
import { getFrontNdEventList } from "@/api/newevent";
import {
  getAppFormSubmit,
  getAppFormUpdatet,
  getAppFlowsUpdate,
  getAppFlowsSubmit,
  getAppFormDraft,
  getAppFormDraftContent,
  getAppFormLinkData,
  getAppFormSubmitNoLogin,
  getFieldData,
  getAppFormView,
  getSingleFieldData,
  getBtnValue,
} from "@/api/app";
import gridLayout from "@/views/gridLayout/index";

export default {
  mixins: [formulamethods],
  props: {
    formData: {
      type: Object,
      required: true,
    },
    flowsFiled: {
      type: Array,
    },
    nodeControls: {
      type: Object,
    },
    noBut: {
      type: Boolean,
    },
    formId: {
      type: String,
    },
    appId: {
      type: String,
    },
    nodeSubmit: [],
    onlyLook: false,
    oldPostData: {
      type: Object,
    },
    isDrat: true,
    formDataId: "",
    isFlowUpdate: false,
    oldEditData: {
      type: Object,
    },
    isDrafts: {
      type: Boolean,
    },
    tableRuleType: {
      type: String,
      default: "1",
    },
    flowRecord: {
      type: Array,
    },
    nodeControlsList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isEvents: {
      type: Boolean,
      default: false,
    },
    isDataAssis: {
      type: Boolean,
      default: false,
    },
    btnFormId: {
      type: String,
      default: "",
    },
    btnFiled: "",
    addDialogVisible: false,
    btnType: false,
  },
  name: "FormSubmit",
  data() {
    return {
      drawer: false,
      printId: null,
      printType: null,
      printWordType: null,
      printTemplateId: null,
      loading: false,
      postData: {},
      show: false,
      rulesData: {},
      rules: {},
      isShowError: false,
      isSaveForm: "",
      tableData: [],
      isSubmit: false,
      dataLink: {},
      formula: {},
      formulaName: "",
      map: null,
      addDialogVisible: false,
      innerformData: {},
      innershow: false,
      innerformId: "",
      formDataList: [],
      cachedFields: [],

      biDialogVisible: false,
      btnFormId: "",
      btnFiled: "",
      btnType: false,
    };
  },
  components: {
    ModuleScreen,
    print,
    gridLayout,
  },
  watch: {
    postData: {
      handler(val, old) {
        setTimeout(() => {
          this.setForMulaData(JSON.parse(JSON.stringify(val)));
        }, 1000);
      },
      deep: true,
    },
    isDrafts: {
      handler(val) {},
    },
    flowsFiled: {
      handler(val) {},
    },
    // "formData.fields": {
    //   handler () {
    //     this.cachedFields = this.getFormFileds();
    //   },
    //   immediate: true, // 立即执行一次
    // },
  },
  computed: {
    ...mapGetters(["handValue", "allMenberList", "allDepartList"]),
    getFormFileds() {
      if (this.formData.fields && this.formData.fields.length > 0) {
        return this.formData.fields.filter(
          (item) =>
            item.isshow !== false &&
            (item.__config__.tag === "el-tabs"
              ? true
              : item.__config__.isDisplay)
        );
      }
    },
  },
  created() {
    setTimeout(() => {
      if (this.formData.fields && this.formData.fields.length > 0) {
        this.isLookAndEdit();
        this.getFormData();
        this.show = true;
      }
    }, 1000);
  },
  mounted() {
    // this.mapKey = new QQMap({
    //   key: '4W2BZ-EPWCB-N5BUH-JRN5A-JGM5T-FSBCD'
    // });

    this.$bus.$on("filed", (data) => {
      let filed = data.departFiled;
      let value = [];
      this.formData.fields.forEach((item) => {
        if (item.__vModel__ === filed) {
          value = item.__config__.defaultValue;
        }
        if (item.__config__.tagIcon === "table") {
          item.children.forEach((item2) => {
            item2.children.forEach((item3) => {
              if (item3.__vModel__ === filed) {
                value = item3.__config__.defaultValue;
              }
            });
          });
        }
      });
      this.formData.fields.forEach((item) => {
        if (item.__vModel__ === data.__vModel__) {
          this.$set(item, "departList", value);
        }

        if (item.__config__.tagIcon === "row") {
          item.__config__.children.forEach((itemA) => {
            if (itemA.__vModel__ === data.__vModel__) {
              this.$set(itemA, "departList", value);
            }
          });
        }

        if (item.__config__.tagIcon === "table") {
          item.children.forEach((item2) => {
            item2.children.forEach((item3) => {
              if (item3.__vModel__ === data.__vModel__) {
                this.$set(item3, "departList", value);
              }

              if (item3.__config__.tagIcon === "row") {
                item3.__config__.children.forEach((itemA) => {
                  if (itemA.__vModel__ === data.__vModel__) {
                    this.$set(itemA, "departList", value);
                  }
                });
              }
            });
          });
        }
      });
    });

    this.$bus.$on("xygz", (data, files) => {
      data.forEach((item) => {
        let arr = item.value;
        this.formData.fields.forEach((item2) => {
          if (files.xygzParent) {
            if (
              item2.__config__.label &&
              item2.__config__.label === files.xygzParent
            ) {
              item2.children[files.tabsIndex].children.forEach((item3) => {
                if (arr.includes(item3.__vModel__)) {
                  this.$set(item3, "isshow", item.isshow);
                  if (!item.isshow) {
                    if (this.rules[item3.__vModel__]) {
                      this.rules[item3.__vModel__].forEach((item4) => {
                        this.$set(item4, "required", false);
                      });
                    }
                  } else {
                    if (this.rules[item3.__vModel__]) {
                      this.rules[item3.__vModel__].forEach((item4) => {
                        this.$set(item4, "required", true);
                      });
                    }
                  }
                }
                if (arr.includes(item3.__config__.rowTitle)) {
                  this.$set(item3, "isshow", item.isshow);
                  if (!item.isshow) {
                    this.rules[item3.__vModel__].forEach((item4) => {
                      this.$set(item4, "required", false);
                    });
                  } else {
                    this.rules[item3.__vModel__].forEach((item4) => {
                      this.$set(item4, "required", true);
                    });
                  }
                }
              });
            }
          } else {
            if (arr.includes(item2.__vModel__)) {
              this.$set(item2, "isshow", item.isshow);
              this.$forceUpdate();
              if (!item.isshow) {
                if (this.rules[item2.__vModel__]) {
                  this.rules[item2.__vModel__].forEach((item3) => {
                    this.$set(item3, "required", false);
                  });
                }
              } else {
                if (this.rules[item2.__vModel__]) {
                  this.rules[item2.__vModel__].forEach((item3) => {
                    this.$set(item3, "required", true);
                  });
                }
              }
            }
            if (arr.includes(item2.__config__.rowTitle)) {
              this.$set(item2, "isshow", item.isshow);
              if (!item.isshow) {
                this.rules[item2.__vModel__].forEach((item3) => {
                  this.$set(item3, "required", false);
                });
              } else {
                this.rules[item2.__vModel__].forEach((item3) => {
                  this.$set(item3, "required", true);
                });
              }
            }
          }
        });
      });
    });
    this.$bus.$on("dataLink", (data) => {
      let query = {
        widgets: data.__vModel__, //当前表单字段
        fields: data.linkValue, //联动表单字段
        form_id: data.formValue, //联动表单ID
        app_id: data.linkAppId, //app_id
      };
      let cond = [];
      data.linkList.forEach((item) => {
        cond.push({
          field: item.linkValue,
          value: "",
          method: item.condition === "等于" ? "eq" : "neq",
          nowValue: item.nowValue,
        });
      });
      query.cond = cond;
      this.dataLink[data.__vModel__] = query;
    });

    this.$bus.$on("formula", (data) => {
      this.getForMulaData(data);
    });
    this.$nextTick(() => {
      // 在这里编写需要在DOM更新完成后执行的代码
      setTimeout(() => {
        if (this.formData.fields && this.formData.fields.length > 0) {
          this.getFormData();
        }
      }, 1500);
    });
    // 扩展按钮触发的事件
    this.$bus.$on("extendFun", (data, id) => {
      this.btnFormId = id;
      this.btnFiled = data.__vModel__;
      this.btnType = true;
      if (data.patternValue == 1) {
        this.addDialogVisible = true;
        this.innerformId = data.btnFormData.formValue;
        this.getInnerList(this.innerformId, data);
      } else {
        this.biDialogVisible = true;
        this.innerformId = data.btnReportData.formValue;
      }
    });
  },
  methods: {
    handleFormSubmitted(list) {
      this.addDialogVisible = false;
      this.btnType = false;
      this.getInnerValue(this.formData, list);
    },

    // 拿到扩展按钮关联的表单的字段
    getInnerList(formId, data) {
      getAppFormView(this.$route.query.id, formId).then((res) => {
        if (res.data.content) {
          if (typeof res.data.content === "string") {
            this.innerformData = JSON.parse(res.data.content)[0];
          } else {
            this.innerformData = res.data.content[0];
          }
          this.getInnerValue(this.innerformData, data);
          this.innershow = true;
        } else {
          this.innershow = false;
        }
      });
    },
    getInnerValue(formData, data) {
      let arr = data.btnFormData ? data.btnFormData.formFilterList : data;
      if (arr.length > 0) {
        formData.fields.forEach((item) => {
          arr.forEach((item2) => {
            if (
              item.__vModel__ === item2.__vModel__ ||
              item.__vModel__ === item2.field
            ) {
              this.setInnerValue(item, item2);
            }
          });
        });
      }
    },
    // 扩展按钮功能 给弹出的表单赋值
    setInnerValue(item, item2) {
      let writeValue = item2.filterData ? item2.filterData.value : item2.value;
      if (
        item.__config__.tag === "el-input" ||
        item.__config__.tag === "textarea" ||
        item.__config__.tag === "inputNumber"
      ) {
        this.$set(item.__config__, "defaultValue", writeValue);

        this.handleValue({ name: item.__vModel__, value: writeValue });
      } else if (item.__config__.tag === "el-select") {
        item.__slot__.options.forEach((itemA) => {
          if (item.multiple) {
            let options = [];
            options.push(writeValue);
            this.$set(item.__config__, "defaultValue", options);
            this.handleValue({ name: item.__vModel__, value: options });
          } else {
            this.$set(item.__config__, "defaultValue", writeValue);
          }
        });
        item.__slot__.options.push({
          label: writeValue,
          value: writeValue,
        });
      } else if (item.__config__.tag === "datePicker") {
        this.$set(item.__config__, "defaultValue", writeValue);
        this.handleValue({ name: item.__vModel__, value: writeValue });
      } else if (
        item.__config__.tag === "menberCheck" ||
        item.__config__.tag === "menberSingle"
      ) {
        let arr = [];
        writeValue.forEach((item) => {
          arr.push({
            label: item.name,
            id: item._id,
          });
        });
        this.$set(item.__config__, "defaultValue", arr);
        this.handleValue({ name: item.__vModel__, value: arr });
      } else if (
        item.__config__.tag === "departCheck" ||
        item.__config__.tag === "departSingle"
      ) {
        let arr = [];
        writeValue.forEach((item) => {
          arr.push({
            label: item.name,
            id: item._id,
          });
        });
        this.$set(item.__config__, "defaultValue", arr);
        this.handleValue({ name: item.__vModel__, value: arr });
      }
    },
    // 关闭扩展按钮弹出的弹窗
    addHandleClose() {
      this.addDialogVisible = false;
    },
    biHandleClose() {
      this.biDialogVisible = false;
    },
    saveDraft() {
      let query = {
        values: {
          rules: this.rules,
          postData: this.postData,
          rulesData: this.rulesData,
        },
        form_id: this.formId,
        app_id: this.appId,
      };

      if (this.isDrafts) {
        // 草稿箱修改
        this.$emit("modifyFun", query);
      } else {
        getAppFormDraft(query).then((res) => {
          if (res.code === 200) {
            this.$emit("getCount");
            this.$message.success("保存成功");
          }
        });
      }
    },

    async clickModuleScreen(item) {
      try {
        const data = {
          form_id: this.formId,
          app_id: this.appId,
          key_id: item.__vModel__,
          type: 2,
          param: this.postData,
        };
        const res = await getFrontNdEventList(data);
        this.cachedFields = [];
        const list = JSON.parse(res.data);
        list.map((item) => {
          this.formData.fields = item.fields;
          // this.$set(this, 'formDataList', item.fields)
        });
        this.getFormData();

        // if (this.formDataList && this.formDataList > 0) {
        //   return this.formDataList.filter((item) => {
        //     return item.isshow !== false &&
        //       (item.__config__.tag === "el-tabs" ||
        //         item.__config__.isDisplay);
        //   });
        // }
        // this.$forceUpdate()
      } catch (error) {}
    },

    databack(val) {
      val.forEach((item) => {
        this.formData.fields.forEach((item2) => {
          if (item.__vModel__ == item2.__vModel__) {
            // item2.__config__.defaultValue = item.labelValue
            if (item.type && item.type == 2 && Array.isArray(item.labelValue)) {
              item.labelValue = item.labelValue.join("");
            }
            if (
              typeof item.labelValue === "object" &&
              item.labelValue !== null
            ) {
              this.$set(item2.__config__, "defaultValue", [...item.labelValue]);
            } else {
              this.$set(item2.__config__, "defaultValue", item.labelValue);
            }
          }
        });

        for (var i in this.postData) {
          if (i == item.__vModel__) {
            this.postData[i].value = [...item.labelValue];
          }
        }
      });
    },

    add() {
      let arr = {};

      for (let i in this.tableData[0]) {
        arr[i] = this.tableData[0][i];
      }

      this.tableData.push(arr);
    },
    validData() {
      let bool = true;
      for (let i in this.postData) {
        if (this.postData[i].tag === "row") {
          try {
            this.formData.fields.forEach((item) => {
              if (this.postData[i].isTabs) {
                if (item.__config__.tag === "el-tabs") {
                  item.children.forEach((itemA) => {
                    itemA.children.forEach((itemB) => {
                      if (i === itemB.__config__.componentName) {
                        if (itemB.isshow !== false) {
                          let arr = this.postData[i].value;
                          for (let j = 0; j < arr.length; j++) {
                            for (let key in arr[j]) {
                              if (arr[j][key].required) {
                                if (
                                  !arr[j][key].value ||
                                  arr[j][key].value.length === 0
                                ) {
                                  this.$message.error(
                                    "请填写" +
                                      this.postData[i].tabName +
                                      ">" +
                                      this.postData[i].title +
                                      ">" +
                                      arr[j][key].label +
                                      "数据"
                                  );
                                  bool = false;
                                  throw "终止循环";
                                }
                              }
                            }
                          }
                        }
                      }
                    });
                  });
                }
              } else {
                if (i === item.__config__.componentName) {
                  if (item.isshow !== false) {
                    let arr = this.postData[i].value;
                    for (let j = 0; j < arr.length; j++) {
                      for (let key in arr[j]) {
                        if (arr[j][key].required) {
                          if (
                            !arr[j][key].value ||
                            arr[j][key].value.length === 0
                          ) {
                            this.$message.error(
                              "请填写" +
                                this.postData[i].title +
                                arr[j][key].label +
                                "数据"
                            );
                            bool = false;
                            throw "终止循环";
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
          } catch {}
        }
        if (!bool) {
          break;
        }
      }
      return bool;
    },
    submitForm(type) {
      this.isShowError = false;
      this.$refs["elForm"].validate((valid) => {
        if (valid) {
          let bool = this.validData();
          if (bool) {
            this.loading = true;
            if (this.oldPostData) {
              this.copySubmitPostData();
            }
            this.submitData(JSON.parse(JSON.stringify(this.postData)), type);
          }
        } else {
          this.$message.error("请填写必填项信息");
          return false;
        }
      });
    },
    copySubmitPostData() {
      for (let key in this.postData) {
        if (
          this.postData.hasOwnProperty(key) &&
          this.oldPostData.hasOwnProperty(key)
        ) {
          // 检查子对象的val属性是否为空
          if (
            this.postData[key].tag == "row" &&
            this.postData[key].value == ""
          ) {
            // 如果为空，从对象B中相应的子对象获取val的值并赋值给A
            this.postData[key].value = this.oldPostData[key].value;
          }
        }
      }
    },
    handleAdd() {
      this.isSubmit = false;
      //   this.$emit("submitSuccess");
    },
    resetForm() {
      this.$refs["elForm"].clearValidate();
    },
    setLinkData(data, type) {
      // if (!Array.isArray(data)) {
      for (let i in data) {
        this.formData.fields.forEach((item) => {
          if (item.__config__.tagIcon === "row") {
            if (
              item.__config__.children &&
              item.__config__.children.length > 0
            ) {
              item.__config__.children.forEach((itemA) => {
                if (i === itemA.__vModel__) {
                  if (data[i]) {
                    if (type === "tableColumn") {
                      if (itemA.__config__.tag === "el-select") {
                        this.$bus.$emit("setTableColumn", {
                          name: itemA.__vModel__,
                          value: data[i],
                        });
                      } else {
                        this.$bus.$emit("setTableColumn", {
                          name: itemA.__vModel__,
                          value: data[i],
                        });
                        this.handleValue({
                          name: itemA.__vModel__,
                          value: data[i],
                        });
                      }
                    } else {
                      if (itemA.__config__.tag === "el-select") {
                        this.$bus.$emit("setTableLink", {
                          name: itemA.__vModel__,
                          value: data[i],
                        });
                      } else {
                        this.$bus.$emit("setTableLink", {
                          name: itemA.__vModel__,
                          value: data[i],
                        });
                        this.$set(itemA.__config__, "defaultValue", data[i]);
                        this.handleValue({
                          name: itemA.__vModel__,
                          value: data[i],
                        });
                      }
                    }
                  }
                }
              });
            }
          } else if (item.__config__.tagIcon === "table") {
            if (
              item.__config__.children &&
              item.__config__.children.length > 0
            ) {
              item.__config__.children.forEach((itemA) => {
                itemA.children.forEach((itemB) => {
                  if (i === itemB.__vModel__) {
                    if (data[i]) {
                      if (!itemB.__config__.defaultValue) {
                        this.$set(itemB.__config__, "defaultValue", data[i]);
                        this.handleValue({
                          name: item.__vModel__,
                          value: data[i],
                        });
                      }
                    }
                  }
                });
              });
            }
          } else {
            if (i === item.__vModel__) {
              if (data[i]) {
                if (
                  item.__config__.tag === "el-input" ||
                  item.__config__.tag === "textarea" ||
                  item.__config__.tag === "inputNumber" ||
                  item.__config__.tag === "addr" ||
                  item.__config__.tag === "select"
                ) {
                  if (item.__config__.tag === "addr") {
                    this.$set(item.__config__, "defaultValue", data[i]);
                    this.$bus.$emit("addr", data[i]);
                  } else {
                    this.$set(item.__config__, "defaultValue", data[i][0]);
                  }
                  this.handleValue({
                    name: item.__vModel__,
                    value: data[i][0],
                  });
                } else {
                  this.setValue(data[i], item);
                }
              }
            }
          }
        });
      }
      // }
    },
    setValue(val, item) {
      if (item.__config__.tag === "el-select") {
        const result = val.every((res) => Array.isArray(res));
        if (val && !result) {
          item.__slot__.options = [];
          val.forEach((res) => {
            item.__slot__.options.push({
              value: res,
            });
          });
        } else if (val && result) {
          item.__slot__.options = [];
          val.forEach((res) => {
            if (Array.isArray(res)) {
              res = res.join(",");
            }
            item.__slot__.options.push({
              value: res,
            });
          });
        }
      } else if (item.__config__.tag === "datePicker") {
        this.$set(item.__config__, "defaultValue", val);
        this.handleValue({ name: item.__vModel__, value: val });
      }
    },

    // 把后端提交返回的重复字段值做比对弹出提示
    getCompare(filedId) {
      this.formData.fields.forEach((item) => {
        if (item.__vModel__ == filedId) {
          this.$message.warning(
            `"${item.__config__.label}"已存在当前输入值，请勿重复添加`
          );
        }
      });
    },
    submitData(data, type) {
      let obj = {};
      let tabs = "";
      let tabsList = [];
      let arr = {};
      // let falge = true
      for (let i in data) {
        if (i.includes("tabs")) {
          tabs = i;
        }
      }

      for (let i in data) {
        if (data[i].isTabs) {
          if (!i.includes("tabs")) {
            tabsList.push(data[i]);
          }
        } else {
          obj[i] = data[i].value ? data[i].value : "";
          if (data[i].tag === "inputNumber" && data[i].value) {
            obj[i] = Number(obj[i]);
          }
          if (i.indexOf("row") != -1 && data[i].value) {
            data[i].value.forEach((item, index) => {
              for (let j in item) {
                if (
                  item[j].tag === "menberCheck" ||
                  item[j].tag === "menberSingle"
                ) {
                  let arr = [];
                  item[j].value.forEach((itemMenber) => {
                    arr.push(itemMenber.id);
                  });
                  obj[i][index][j] = arr;
                } else if (
                  item[j].tag === "departCheck" ||
                  item[j].tag === "departSingle"
                ) {
                  let arr = [];
                  item[j].value.forEach((itemDepart) => {
                    arr.push(itemDepart.id);
                  });
                  obj[i][index][j] = arr;
                } else if (item[j].tag === "inputNumber") {
                  obj[i][index][j] = item[j].value ? Number(item[j].value) : "";
                } else {
                  obj[i][index][j] = item[j].value ? item[j].value : "";
                }
              }
            });
          }
          if (data[i].tag === "menberCheck" || data[i].tag === "menberSingle") {
            let arr = [];
            data[i].value.forEach((item) => {
              arr.push(item.id);
            });
            obj[i] = arr;
          }
          if (data[i].tag === "departCheck" || data[i].tag === "departSingle") {
            let arr = [];
            data[i].value.forEach((item) => {
              arr.push(item.id);
            });
            obj[i] = arr;
          }

          if (data[i].tag === "el-input" && data[i].value) {
            this.cachedFields.forEach((item) => {
              if (
                item.__vModel__ === data[i].listindex &&
                item.__config__.isUniqueOnly == true
              ) {
                // getSingleFieldData(this.appId, this.formId, item.__vModel__).then(res => {
                //   if (data[i].value && res.data.includes(data[i].value)) {
                //     falge = false
                //   }
                // })
              }
            });
          }
        }
      }

      tabsList.forEach((item, index) => {
        if (item.tag === "row") {
          let tableArr = [];
          if (item.value) {
            item.value.forEach((itemA) => {
              let obj = {};
              for (let i in itemA) {
                if (
                  itemA[i].tag === "menberCheck" ||
                  itemA[i].tag === "menberSingle"
                ) {
                  let ids = [];
                  itemA[i].value.forEach((item) => {
                    ids.push(item.id);
                  });
                  obj[i] = ids;
                } else if (
                  itemA[i].tag === "departCheck" ||
                  itemA[i].tag === "departSingle"
                ) {
                  let ids = [];
                  itemA[i].value.forEach((item) => {
                    ids.push(item.id);
                  });
                  obj[i] = ids;
                } else if (itemA[i].tag === "inputNumber") {
                  obj[i] = itemA[i].value ? Number(itemA[i].value) : "";
                } else {
                  obj[i] = itemA[i].value ? itemA[i].value : "";
                }
              }
              tableArr.push(obj);
            });
          }

          arr[item.listindex] = tableArr;
        } else {
          if (item.tag === "menberCheck" || item.tag === "menberSingle") {
            let ids = [];
            item.value.forEach((item) => {
              ids.push(item.id);
            });
            arr[item.listindex] = ids;
          } else if (
            item.tag === "departCheck" ||
            item.tag === "departSingle"
          ) {
            let ids = [];

            item.value.forEach((item) => {
              ids.push(item.id);
            });
            arr[item.listindex] = ids;
          } else if (item.tag === "inputNumber") {
            arr[item.listindex] = item.value ? Number(item.value) : "";
          } else {
            arr[item.listindex] = item.value;
          }
        }
      });

      if (tabs) {
        obj[tabs] = arr;
      }
      //编辑时携带id
      if (this.oldEditData) {
        for (let i in obj) {
          if (i.includes("row") && obj[i]) {
            obj[i].forEach((item, index) => {
              if (this.postData[i]) {
                item["_id"] = this.postData[i]["value"][index]["_id"];
              }
            });
          } else if (i.includes("tabs")) {
            obj[i]["_id"] = this.postData[i]["_id"];
          }
        }
      }

      let query = {
        values: obj,
        form_id: this.formId,
        app_id: this.appId,
        form_data_id: this.formDataId,
        post_data: this.postData,
      };
      if (this.isEvents) {
        this.loading = false;
        this.$emit("getData", query);
        return;
      }
      let data_arr = [];
      for (let i in obj) {
        if (i.includes("tabs")) {
          for (let j in obj[i]) {
            data_arr.push({ id: j, val: obj[i][j] });
          }
        } else {
          data_arr.push({ id: i, val: obj[i] });
        }
      }
      if (this.nodeSubmit && this.nodeSubmit.length > 0) {
        this.nodeSubmit.forEach((item) => {
          let isok = false;
          let allok = true;
          item.list.forEach((item2) => {
            data_arr.forEach((atat_item) => {
              if (item2.tag == atat_item.id) {
                if (atat_item.val && item2.value == atat_item.val) {
                  isok = true;
                } else if (atat_item.val) {
                  allok = false;
                }
              }
            });
          });

          if (item.satisfy === 2) {
            item.ok = isok;
          } else {
            item.ok = allok;
          }
        });
      }

      let isgo = true;
      if (this.nodeSubmit && this.nodeSubmit.length > 0) {
        for (let j = 0; j < this.nodeSubmit.length; j++) {
          if (!this.nodeSubmit[j].ok) {
            this.$message.error(this.nodeSubmit[j].text);
            isgo = false;
            this.loading = false;
            break;
          }
        }
      }
      if (!isgo) {
        this.loading = false;
        return;
      }
      if (this.isDataAssis) {
        this.$emit("getFormData", query);
        this.loading = false;
        return;
      }
      if (this.formDataId && this.isFlowUpdate) {
        getAppFlowsUpdate(query).then((res) => {
          if (res.code === 200) {
            if (res.msg === "1") {
              this.loading = false;
              this.printId = this.formDataId;
              if (type === "print") {
                this.$emit("editSuccess", "print");
                this.nodeControlsList.forEach((item) => {
                  if (item.type === "print") {
                    this.printTemplateId = item.printId;
                    this.printType = item.printType;
                    this.printWordType = item.printWordType;
                  }
                });
                this.drawer = true;
              } else {
                this.$emit("editSuccess");
              }
            } else if (res.msg === "存在重复数据") {
              this.getCompare(res.field);
              this.loading = false;
            }
          }
        });
      } else if (this.formDataId) {
        getAppFormUpdatet(query).then((res) => {
          if (res.code === 200) {
            if (res.msg === "修改成功") {
              this.$emit("editSuccess");
              this.loading = false;
            } else if (res.msg === "存在重复数据") {
              this.getCompare(res.field);
              this.loading = false;
            }
          }
        });
      } else {
        if (this.$route.query.status == 1) {
          getAppFormSubmitNoLogin(query).then((res) => {
            if (res.code === 200) {
              this.$message.success("提交成功");
              this.isSubmit = true;
              this.loading = false;
            }
          });
        } else {
          getAppFormSubmit(query).then((res) => {
            if (res.code === 200) {
              if (res.msg === "添加成功") {
                this.$message.success("提交成功");
                this.$emit("submitSuccess");
                this.loading = false;
                this.printId = res.id;
                this.isSubmit = true;
                if (this.btnType) {
                  this.getBtnValueFun(this.printId);
                }
                // this.$emit('form-submitted');
                if (type === "print") {
                  this.nodeControlsList.forEach((item) => {
                    if (item.type === "print") {
                      this.printTemplateId = item.printId;
                      this.printType = item.printType;
                      this.printWordType = item.printWordType;
                    }
                  });
                  this.drawer = true;
                }
              } else if (res.msg === "存在重复数据") {
                this.getCompare(res.field);
                this.loading = false;
              }
            }
          });
        }
      }
    },
    // 扩展按钮功能提交回写
    getBtnValueFun(sourceId) {
      getBtnValue(this.btnFormId, this.btnFiled, sourceId, this.formId).then(
        (res) => {
          this.isSubmit = false;
          this.addDialogVisible = false;
          this.$emit("form-submitted", res.data);
        }
      );
    },

    getFormData() {
      this.tableData = [];
      this.isSubmit = false;
      this.formData.fields.forEach((item, index) => {
        if (item.__config__.tagIcon !== "Line") {
          let obj = "";
          if (item.__config__.tagIcon === "row") {
            obj = {
              value: "",
              tag: item.__config__.tagIcon,
              listindex: item.__config__.componentName,
              title: item.__config__.rowTitle,
            };
          } else if (item.__config__.tag === "el-tabs") {
            item.children.forEach((itemA, indexA) => {
              itemA.children.forEach((itemB) => {
                if (itemB.__config__.tagIcon === "row") {
                  obj = {
                    value: "",
                    tag: itemB.__config__.tagIcon,
                    listindex: itemB.__config__.componentName,
                    title: itemB.__config__.rowTitle,
                    index: indexA,
                    isTabs: true,
                    tabVmodel: item.__vModel__,
                    tabTitle: item.__config__.label,
                    tabName: item.children[indexA].name,
                  };
                } else {
                  obj = {
                    value: itemB.__config__.defaultValue,
                    tag: itemB.__config__.tag,
                    listindex: itemB.__vModel__,
                    index: indexA,
                    isTabs: true,
                    tabVmodel: item.__vModel__,
                    tabTitle: item.__config__.label,
                    tabName: item.children[indexA].name,
                  };
                }

                this.$set(
                  this.postData,
                  itemB.__vModel__
                    ? itemB.__vModel__
                    : itemB.__config__.componentName,
                  obj
                );
                this.$set(
                  this.rulesData,
                  itemB.__vModel__
                    ? itemB.__vModel__
                    : itemB.__config__.componentName,
                  itemB.__config__.defaultValue
                );
                let arr = [];
                if (itemB.__config__.required) {
                  let obj = {
                    required: true,
                    message: "此项为必填项",
                    trigger: "change",
                  };
                  if (item.__config__.tag == "el-input") {
                    obj.trigger = "blur";
                  }
                  arr.push(obj);
                }
                if (
                  itemB.__config__.regList &&
                  itemB.__config__.regList.length > 0
                ) {
                  itemB.__config__.regList.forEach((item2, index2) => {
                    if (item2.pattern != "") {
                      this.$set(item2, "message", item2.message + "格式错误");
                      item2.pattern = regularDict(item2.message);

                      item2.trigger = "change";
                      if (itemB.__config__.tag == "el-input") {
                        item2.trigger = "blur";
                      }

                      arr.push(item2);
                    }
                  });
                  this.$set(this.rules, itemB.__vModel__, arr);
                }
              });
            });
          } else {
            obj = {
              value: item.__config__.defaultValue,
              tag: item.__config__.tag,
              listindex: item.__vModel__,
            };
          }

          this.$set(
            this.postData,
            item.__vModel__ ? item.__vModel__ : item.__config__.componentName,
            obj
          );
          this.$set(
            this.rulesData,
            item.__vModel__ ? item.__vModel__ : item.__config__.componentName,
            item.__config__.defaultValue
          );
          let arr = [];
          if (item.__config__.required) {
            let obj = {
              required: true,
              message: "此项为必填项",
              trigger: "change",
            };
            if (item.__config__.tag == "el-input") {
              obj.trigger = "blur";
            }
            arr.push(obj);
          }
          if (item.__config__.regList && item.__config__.regList.length > 0) {
            item.__config__.regList.forEach((item2, index2) => {
              if (item2.pattern != "") {
                if (!item2.message.includes("格式错误")) {
                  this.$set(item2, "message", item2.message + "格式错误");
                  0;
                }

                item2.pattern = regularDict(item2.message);

                item2.trigger = "change";
                if (item.__config__.tag == "el-input") {
                  item2.trigger = "blur";
                }

                arr.push(item2);
              }
            });
          }
          this.$set(this.rules, item.__vModel__, arr);
        }
      });
      //编辑时携带id
      if (this.oldEditData) {
        // setTimeout(() => {
        for (let i in this.postData) {
          if (i.includes("row") && this.postData[i].value) {
            this.postData[i].value.forEach((item, index) => {
              if (this.oldEditData[i]) {
                item["_id"] = this.oldEditData[i][index]["_id"];
              }
            });
          } else if (i.includes("tabs")) {
            this.postData[i]["_id"] = this.oldEditData[i]["_id"];
          }
        }
        this.show = true;
        // }, 1500);
      }
      this.isLookAndEdit();
      setTimeout(() => {
        if (this.oldPostData) {
          let draftData = {};

          draftData = this.oldPostData;
          this.postData = this.oldPostData;

          this.formData.fields.forEach((item, index) => {
            if (
              item.__config__.tagIcon !== "row" &&
              item.__config__.tagIcon !== "table"
            ) {
              for (let i in draftData) {
                if (i === item.__vModel__) {
                  this.$set(
                    this.formData.fields[index].__config__,
                    "defaultValue",
                    draftData[i].value
                  );
                }
              }
            }
            if (item.__config__.tagIcon === "row") {
              for (let i in draftData) {
                if (i === item.__config__.componentName) {
                  this.$set(
                    item.__config__,
                    "defaultValue",
                    draftData[i].value
                  );
                }
              }
            }
            if (item.__config__.tagIcon === "table") {
              for (let i in draftData) {
                if (draftData[i].tabVmodel) {
                  item.children.forEach((itemTabA) => {
                    if (draftData[i].tabName === itemTabA.name) {
                      itemTabA.children.forEach((itemTabB) => {
                        if (
                          itemTabB.__config__.tagIcon !== "row" &&
                          i === itemTabB.__vModel__
                        ) {
                          this.$set(
                            itemTabB.__config__,
                            "defaultValue",
                            draftData[i].value
                          );
                        }
                        if (
                          itemTabB.__config__.tagIcon === "row" &&
                          i === itemTabB.__config__.componentName
                        ) {
                          this.$set(
                            itemTabB.__config__,
                            "defaultValue",
                            draftData[i].value
                          );
                        }
                      });
                    }
                  });
                }
              }
            }
          });
        }
      }, 200);
    },
    isLookAndEdit() {
      if (!this.flowsFiled || this.flowsFiled.length == 0) {
        return;
      }

      let flows_arr = [];
      this.flowsFiled.forEach((item) => {
        if (item.tagIcon == "table") {
          item.child.forEach((item2) => {
            item2.child.forEach((item3) => {
              if (item3.tagIcon == "row" && item3.child.length > 0) {
                item3.child.forEach((item4) => {
                  flows_arr.push(item4);
                });
              } else {
                flows_arr.push(item3);
              }
            });
          });
        } else if (item.tagIcon == "row") {
          if (item.child.length > 0) {
            item.child.forEach((item2) => {
              flows_arr.push(item2);
            });
          }
        } else {
          flows_arr.push(item);
        }
      });

      this.formData.fields.forEach((item) => {
        if (item.__config__.tagIcon == "table") {
          item.children.forEach((item2) => {
            item2.children.forEach((item3) => {
              if (
                item3.__config__.tagIcon == "row" &&
                item3.__config__.children.length > 0
              ) {
                let show = false;
                let indexs = 0;
                item3.__config__.children.forEach((item4) => {
                  flows_arr.forEach((flow_item) => {
                    if (item4.__vModel__ == flow_item.fileds) {
                      indexs++;
                      item4.__config__.isDisplay = flow_item.look;
                      item4.disabled = !flow_item.edit;
                      if (flow_item.look) {
                        show = true;
                      }
                    }
                  });
                });
                if (indexs < item3.__config__.children.length) {
                  show = true;
                }
                item3.__config__.isDisplay = show;
              } else {
                flows_arr.forEach((flow_item) => {
                  if (item3.__vModel__ == flow_item.fileds) {
                    item3.__config__.isDisplay = flow_item.look;
                    item3.disabled = !flow_item.edit;
                  }
                });
              }
            });
          });
        } else if (item.__config__.tagIcon == "row") {
          let show = false;
          let indexs = 0;
          item.__config__.children.forEach((item2) => {
            flows_arr.forEach((flow_item) => {
              if (item2.__vModel__ == flow_item.fileds) {
                indexs++;
                item2.__config__.isDisplay = flow_item.look;
                item2.disabled = !flow_item.edit;
                if (flow_item.look) {
                  show = true;
                }
              }
            });
          });
          if (indexs < item.__config__.children.length) {
            show = true;
          }
          item.__config__.isDisplay = show;
        } else {
          flows_arr.forEach((flow_item) => {
            if (item.__vModel__ == flow_item.fileds) {
              item.__config__.isDisplay = flow_item.look;
              item.disabled = !flow_item.edit;
            }
          });
        }
      });
    },
    handleValue(val) {
      console.log(val, "表格数据联动");

      let bool = false;
      this.formulaName = val.name;

      if (this.postData[val.name]) {
        this.postData[val.name].value = val.value;
        this.rulesData[val.name] = val.value;

        if (this.$refs[val.name]) {
          this.$refs[val.name][0].clearValidate();
        }
        if (!Array.isArray(this.$refs[val.name])) {
          // this.$refs[val.name].clearValidate();
        }
        if (val.isTable) {
          val.value.forEach((itemA) => {
            for (let i in this.dataLink) {
              this.dataLink[i].cond.forEach((item) => {
                if (val.value == "" && itemA[item.nowValue].value == "") {
                  bool = false;
                } else {
                  item.value = itemA[item.nowValue].value;
                  bool = true;
                }
              });
              if (bool) {
                getAppFormLinkData(this.dataLink[i]).then((res) => {
                  this.setLinkData(res.data, "tableColumn");
                });
              }
            }
          });
        } else {
          if (
            this.handValue.name === val.name &&
            this.handValue.value === val.value
          ) {
            return;
          } else {
            for (let i in this.dataLink) {
              this.dataLink[i].cond.forEach((item) => {
                if (!val.value && !item.value) {
                  bool = false;
                } else {
                  if (val.name === item.nowValue) {
                    item.value = val.value;
                    bool = true;
                  }
                }
              });
              if (bool) {
                getAppFormLinkData(this.dataLink[i]).then((res) => {
                  this.setLinkData(res.data);
                });
              }
            }
            this.$store.dispatch("app/setHandValue", val);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  table-layout: fixed;
}

.add-form-box {
  ::v-deep .el-dialog__body {
    height: unset !important;
  }
}

::v-deep .el-table {
  table-layout: fixed;
}

::v-deep .but-box .el-form-item__content {
  text-align: center;
  margin-left: 0 !important;
}

.formSubmit::-webkit-scrollbar {
  display: none;
}

.formSubmit {
  height: 100%;
  position: relative;
}

.btn {
  height: 50px;
  width: 100%;
  line-height: 50px;
  text-align: center;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  position: absolute;
}

::v-deep .el-col {
  padding-right: 20px;
  box-sizing: border-box;
}

.submit-success {
  text-align: center;
  padding-top: 150px;
  box-sizing: border-box;

  .success-btn {
    div {
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: bold;
      color: #303133;
      line-height: 28px;
    }
  }
}

::v-deep .el-form-item__label {
  font-size: 15px !important;
  color: #303133 !important;
  padding: unset !important;
  font-weight: 500;
}

::v-deep .el-form-item__content {
  line-height: unset !important;
  min-height: 5px;
}

.describe {
  line-height: 20px !important;
  margin-top: -5px;
  margin-bottom: 10px;
  //color: #c0c4cc;
}

.el-form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: scroll;
  max-height: calc(100% - 50px);
  padding-bottom: 50px;
  box-sizing: border-box;
}

.el-form::-webkit-scrollbar {
  display: none;
}
</style>
