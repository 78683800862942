import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";
import store from "@/store";
Vue.use(VueRouter);

const routes = [,
  {
    path: "/",
    component: Layout,
    redirect: "/login",
    children: [{
        path: "/home",
        name: "home",
        component: () => import("@/views/home/index.vue"),
      },
      {
        path: "/todolist",
        name: "todolist",
        component: () => import("@/views/toDoList/index.vue"),
        meta: {
          title: "应用",
        },
      },
      {
        path: "/communicate",
        name: "communicate",
        component: () => import("@/views/communicate/index.vue"),
        meta: {
          title: "通讯录",
        },
      },
      {
        path: "/management",
        name: "management",
        component: () => import("@/views/management/index.vue"),
        meta: {
          title: "管理后台",
        },
      },
      {
        path: "/commission",
        name: "commission",
        component: () => import("@/views/commission/index.vue"),
        meta: {
          title: "待办",
        },
      },
      {
        path: "/recycle",
        name: "recycle",
        component: () => import("@/views/recycle/index.vue"),
        meta: {
          title: "回收站",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/ddlogin",
    name: "ddlogin",
    component: () => import("@/views/ddlogin/index.vue"),
  },
  {
    path: "/form",
    name: "form",
    component: () => import("@/views/form/index.vue"),
  },
  {
    path: "/gs",
    name: "gs",
    component: () => import("@/views/gs/index.vue"),
  },
  {
    path: "/flow",
    name: "flow",
    component: () => import("@/views/flow/index.vue"),
  },
  {
    path: "/FormSub",
    name: "FormSub",
    component: () => import("@/views/FormSub/index.vue"),
  },
  {
    path: "/gridLayout",
    name: "gridLayout",
    component: () => import("@/views/gridLayout/index.vue"),
  },
  {
    path: "/ChatWithin",
    name: "ChatWithin",
    component: () => import("@/views/chatWithin/index.vue"),
  },
  {
    path: "/DataSheet",
    name: "DataSheet",
    component: () => import("@/views/chatWithin/src/dataSheet/index.vue"),
  },
  {
    path: "/chartsTab",
    name: "chartsTab",
    component: () => import("@/views/chartsTab/index.vue"),
  },
  {
    path: "/luckySheet",
    name: "luckySheet",
    component: () => import("@/views/luckysheet/index.vue"),
  },
  {
    path: "/chartRelease",
    name: "chartRelease",
    component: () => import("@/views/chartRelease/index.vue"),
  },
  {
    path: "/noPermissionForm",
    name: "noPermissionForm",
    component: () => import("@/views/noPermissionForm/formSubmit.vue"),
  },
  {
    path: "/dataView",
    name: "dataView",
    component: () => import("@/views/dataView/index.vue"),
  },
  {
    path: "/dataAssistant",
    name: "dataAssistant",
    component: () => import("@/views/dataAssistant/index.vue"),
  },
];
//  解决触发导航守卫问题
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) {
//     return originalPush.call(this, location, onResolve, onReject);
//   }
//   return originalPush.call(this, location).catch((err) => err);
// };

const router = new VueRouter({
  routes,
});

function getUrlKey(name) {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
        location.href
      ) || [, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
}
router.beforeEach((to, from, next) => {
  if (
    store.state.user.token ||
    to.path === "/FormSub" ||
    to.path === "/ddlogin" ||
    to.path === "/commission"
  ) {
    next();
    console.log(store.state, "哈哈哈哈")


    store.dispatch("app/setRouteName", to.name);
    if (
      to.path === "/todolist" ||
      to.path === "/todoList" ||
      to.path === "/commission"
    ) {
      if (store.state.app.allDepartList.length === 0) {
        store.dispatch("app/setAllDepart");
        store.dispatch("app/setAllMenber");
      }
    }
  } else {
    if (to.path === "/login") {
      next();
    } else {
      let herf = window.location.href;
      if (
        window.location.href.split("#")[1].split("?")[0] == "/noPermissionForm"
      ) {
        next();
      } else if (window.location.href.includes("todolist")) {
        next();
      } else {
        // next(
        //   `/login?id=${getUrlKey("id")}&formId=${getUrlKey(
        //     "formId"
        //   )}&isUrl=${getUrlKey("isUrl")}&appName=${decodeURIComponent(
        //     window.location.href.split("appName=")[1]
        //   )}`
        // );

        next(
          `/login?id=${getUrlKey("id")}&formId=${getUrlKey(
            "formId"
          )}&isUrl=${getUrlKey("isUrl")}`
        );
      }

      // `/login?id=${id}&formId=${formId}&isUrl=${isUrl}&appName=${appName}&formInfo=${formInfo}`
    }
  }
});
export default router;
