<template>
  <div>
    <div v-if="!data.takePictures">
      <el-upload :ref="data.__vModel__" :file-list="fileList" :action="data.action" :multiple="data.multiple"
        :accept="data.accept" :before-upload="beforeUploadFun" :limit="limit" :on-success="successFun"
        :on-remove="removeFun" name="file" list-type="picture-card" :disabled="data.disabled" :on-preview="previewFun"
        :on-exceed="exceedFun" :data="{
          app_id: appId,
          form_id: formId,
          additionalData: data.compressedUpload,
        }">
        <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"> -->
        <i class="el-icon-plus avatar-uploader-icon"></i>
        <!-- <el-button size="small" type="primary" icon="el-icon-upload" :disabled="data.disabled">{{ "点击上传" }}</el-button> -->
        <div slot="tip" class="el-upload__tip">
          只能上传不超过 {{ data.__config__.fileSize || 2
          }}{{ data.__config__.sizeUnit || "M" }} 的图片
        </div>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible" append-to-body :modal-append-to-body="false">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>
    <div v-else>
      <span style="color: red">拍照上传</span>（该功能仅支持移动端使用）
    </div>
  </div>
</template>

<script>
import { compressImage } from "@/utils/compressImg.js";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    formId: {
      type: String,
    },
    appId: {
      type: String,
    },
  },
  name: "MyUpload",
  data () {
    return {
      fileList: [],
      attachmen_id: "",
      limit: 99,
      imageUrl: "",
      dialogVisible: false,
      dialogImageUrl: "",
    };
  },
  watch: {
    data: {
      handler (newVal) {
        if (!newVal.multiple) {
          this.limit = 1;
        } else {
          this.limit = 99;
        }
      },
      deep: true,
    },
  },

  components: {},
  mounted () { },
  created () {
    setTimeout(() => {
      if (
        this.data.__config__.defaultValue &&
        this.data.__config__.defaultValue.length > 0
      ) {
        this.data.__config__.defaultValue.forEach((item) => {
          if (item.response) {
            this.$set(
              item,
              "url",
              `${window.location.origin}/api/upload/handleRequest?attachmen_id=` +
              item.response.data.attachment_id
            );
            this.fileList.push(item);
          }
        });
      }
    }, 500);
    this.$set(
      this.data,
      "action",
      process.env.NODE_ENV === "development"
        ? "/Api/api/upload/upload"
        : `${window.location.origin}/api/upload/upload`
    );
  },
  methods: {
    beforeUploadFun (file) {
      const allowHook = {
        // video: ["mp4", "ogv", "ogg", "webm", "wav", "mp3", "acc", "amr"],
        // file: [
        //   "doc",
        //   "docx",
        //   "xlsx",
        //   "xls",
        //   "pdf",
        //   "txt",
        //   "zip",
        //   "pptx",
        //   "json",
        // ],
        img: ["jpg", "jpeg", "png", "gif"],
      };

      if (this.data.accept === "image") {
        if (
          allowHook.img.indexOf(
            file.name.split(".")[file.name.split(".").length - 1]
          ) === -1
        ) {
          this.$message.error("只能上传图片");
          return false;
        }
      }
      if (!this.data.__config__.fileSize) {
        return;
      }

      let fileSize;
      if (this.data.__config__.sizeUnit == "GB") {
        fileSize = file.size / 1024 / 1024 / 1024;
      } else if (this.data.__config__.sizeUnit == "MB") {
        fileSize = file.size / 1024 / 1024;
      } else if (this.data.__config__.sizeUnit == "KB") {
        fileSize = file.size / 1024;
      }
      let isRightSize = fileSize < this.data.__config__.fileSize * 1;
      if (!isRightSize) {
        this.$message.error(
          "文件大小超过 " +
          this.data.__config__.fileSize +
          this.data.__config__.sizeUnit
        );
        return false;
      }
      // if (this.data.compressedUpload) {
      //   const compressRatio = this.data.ratioNum;
      //   compressImage(file, compressRatio, compressedBlob => {
      //     // 使用压缩后的blob进行上传
      //     const formData = new FormData();
      //     formData.append('file', compressedBlob, file.name);
      //     // 这里使用axios进行上传，你可以根据实际情况使用其他方式上传
      //     axios.post(this.uploadUrl, formData).then(response => {
      //       this.successFun(response.data);
      //     }).catch(error => {
      //       console.error('上传失败', error);
      //     });
      //   });
      //   return false;
      // }
    },
    exceedFun () {
      this.$message.warning("只能上传一张图片");
    },
    successFun (response, file, fileList) {
      if (response.code === -1) {
        // 上传失败，删除文件
        const index = fileList.findIndex((item) => item.uid === file.uid);
        if (index > -1) {
          fileList.splice(index, 1);
        }
        this.$message.error("文件上传失败！");
      } else {
        this.fileList = fileList;

        this.sendValue();
      }
    },
    removeFun (file, fileList) {
      this.fileList = fileList;
      this.sendValue();
    },
    previewFun (file) {
      // window.open(
      //   `${window.location.origin}/api/upload/handleRequest?attachmen_id=`+file.response.data.attachment_id
      // );
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    sendValue () {
      let obj = {
        value: this.fileList,

        name: this.data.__vModel__,
      };
      console.log(this.fileList, "嘿嘿嘿嘿");

      this.$emit("value", obj);
    },
  },
};
</script>

<style lang="scss"  scoped>
::v-deep .el-upload-list__item-name {
  font-size: 14px;
}

::v-deep .add-form-box .el-dialog {
  margin: 10vh 40vh !important;
}

::v-deep .el-dialog__body {
  height: 100% !important;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 22px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
  /* border: 1px dashed #d9d9d9; */
}

.avatar {
  width: 148px;
  height: 148px;
  display: block;
}
</style>
