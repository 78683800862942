<template>
  <div>
    <!-- <div v-if="num_radio==1">
      <div v-for="(item, index) in gl_field_filter" :key="item.label">
        <label>{{item.label}}</label>
        <el-input placeholder="暂无内容" :disabled="true">
        </el-input>
      </div>
    </div>
    <div v-else-if="num_radio==2">
      <el-table style="width: 100%">
        <el-table-column v-for="(item, index) in gl_field_filter" :key="item.label" :label="item.label" width="120">
        </el-table-column>
      </el-table>
    </div> -->

    <dataDetails :headData="gl_field_filter" :multi="multi" :searchType="true"></dataDetails>



  </div>
</template>
<script>
import dataDetails from "@/components/relatedDatatable/dataDetails.vue"
export default {
  name: 'relationSearch',
  components: {
    dataDetails
  },
  data () {
    return {
      condition_value: 'and',
      multi: false
    }
  },
  props: {
    gl_field_filter: {
      type: Array,
      default: () => []
    },
    gl_table_all_column: {
      type: Array,
      default: () => []
    },

    num_radio: {
      type: String,
      default: '1'
    },
    model_radio: {
      type: String,
      default: '1'
    },
  },
  methods: {

  },
  watch: {
    num_radio (val) {
      if (val == '1') {
        this.multi = false
      } else {
        this.multi = true
      }

    }

  },
  mounted () {
    if (this.num_radio == '1') {
      this.multi = false
    } else {
      this.multi = true
    }
  },
}

</script>
<style lang="scss" scoped></style>
