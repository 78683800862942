import {
  funcList
} from "@/utils/funcList";
import {
  Decimal
} from "decimal.js";
import {
  calculate
} from "@/utils/math.js";
import {
  isInteger
} from "@/utils/index";
//import * as math from "mathjs";
import {
  create,
  all
} from 'mathjs';
import {
  isPromise
} from "@/utils/index";
import moment from 'moment';
import {
  Tabs
} from "element-ui";
const math = create(all);

// 设置全局精度
math.config({
  number: 'BigNumber', // 使用 BigNumber 类型进行高精度计算
  precision: 20 // 设置精度为 20 位小数
});
export default {
  methods: {
    getForMulaReplace(type, str) {
      let result = str.slice(type.length + 1, str.length - 1);


      // const regex = /,(?![^(]*\))/g; // 匹 配不在括号中的逗号

      const arr = this.splitIgnoringCommas(result); // 使用正则表达式进行分隔


      return arr;
    },
    getIfMulaReplace(type, str) {

      let result = str.slice(type.length + 1, str.length - 1);

      // const regex = /,(?![^(]*\))/g; // 匹配不在括号中的逗号
      const arr = this.splitIgnoringCommas(result); // 使用正则表达式进行分隔
      let obj = this.parseCondition(arr[0]);
      let list = [];
      list.push(obj.beforeOperator);
      list.push(obj.afterOperator);
      list.push(obj.operator);
      list.push(arr[1]);
      list.push(arr[2]);

      return list;
    },
    getOrMulaReplace(type, str) {
      let result = str.slice(type.length + 1, str.length - 1);

      // const regex = /,(?![^(]*\))/g; // 匹配不在括号中的逗号
      const arr = this.splitIgnoringCommas(result); // 使用正则表达式进行分隔

      let list = [];
      arr.forEach((item, index) => {
        let obj = this.parseCondition(item);
        list.push(obj.beforeOperator);
        list.push(obj.afterOperator);
        list.push(obj.operator);
      });
      return list;
    },
    getAndMulaReplace(type, str) {
      let result = str.slice(type.length + 1, str.length - 1);

      // const regex = /,(?![^(]*\))/g; // 匹配不在括号中的逗号
      const arr = this.splitIgnoringCommas(result); // 使用正则表达式进行分隔

      let list = [];
      arr.forEach((item, index) => {
        let obj = this.parseCondition(item);
        list.push(obj.beforeOperator);
        list.push(obj.afterOperator);
        list.push(obj.operator);
      });
      return list;
    },
    getIfsMulaReplace(type, str) {
      let result = str.slice(type.length + 1, str.length - 1);

      // const regex = /,(?![^(]*\))/g; // 匹配不在括号中的逗号
      const arr = this.splitIgnoringCommas(result); // 使用正则表达式进行分隔

      let list = [];
      arr.forEach((item, index) => {
        if (index % 2 === 0) {
          let obj = this.parseCondition(item);
          list.push(obj.beforeOperator);
          list.push(obj.afterOperator);
          list.push(obj.operator);
          list.push(arr[index + 1]);
        }
      });

      return list;
    },
    splitIgnoringCommas(str) {

      const result = [];
      let segment = "";
      let openParentheses = 0;

      for (let i = 0; i < str.length; i++) {
        const char = str.charAt(i);

        if (char === "(") {
          openParentheses++;
        } else if (char === ")") {
          openParentheses--;
        }

        if (char === "," && openParentheses === 0) {
          result.push(segment.trim());
          segment = "";
        } else {
          segment += char;
        }
      }





      result.push(segment.trim());

      return result;
    },
    getForMulaData(data) {
      let bool = "";

      if (data.formula && data.formula.type) {
        bool = funcList.some((item) => item.type === data.formula.type) || data.formula.type === "-COUNTIF";
      }

      if (bool) {
        let formula = {};
        if (
          data.formula.type === "CONCATENATE" ||
          data.formula.type === "SUM" ||
          data.formula.type === "RIDE" || data.formula.type === "MINUS" || data.formula.type === "DISCUSS" ||
          data.formula.type === "MAX" ||
          data.formula.type === "MIN" ||
          data.formula.type === "AVERAGE" ||
          data.formula.type === "DATEDIF" ||
          data.formula.type === "COS" ||
          data.formula.type === "COT" ||
          data.formula.type === "SIN" ||
          data.formula.type === "ROUND" ||
          data.formula.type === "COUNT" ||
          data.formula.type === "COUNTIF" ||
          data.formula.type === "-COUNTIF" ||
          data.formula.type === "FIXED" ||
          data.formula.type === "LARGE" ||
          data.formula.type === "JOIN" ||
          data.formula.type === "LEFT" ||
          data.formula.type === "TEXTLOCATION" ||
          data.formula.type === "DISTANCE" ||
          data.formula.type === "DAYS" ||
          data.formula.type === "INDEX" ||
          data.formula.type === "RMBCAP" ||
          data.formula.type === "ABS" ||
          data.formula.type === "RADIANS" ||
          data.formula.type === "INT" ||
          data.formula.type === "LEN" ||
          data.formula.type === "LOWER" ||
          data.formula.type === "DATE" ||
          data.formula.type === "DATEDELTA" ||
          data.formula.type === "DAY" ||
          data.formula.type === "HOUR" ||
          data.formula.type === "ISOWEEKNUM" ||
          data.formula.type === "MINUTE" ||
          data.formula.type === "MONTH" ||
          data.formula.type === "NOW" ||
          data.formula.type === "YEAR" ||
          data.formula.type === "WEEKDAY" ||
          data.formula.type === "DATEFORMAT" ||
          data.formula.type === "TODAY" ||
          data.formula.type === "TEXT" ||
          data.formula.type === "SUMPRODUCT" ||
          data.formula.type === "ISEMPTY" ||
          data.formula.type === "VALUE" ||
          data.formula.type === "LENGTH"
        ) {
          let item = this.getForMulaReplace(
            data.formula.type,
            data.formula.formula
          );

          if (Array.isArray(item) && item.length > 0) {
            formula.type = data.formula.type;
            formula.formula = item;
            formula.vModel = data.__vModel__;
            this.formula[data.__vModel__] = formula;

          }
        } else if (data.formula.type === "LOWERSSSS") {
          let item = data.formula.formula.split("(")[1].split(")")[0];

          formula.type = data.formula.type;
          formula.formula = [item];
          formula.vModel = data.__vModel__;
          this.formula[data.__vModel__] = formula;
        } else if (data.formula.type === "IF") {
          let item = this.getForMulaReplace(
            data.formula.type,
            data.formula.formula
          );

          let result = this.parseCondition(item[0]);

          let list = [];
          list.push(result.beforeOperator);
          list.push(result.afterOperator);
          list.push(result.operator);
          list.push(item[1]);
          list.push(item[2]);
          if (Array.isArray(item) && item.length > 0) {
            formula.type = data.formula.type;
            formula.formula = list;
            formula.vModel = data.__vModel__;
            this.formula[data.__vModel__] = formula;
          }

        } else if (data.formula.type === "OR") {
          let list = [];
          let item = this.getForMulaReplace(
            data.formula.type,
            data.formula.formula
          );
          item.forEach((item, index) => {
            let obj = this.parseCondition(item);
            list.push(obj.beforeOperator);
            list.push(obj.afterOperator);
            list.push(obj.operator);
          });
          if (Array.isArray(item) && item.length > 0) {
            formula.type = data.formula.type;
            formula.formula = list;
            formula.vModel = data.__vModel__;
            this.formula[data.__vModel__] = formula;
          }

        } else if (data.formula.type === "AND") {
          let list = [];
          let item = this.getForMulaReplace(
            data.formula.type,
            data.formula.formula
          );
          item.forEach((item, index) => {
            let obj = this.parseCondition(item);
            list.push(obj.beforeOperator);
            list.push(obj.afterOperator);
            list.push(obj.operator);
          });
          if (Array.isArray(item) && item.length > 0) {
            formula.type = data.formula.type;
            formula.formula = list;
            formula.vModel = data.__vModel__;
            this.formula[data.__vModel__] = formula;
          }
        } else if (data.formula.type === "IFS") {
          let list = [];
          let item = this.getForMulaReplace(
            data.formula.type,
            data.formula.formula
          );

          item.forEach((item2, index) => {
            if (index % 2 === 0) {
              let obj = this.parseCondition(item2);
              list.push(obj.beforeOperator);
              list.push(obj.afterOperator);
              list.push(obj.operator);
              list.push(item[index + 1]);
            }
          });
          if (Array.isArray(item) && item.length > 0) {
            formula.type = data.formula.type;
            formula.formula = list;
            formula.vModel = data.__vModel__;
            this.formula[data.__vModel__] = formula;
          }

        }
      }
    },
    getFiedlsMula(data, formula, text, node) {
      let list = [];
      let str = text;

      funcList.forEach((item) => {
        list.push(item.type);
      });

      const regex = /(\w+)\(/; // 匹配第一个括号前的内容

      const match = formula ? formula.match(regex) : ""; // 使用正则表达式进行匹配

      let item = [];

      if (match && match.length > 1) {
        let type = "";
        type = match[1];

        if (type === "IF") {
          item = this.getIfMulaReplace(type, formula);
        } else if (type === "OR") {
          item = this.getOrMulaReplace(type, formula);
        } else if (type === "AND") {
          item = this.getAndMulaReplace(type, formula);
        } else if (type === "IFS") {
          item = this.getIfsMulaReplace(type, formula);
        } else {
          item = this.getForMulaReplace(type, formula);
        }

        let query = {
          type: type,
          vModel: node.vModel,
        };
        let ntr = "";
        let list = [];

        item.forEach((itemA) => {

          if (typeof this.getFiedlsMula(data, itemA, str, query)) {
            if (this.getFiedlsMula(data, itemA, str, query)) {
              list.push(this.getFiedlsMula(data, itemA, str, query));
              list.push(type);
            }
          } else {
            ntr = ntr + this.getFiedlsMula(data, itemA, str, query);
          }
        });

        if (formula === "NOW()") {
          return this.getNow([])
        } else if (formula === 'TODAY()') {
          return this.getToDay([])
        } else {
          return ntr ? ntr : list;
        }


      } else {

        if (node.type === 'NOW') {
          return this.getNow([])
        } else {

          str = this.getMulaData(data, node.type, formula, str, node.vModel);

          return str ? str : "";
        }

      }
    },
    getMulaData(data, type, el, node, vModel) {
      let str = node
      str = this.getFiedsData(data, type, el, node, vModel);


      return str ? str : el;
    },

    getArrData(arr) {

      let list = [];
      funcList.forEach((item) => {
        list.push(item.type);
      });

      arr.forEach((item) => {
        if (Array.isArray(item)) {
          if (list.indexOf(item[item.length - 1]) > -1) {
            for (let i = 0; i < item.length - 1; i++) {
              if (item[i] === item[item.length - 1]) {
                item.splice(i, 1);
              }
            }
          }

          this.getArrData(item);
        }
      });

      let arrList = JSON.parse(JSON.stringify(arr));

      arrList = this.clearVW(arrList);

      let data = this.getArrayListData(arrList);

      data.forEach((item) => {
        if (Array.isArray(item)) {
          data = this.getArrayListData(data);

        }
      });

      return data;
    },
    getTableArrData(arr) {

      let list = [];
      funcList.forEach((item) => {
        list.push(item.type);
      });

      arr.forEach((item) => {
        if (Array.isArray(item)) {
          if (list.indexOf(item[item.length - 1]) > -1) {
            for (let i = 0; i < item.length - 1; i++) {
              if (item[i] === item[item.length - 1]) {
                item.splice(i, 1);
              }
            }
          }
          this.getArrData(item);
        }
      });

      let arrList = JSON.parse(JSON.stringify(arr));
      arrList = this.clearVW(arrList);

      let data = this.getTableArrayListData(arrList);
      data.forEach((item) => {
        if (Array.isArray(item)) {
          this.getTableArrayListData(arrList);
        }
      });

      return data;
    },
    getTableArrayListData(arrList) {
      let list = [];
      funcList.forEach((item) => {
        list.push(item.type);
      });

      arrList.forEach((item, index) => {
        if (Array.isArray(item) && item.length > 0) {
          item.forEach((itemA, index) => {
            const arr = (itemA) => {
              let type = "";
              if (Array.isArray(itemA)) {
                type = itemA[itemA.length - 1];
              }

              if (list.indexOf(type) !== -1) {
                itemA.splice(itemA.length - 1, 1);

                this.$set(
                  item,
                  index,
                  this.getTableFiledsChangeData(itemA, type)
                );
              }
            };
            arr(itemA);
          });
          let type = item[item.length - 1];
          if (list.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(
              arrList,
              index,
              this.getTableFiledsChangeData(item, type)
            );
          }
        } else {}
      });

      return arrList;
    },
    getTableFiledsChangeData(data, type) {
      let str = "";

      if (type === "CONCATENATE") {
        str = this.getConcatEnate2(data);
      } else if (type === "SUM") {
        str = this.getSum2(data);
      } else if (type === "RIDE") {
        str = this.getRide2(data);
      } else if (type === "DISCUSS") {
        str = this.getDiscuss2(data);
      } else if (type === "MINUS") {
        str = this.getMinus2(data);
      } else if (type === "MAX") {
        str = this.getMax2(data);
      } else if (type === "MIN") {
        str = this.getMin2(data);
      } else if (type === "AVERAGE") {
        str = this.getAverage2(data);
      } else if (type === "DATEDIF") {
        str = this.getDateDif2(data);
      } else if (type === "RMBCAP") {
        str = this.getRmbCap2(data);
      } else if (type === "IF") {
        str = this.getIf2(data);
      } else if (type === "OR") {
        str = this.getOr2(data);
      } else if (type === "AND") {
        str = this.getAnd2(data);
      } else if (type === "ABS") {
        str = this.getAbs2(data);
      } else if (type === "RADIANS") {
        str = this.getRadians2(data);
      } else if (type === "COS") {
        str = this.getCos2(data);
      } else if (type === "COT") {
        str = this.getCot2(data);
      } else if (type === "SIN") {
        str = this.getSin2(data);
      } else if (type === "ROUND") {
        str = this.getRound2(data);
      } else if (type === "COUNT") {
        str = this.getCountr2(data);
      } else if (type === "COUNTIF" || type === "-COUNTIF") {
        str = this.getCountif2(data);
      } else if (type === "FIXED") {
        str = this.getFixed2(data);
      } else if (type === "INT") {
        str = this.getInt2(data);
      } else if (type === "LARGE") {
        str = this.getLarge2(data);
      } else if (type === "JOIN") {
        str = this.getJoin2(data);
      } else if (type === "LEFT") {
        str = this.getLeft2(data);
      } else if (type === "LEN") {
        str = this.getLen2(data);
      } else if (type === "LOWER") {
        str = this.getLower2(data);
      } else if (type === "TEXTLOCATION") {
        str = this.getTextlocation2(data);
      } else if (type === "IFS") {
        str = this.getIfs2(data);
      } else if (type === "DISTANCE") {
        str = this.getDistance2(data);
      } else if (type === "DAYS") {
        str = this.getDays2(data);
      } else if (type === "INDEX") {
        str = this.getIndex2(data);
      } else if (type === "DATE") {
        str = this.getDate2(data);
      } else if (type === "DATEDELTA") {
        str = this.getDateDelta2(data);
      } else if (type === "DAY") {
        str = this.getDay2(data);
      } else if (type === "HOUR") {
        str = this.getHour2(data);
      } else if (type === "ISOWEEKNUM") {
        str = this.getIsoWeekNum2(data);
      } else if (type === "MINUTE") {
        str = this.getMinute2(data);
      } else if (type === "MONTH") {
        str = this.getMonth2(data);
      } else if (type === "NOW") {
        str = this.getNow2(data);
      } else if (type === "YEAR") {
        str = this.getYear2(data);
      } else if (type === "WEEKDAY") {
        str = this.getWeekDay2(data);
      } else if (type === "DATEFORMAT") {
        str = this.getDateFormat2(data);
      } else if (type === "TODAY") {
        str = this.getToDay2(data);
      } else if (type === "TEXT") {
        str = this.getText2(data);
      } else if (type === "VALUE") {
        str = this.getValue2(data);
      } else if (type === "ISEMPTY") {
        str = this.getIsempty2(data);
      }
      return str;
    },

    getConcatEnate2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });

      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });
      for (let i = 0; i < len; i++) {
        let str = "";
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str = str + item[i];
          } else {
            str = str + item;
          }
        });
        list.push(str);
      }

      return list;
    },
    getSum2(data) {

      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });


      const arraysOnly = data.filter(item => Array.isArray(item));


      len = arraysOnly.reduce((maxArr, currentArr) => {
        return currentArr.length > maxArr.length ? currentArr : maxArr;
      }, []).length;



      for (let i = 0; i < len; i++) {
        let str = null;
        data.forEach((item) => {


          if (Array.isArray(item)) {
            if (this.isNUmber(item[i])) {
              str = this.compute(Number(str), Number(item[i]));

            }
          } else {
            if (this.isNUmber(item)) {
              str = this.compute(Number(str), Number(item));

            }
          }
        });

        if (str) {
          list.push(str);
        }


      }
      if (len === 0) {
        let str = null;
        data.forEach((item) => {


          if (Array.isArray(item)) {
            if (this.isNUmber(item[i])) {
              str = this.compute(Number(str), Number(item[i]));

            }
          } else {
            if (this.isNUmber(item)) {
              str = this.compute(Number(str), Number(item));

            }
          }
        });
        list.push(str);
      }
      return list;
    },
    getRide2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });


      const arraysOnly = data.filter(item => Array.isArray(item));


      len = arraysOnly.reduce((maxArr, currentArr) => {
        return currentArr.length > maxArr.length ? currentArr : maxArr;
      }, []).length;




      for (let i = 0; i < len; i++) {
        let str = null;
        let arr = []
        data.forEach((item) => {


          if (Array.isArray(item)) {
            if (this.isNUmber(item[i])) {

              arr.push(item[i])
            } else {
              arr.push(0)
            }
          } else {
            if (this.isNUmber(item)) {

              arr.push(item)
            } else {
              arr.push(0)
            }
          }
        });

        if (arr.length > 0) {
          str = arr.reduce((acc, num) => math.multiply(acc, num));
          list.push(str);
        }


      }
      return list;
    },
    getDiscuss2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });


      const arraysOnly = data.filter(item => Array.isArray(item));


      len = arraysOnly.reduce((maxArr, currentArr) => {
        return currentArr.length > maxArr.length ? currentArr : maxArr;
      }, []).length;




      for (let i = 0; i < len; i++) {
        let str = null;
        let arr = []
        data.forEach((item) => {


          if (Array.isArray(item)) {
            if (this.isNUmber(item[i])) {

              arr.push(item[i])
            }
          } else {
            if (this.isNUmber(item)) {

              arr.push(item)
            }
          }
        });

        if (arr.length > 0) {

          str = arr.reduce((acc, num) => math.divide(acc, num));
          list.push(str);
        }


      }
      return list;
    },
    getMinus2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });


      const arraysOnly = data.filter(item => Array.isArray(item));


      len = arraysOnly.reduce((maxArr, currentArr) => {
        return currentArr.length > maxArr.length ? currentArr : maxArr;
      }, []).length;



      for (let i = 0; i < len; i++) {
        let str = null;
        let arr = []
        data.forEach((item) => {


          if (Array.isArray(item)) {
            if (this.isNUmber(item[i])) {

              arr.push(item[i])
            }
          } else {
            if (this.isNUmber(item)) {

              arr.push(item)
            }
          }
        });

        if (arr.length === 2) {
          str = math.subtract(arr[0], arr[1]);
          list.push(str);
        }


      }

      return list;
    },
    getMax2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            if (this.isNUmber(item[i])) {
              str.push(item[i]);
            }
          } else {
            if (this.isNUmber(item)) {
              str.push(item);
            }
          }
        });
        list.push(Math.max(...str));
      }
      return list;
    },
    getMin2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        list.push(Math.min(...str));
      }
      return list;
    },
    getAverage2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        try {
          list.push(this.average(str));
        } catch {
          list.push("");
        }
      }
      return list;
    },
    getDateDif2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        list.push(
          this.getDateDiffer(new Date(str[0]), new Date(str[1]), str[2])
        );
      }
      return list;
    },
    getIf2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        list.push(str);
      }

      let result = [];

      list.forEach((item) => {
        let bool = true;
        if (item[2] === "=") {
          if (item[0] === item[1]) {
            bool = true;
          } else {
            bool = false;
          }
        } else if (item[2] === "!=") {
          if (item[0] !== item[1]) {
            bool = true;
          } else {
            bool = false;
          }
        } else {
          try {
            bool = eval(item[0] + `${item[2]}` + item[1]);
          } catch {}
        }

        if (bool) {
          result.push(item[3] === "''" ? "" : item[3]);
        } else {
          result.push(item[4] === "''" ? "" : item[4]);
        }
      });

      return result;
    },
    getOr2(data) {

      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      const arraysOnly = data.filter(item => Array.isArray(item));


      len = arraysOnly.reduce((maxArr, currentArr) => {
        return currentArr.length > maxArr.length ? currentArr : maxArr;
      }, []).length;

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        list.push(str);
      }
      let result = [];

      list.forEach((item) => {

        const newArray = Array.from({
            length: Math.ceil(item.length / 3)
          },
          (_, index) => item.slice(index * 3, index * 3 + 3)
        );
        let bool = false;

        newArray.forEach((item2) => {
          let isEqual = false;
          if (item2[2] === "=") {
            if (item2[0] === item2[1] || item2[0].includes(item2[1])) {
              isEqual = true;
            } else {
              isEqual = false;
            }
          } else {
            try {
              isEqual = eval(item2[0] + `${item2[2]}` + item2[1]);
            } catch {}
          }
          if (isEqual) {
            bool = true;
          }
        });
        if (bool) {
          result.push("true");
        } else {
          result.push("false");
        }
      });

      return result;
    },
    getAnd2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        list.push(str);
      }
      let result = [];
      list.forEach((item) => {
        const newArray = Array.from({
            length: Math.ceil(item.length / 3)
          },
          (_, index) => item.slice(index * 3, index * 3 + 3)
        );
        let bool = true;

        newArray.forEach((item2) => {
          let isEqual = false;

          if (item2[2] === "=") {
            if (item2[0] === item2[1]) {
              isEqual = true;
            } else {
              if (item2[1] === "''" && !item2[0]) {
                isEqual = true;
              } else {
                isEqual = false;
              }

            }

          } else if (item2[2] === "!=") {
            if (item2[0] !== item2[1]) {
              isEqual = true;
            } else {
              isEqual = false;
            }

          } else {
            try {
              isEqual = eval(item2[0] + `${item2[2]}` + item2[1]);

            } catch {}
          }

          if (!isEqual) {
            bool = false;
          }
        });
        if (bool) {
          result.push("true");
        } else {
          result.push("false");
        }
      });

      return result;
    },
    getIfs2(data) {

      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      const arraysOnly = data.filter(item => Array.isArray(item));


      len = arraysOnly.reduce((maxArr, currentArr) => {
        return currentArr.length > maxArr.length ? currentArr : maxArr;
      }, []).length;

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        list.push(str);
      }
      let result = [];

      list.forEach((item) => {
        const newArray = Array.from({
            length: Math.ceil(item.length / 4)
          },
          (_, index) => item.slice(index * 4, index * 4 + 4)
        );
        let bool = [];


        newArray.forEach((item2) => {
          let isEqual = false;
          if (item2[2] === "=") {
            if (item2[0] === item2[1]) {
              isEqual = true;
            } else {
              isEqual = false;
            }
          } else {
            try {
              isEqual = eval(item2[0] + `${item2[2]}` + item2[1]);
            } catch {}
          }
          if (isEqual) {
            bool.push(item2[3]);
          }
        });

        if (bool.length > 0) {
          result.push(bool[0]);
        } else {
          result.push("暂无满足条件");
        }
      });
      return result;
    },
    getRmbCap2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        list.push(this.smallToBig(str[0]));
      }
      return list;
    },
    getAbs2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        list.push(Math.abs(Number(str[0])));
      }
      return list;
    },
    getRadians2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        list.push(Number(str[0]) * (Math.PI / 180));
      }
      return list;
    },
    getCos2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        list.push(Math.cos(str[0]));
      }
      return list;
    },
    getCot2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        list.push(1 / Math.tan(str[0]));
      }
      return list;
    },
    getSin2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        list.push(Math.sin(str[0]));
      }
      return list;
    },
    getRound2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });

        list.push(Number(str[0]).toFixed(Number(str[1])));
      }
      return list;
    },
    getCountr2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });

        list.push(str.toString() ? str.toString().split(",").length : 0);


      }
      return list;
    },
    getCountif2(data) {
      return this.countMatches(data[0], data[1]);
    },
    getFixed2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        list.push(`"${Number(str[0]).toFixed(Number(str[1]))}"`);
      }
      return list;
    },
    getInt2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        list.push(Math.floor(str[0]));
      }
      return list;
    },
    getLarge2(data) {
      data[0].sort((a, b) => b - a);
      let arr = data[0];
      let n = data[1];
      return arr[n - 1];
    },
    getJoin2(data) {
      return data[0].join(data[1]);
    },
    getTextlocation2(data) {
      if (data[1] === "address") {
        return data[0][0].split(",").join("/");
      } else if (data[1] === "province") {
        return data[0][0].split(",")[0];
      } else if (data[1] === "city") {
        return data[0][0].split(",")[1];
      } else if (data[1] === "district") {
        return data[0][0].split(",")[2];
      } else if (data[1] === "detail") {
        return data[0][0].split(",")[3];
      }
    },
    getLeft2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        list.push(str[0].slice(0, str[1]));
      }
      return list;
    },
    getLen2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        list.push(str[0].length);
      }
      return list;
    },
    getLower2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        list.push(str[0].toLowerCase());
      }
      return list;
    },
    getDays2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        const date1 = new Date(str[0]);
        const date2 = new Date(str[1]);
        let days = this.daysBetween(date1, date2);
        if (days) {
          list.push(days);
        } else {
          list.push("");
        }

      }

      return list;
    },
    getIndex2(data) {
      let arr = data[0];
      let index = data[1];
      let length = arr.length;
      let idx = index >= 0 ? Number(index) - 1 : length + Number(index) - 1;
      return arr[idx];
    },
    getDate2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });

        if (this.isTimestamp(str[0])) {
          list.push(str[0])
        } else {
          list.push(new Date(str[0]).getTime())
        }

      }
      return list;
    },
    getDateDelta2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });
        let timestamp = Number(str[1]) * 1000 * 60 * 60 * 24
        if (this.isTimestamp(str[0])) {
          let dataTimestamp = str[0] + timestamp
          list.push(this.formatTimestamp(dataTimestamp))
        } else {
          let dataTimestamp = ""

          if (str[0] === 'NOW()') {
            dataTimestamp = new Date().getTime() + timestamp

          } else {
            dataTimestamp = new Date(str[0]).getTime() + timestamp
          }

          if (this.isTimestamp(dataTimestamp)) {
            list.push(this.formatTimestamp(dataTimestamp))

          }
        }


      }
      return list;
    },
    getDay2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });


        if (this.isTimestamp(str[0])) {



          list.push(String(new Date(str[0]).getDate()))
        } else {
          let dataTimestamp = ""

          if (str[0] === 'NOW()') {
            dataTimestamp = new Date().getTime();

          } else {
            dataTimestamp = new Date(str[0]).getTime()
          }

          if (this.isTimestamp(dataTimestamp)) {
            list.push(String(new Date(dataTimestamp).getDate()))

          }
        }

      }
      return list;
    },
    getHour2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });


        if (this.isTimestamp(str[0])) {



          list.push(String(new Date(str[0]).getHours()))
        } else {
          let dataTimestamp = ""

          if (str[0] === 'NOW()') {
            dataTimestamp = new Date().getTime();

          } else {
            dataTimestamp = new Date(str[0]).getTime()
          }

          if (this.isTimestamp(dataTimestamp)) {
            list.push(String(new Date(dataTimestamp).getHours()))

          }
        }

      }
      return list;
    },
    getIsoWeekNum2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });


        if (str[0] === 'NOW()') {
          list.push(this.getISOWeekNumber(this.formatTimestamp(new Date().getTime())))
        } else {
          list.push(this.getISOWeekNumber(str[0]))
        }


      }
      return list;
    },
    getMinute2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });


        if (this.isTimestamp(str[0])) {



          list.push(String(new Date(str[0]).getMinutes()))
        } else {
          let dataTimestamp = ""

          if (str[0] === 'NOW()') {
            dataTimestamp = new Date().getTime();

          } else {
            dataTimestamp = new Date(str[0]).getTime()
          }

          if (this.isTimestamp(dataTimestamp)) {
            list.push(String(new Date(dataTimestamp).getMinutes()))

          }
        }

      }
      return list;
    },
    getMonth2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });


        if (this.isTimestamp(str[0])) {



          list.push(String(new Date(str[0]).getMonth() + 1))
        } else {
          let dataTimestamp = ""

          if (str[0] === 'NOW()') {
            dataTimestamp = new Date().getTime();

          } else {
            dataTimestamp = new Date(str[0]).getTime()
          }

          if (this.isTimestamp(dataTimestamp)) {
            list.push(String(new Date(dataTimestamp).getMonth() + 1))

          }
        }

      }
      return list;
    },
    getNow2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });

      }
      if (str[0] === 'NOW()') {
        list.push(moment().format('YYYY-MM-DD HH:mm:ss'))
      }
      return list;
    },

    getYear2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });


        if (this.isTimestamp(str[0])) {



          list.push(String(new Date(str[0]).getFullYear()))
        } else {
          let dataTimestamp = ""

          if (str[0] === 'NOW()') {
            dataTimestamp = new Date().getTime();

          } else {
            dataTimestamp = new Date(str[0]).getTime()
          }

          if (this.isTimestamp(dataTimestamp)) {
            list.push(String(new Date(dataTimestamp).getFullYear()))

          }
        }

      }
      return list;
    },
    getWeekDay2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });

        const d = new Date(str[0]);
        list.push(d.getDay().toString())

      }
      return list;
    },
    getDateFormat2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });

        let arr = []

        if (str[0]) {
          str[1].split("-").forEach(item => {
            if (item === 'y') {
              arr.push(String(new Date(str[0]).getFullYear()))
            } else if (item === 'm') {
              arr.push(String(new Date(str[0]).getMonth() + 1))
            } else if (item === 'd') {
              arr.push(String(new Date(str[0]).getDate()))
            }
          })
          list.push(arr.join("-"))
        }



      }

      return list;
    },
    getToDay2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });



        if (str.length === 0) {
          list.push(this.formatTimestamp(new Date().getTime()))

        }



      }

      return list;
    },
    getText2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });


        if (str[0] && str.length === 1) {
          list.push(str[0].toString())
        } else if (str.length === 2) {
          list.push(moment(str[0], 'YYYY-MM-DD HH:mm:ss').format(str[1]).toString())
        }

      }

      return list;
    },
    getText2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });


        if (str[0] && Number(str[0])) {

          list.push(Number(str[0]))

        }

      }



      return list;
    },
    getIsempty2(data) {
      let len = 1;
      let typeList = [];
      let list = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getTableFiledsChangeData(item, type));
          }
        }
      });
      data.forEach((item) => {
        if (Array.isArray(item)) {
          len = item.length;
        }
      });

      for (let i = 0; i < len; i++) {
        let str = [];
        data.forEach((item) => {
          if (Array.isArray(item)) {
            str.push(item[i]);
          } else {
            str.push(item);
          }
        });


        if (!str[0] || str[0].length === 0) {
          list.push('1')

        } else {
          list.push('0')
        }

      }

      return list;
    },
    clearVW(data) {
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          this.clearVW(item);
        } else {
          if (item && item[0] === "v" && item[1] === "w") {
            item = "";
            this.$set(data, index, "");
          }
          if (item === -Infinity) {
            this.$set(data, index, "");
          }
          if (!item) {
            this.$set(data, index, "");
          }
        }
      });
      return data;
    },

    getArrayListData(arrList) {
      let list = [];
      funcList.forEach((item) => {
        list.push(item.type);
      });

      arrList.forEach((item, index) => {
        if (Array.isArray(item) && item.length > 0) {
          let type = item[item.length - 1];

          let isArray = item.some((itemA) => Array.isArray(itemA));

          if (!isArray) {
            if (list.indexOf(type) > -1) {
              item.splice(item.length - 1, 1);

              if (type === "CONCATENATE") {
                this.$set(arrList, index, this.getConcatEnate(item));
              } else if (type === "SUM") {

                this.$set(arrList, index, this.getSum(item));
              } else if (type === "RIDE") {
                this.$set(arrList, index, this.getRide(item));
              } else if (type === "DISCUSS") {
                this.$set(arrList, index, this.getDiscuss(item));
              } else if (type === "MINUS") {
                this.$set(arrList, index, this.getMinus(item));
              } else if (type === "AVERAGE") {
                this.$set(arrList, index, this.getAverage(item));
              } else if (type === "MAX") {
                this.$set(arrList, index, this.getMax(item));
              } else if (type === "MIN") {
                this.$set(arrList, index, this.getMin(item));
              } else if (type === "DATEDIF") {
                this.$set(arrList, index, this.getDateDif(item));
              } else if (type === "RMBCAP") {
                this.$set(arrList, index, this.getRmbCap(item));
              } else if (type === "IF") {
                this.$set(arrList, index, this.getIf(item));
              } else if (type === "OR") {

                this.$set(arrList, index, this.getOr(item));
              } else if (type === "AND") {
                this.$set(arrList, index, this.getAnd(item));
              } else if (type === "ABS") {
                this.$set(arrList, index, this.getAbs(item));
              } else if (type === "RADIANS") {
                this.$set(arrList, index, this.getRadians(item));
              } else if (type === "COS") {
                this.$set(arrList, index, this.getCos(item));
              } else if (type === "COT") {
                this.$set(arrList, index, this.getCot(item));
              } else if (type === "SIN") {
                this.$set(arrList, index, this.getSin(item));
              } else if (type === "ROUND") {
                this.$set(arrList, index, this.getRound(item));
              } else if (type === "COUNT") {
                this.$set(arrList, index, this.getCountr(item));
              } else if (type === "COUNTIF" || type === "-COUNTIF") {
                this.$set(arrList, index, this.getCountif(item, type));
              } else if (type === "FIXED") {
                this.$set(arrList, index, this.getFixed(item));
              } else if (type === "INT") {
                this.$set(arrList, index, this.getInt(item));
              } else if (type === "LARGE") {
                this.$set(arrList, index, this.getLarge(item));
              } else if (type === "LEFT") {
                this.$set(arrList, index, this.getLeft(item));
              } else if (type === "LEN") {
                this.$set(arrList, index, this.getLen(item));
              } else if (type === "LOWER") {
                this.$set(arrList, index, this.getLower(item));
              } else if (type === "JOIN") {
                this.$set(arrList, index, this.getJoin(item));
              } else if (type === "TEXTLOCATION") {
                this.$set(arrList, index, this.getTextlocation(item));
              } else if (type === "IFS") {
                this.$set(arrList, index, this.getIfs(item));
              } else if (type === "DISTANCE") {
                this.$set(arrList, index, this.getDistance(item));
              } else if (type === "DAYS") {
                this.$set(arrList, index, this.getDays(item));
              } else if (type === "INDEX") {
                this.$set(arrList, index, this.getIndex(item));
              } else if (type === "DATE") {
                this.$set(arrList, index, this.getDate(item));
              } else if (type === "DATEDELTA") {
                this.$set(arrList, index, this.getDateDelta(item));
              } else if (type === "DAY") {
                this.$set(arrList, index, this.getDay(item));
              } else if (type === "HOUR") {
                this.$set(arrList, index, this.getHour(item));
              } else if (type === "ISOWEEKNUM") {
                this.$set(arrList, index, this.getIsoWeekNum(item));
              } else if (type === "MINUTE") {
                this.$set(arrList, index, this.getMinute(item));
              } else if (type === "MONTH") {
                this.$set(arrList, index, this.getMonth(item));
              } else if (type === "NOW") {
                this.$set(arrList, index, this.getNow(item));
              } else if (type === "YEAR") {
                this.$set(arrList, index, this.getYear(item));
              } else if (type === "WEEKDAY") {
                this.$set(arrList, index, this.getWeekDay(item));
              } else if (type === "DATEFORMAT") {
                this.$set(arrList, index, this.getDateFormat(item));
              } else if (type === "TODAY") {
                this.$set(arrList, index, this.getToDay(item));
              } else if (type === "TEXT") {
                this.$set(arrList, index, this.getText(item));
              } else if (type === "VALUE") {
                this.$set(arrList, index, this.getValue(item));
              } else if (type === "SUMPRODUCT") {
                this.$set(arrList, index, this.getSumpRoduct(item));
              } else if (type === "ISEMPTY") {
                this.$set(arrList, index, this.getIsempty(item));
              } else if (type === "LENGTH") {
                this.$set(arrList, index, this.getLength(item));
              }
            }
          } else {
            this.getArrayListData(item);
          }
        }
      });

      return arrList;
    },

    getConcatEnate(data) {
      let str = "";
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      data.forEach((item, index) => {
        str = str + item;
      });

      return str ? str : "";
    },
    getSum(data) {

      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      data.forEach((item) => {
        if (this.isNUmber(item)) {
          str = this.compute(Number(str), Number(item));
        }
      });
      return str ? str : "";
    },
    getRide(data) {

      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      str = data.reduce((acc, num) => math.multiply(acc, num));

      return str ? str : "";
    },
    getDiscuss(data) {

      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });
      str = data.reduce((acc, num) => math.divide(acc, num));

      return str ? str : "";
    },
    getMinus(data) {

      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      if (data.length === 2 && this.isNUmber(data[0]) && this.isNUmber(data[1])) {

        str = math.subtract(data[0], data[1])
      }

      return str ? str : "";
    },
    getJoin(data) {

      let arr = JSON.parse(JSON.stringify(data[0]));
      arr = arr.split(",");
      arr.splice(arr.length - 1, 1);

      return arr.join(data[1]);
    },
    getTextlocation(data) {
      if (data[1] === "address") {
        return data[0].split(",").join("/");
      } else if (data[1] === "province") {
        return data[0].split(",")[0];
      } else if (data[1] === "city") {
        return data[0].split(",")[1];
      } else if (data[1] === "district") {
        return data[0].split(",")[2];
      } else if (data[1] === "detail") {
        return data[0].split(",")[3];
      }
    },
    getAverage(data) {
      let str = [];
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });
      data.forEach((item) => {
        str.push(item);
      });

      try {
        return this.average(str) ? this.average(str) : "";
      } catch {
        return "";
      }
    },
    getMax(data) {
      let str = [];
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });
      data.forEach((item) => {
        if (this.isNUmber(item)) {
          str.push(Number(item));
        }
      });

      return Math.max(...str);
    },
    getMin(data) {
      let str = [];
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });
      data.forEach((item) => {
        str.push(item);
      });

      return Math.min(...str);
    },
    getDateDif(data) {
      let str = [];
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });
      data.forEach((item) => {
        if (item) {
          str.push(item);
        }
      });

      return this.getDateDiffer(new Date(str[0]), new Date(str[1]), str[2]);
    },
    getIf(data) {
      let str = [];
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      data.forEach((item, index) => {
        if (index !== 2) {
          str.push(item);
        } else {
          str.push(item);
        }
      });
      let bool = false;
      if (str[2] === "=") {
        if (str[0] === str[1]) {
          bool = true;
        }
      } else if (str[2] === "!=") {
        if (str[0] !== str[1]) {
          bool = true;
        }
      } else {
        try {
          bool = eval(str[0] + `${str[2]}` + str[1]);
        } catch {}
      }

      if (bool) {
        return str[3] === "''" ? "" : str[3];
      } else {
        return str[4] === "''" ? "" : str[4];
      }
    },
    getOr(data) {

      let str = [];
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });
      data.forEach((item, index) => {
        if ((index + 1) % 3 !== 0) {
          str.push(item);
        } else {
          str.push(item);
        }
      });
      const newArray = Array.from({
          length: Math.ceil(str.length / 3)
        },
        (_, index) => str.slice(index * 3, index * 3 + 3)
      );

      let bool = false;

      newArray.forEach((item) => {
        let isEqual = false;
        if (item[2] === "=") {
          if (item[0] === item[1] || item[0].includes(item[1])) {
            isEqual = true;
          } else {
            isEqual = false;
          }
        } else {
          try {
            isEqual = eval(item[0] + `${item[2]}` + item[1]);
          } catch {}
        }
        if (isEqual) {
          bool = true;
        }
      });
      if (bool) {
        return "true";
      } else {
        return "false";
      }
    },
    getAnd(data) {
      let str = [];
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });
      data.forEach((item, index) => {
        if ((index + 1) % 3 !== 0) {
          str.push(item);
        } else {
          str.push(item);
        }
      });
      const newArray = Array.from({
          length: Math.ceil(str.length / 3)
        },
        (_, index) => str.slice(index * 3, index * 3 + 3)
      );
      let bool = true;

      newArray.forEach((item) => {
        let isEqual = false;
        if (item[2] === "=") {

          if (item[0] === item[1]) {
            isEqual = true;
          } else {
            if (item[1] === "''" && !item[0]) {
              isEqual = true;
            } else {
              isEqual = false;
            }

          }
        } else if (item[2] === "!=") {
          if (item[0] !== item[1]) {
            isEqual = true;
          } else {
            isEqual = false;
          }

        } else {
          try {
            isEqual = eval(item[0] + `${item[2]}` + item[1]);
          } catch {}
        }
        if (!isEqual) {
          bool = false;
        }
      });
      if (bool) {
        return "true";
      } else {
        return "false";
      }
    },
    getIfs(data) {

      let str = [];
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });
      data.forEach((item, index) => {
        if ((index + 1) % 4 !== 0) {
          str.push(item);
        } else {
          str.push(item);
        }
      });
      const newArray = Array.from({
          length: Math.ceil(str.length / 4)
        },
        (_, index) => str.slice(index * 4, index * 4 + 4)
      );

      let bool = [];
      newArray.forEach((item) => {
        let isEqual = false;
        if (item[2] === "=") {
          if (item[0] === item[1]) {
            isEqual = true;
          } else {
            isEqual = false;
          }
        } else {
          try {
            isEqual = eval(item[0] + `${item[2]}` + item[1]);
          } catch {}
        }
        if (isEqual) {
          bool.push(item[3]);
        }
      });

      if (bool.length > 0) {
        return bool[0];
      } else {
        return "暂无满足条件";
      }
    },
    getRmbCap(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      data.forEach((item) => {
        if (this.isNUmber(item)) {
          str = this.smallToBig(item);
        }
      });
      return str ? str : "";
    },
    getAbs(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });
      data.forEach((item) => {
        if (isInteger(item)) {
          str = Math.abs(Number(data[0]));
        }
      });
      // if (data.length === 1) {
      //   if (isInteger(data[0])) {
      //     return Math.abs(Number(data[0]));
      //   }
      // }
      return str ? str : "";
    },
    getRadians(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });
      data.forEach((item) => {
        if (isInteger(item)) {
          str = Number(item) * (Math.PI / 180);
        }
      });
      return str ? str : "";
    },
    getCos(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });
      data.forEach((item) => {
        if (isInteger(item)) {
          str = Math.cos(Number(item));
        }
      });
      return str ? str : "";
    },
    getCot(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });
      data.forEach((item) => {
        if (isInteger(item)) {
          str = 1 / Math.tan(Number(item));
        }
      });
      return str ? str : "";
      // if (data.length === 1) {
      //   if (isInteger(data[0])) {
      //     return 1 / Math.tan(Number(data[0]));
      //   }
      // }
    },
    getSin(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });
      data.forEach((item) => {
        if (isInteger(item)) {
          str = Math.sin(Number(item));
        }
      });
      return str ? str : "";
      // if (data.length === 1) {
      //   if (isInteger(data[0])) {
      //     return Math.sin(Number(data[0]));
      //   }
      // }
    },
    getRound(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });
      if (isInteger(data[0]) && isInteger([1]) && data.length === 2) {
        str = Number(data[0]).toFixed(Number(data[1]));
      }

      return str ? str : "";
      // if (isInteger(data[0]) && isInteger([1])) {
      //   return Number(data[0]).toFixed(Number(data[1]));
      // }
    },
    getCountr(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      if (data.length > 1) {
        str = data.length;
      } else if (data.length === 1 && Array.isArray(data[0])) {
        str = data[0].length
      } else {
        str = data[0].toString().split(",").length
      }


      return str ? str : "";
    },
    countMatches(arr, rule, type) {
      let count = 0;


      arr.forEach((item) => {
        // 直接匹配
        if (rule === item) {

          count++;
        } else if (rule.startsWith(">")) {

          let num = parseFloat(rule.slice(1)); // 提取大于后面的数字
          if (!isNaN(num) && parseFloat(item) > num) {
            count++;
          }
        } else if (rule.startsWith("<")) {

          let num = parseFloat(rule.slice(1)); // 提取小于后面的数字
          if (!isNaN(num) && parseFloat(item) < num) {
            count++;
          }
        } else if (rule.startsWith("!=")) {
          let num = rule.slice(2); // 提取小于后面的数字

          if (num != item) {

            count++;
          }
        }
      });
      if (type === "COUNTIF") {
        return count;
      } else {
        return -count
      }


    },
    getCountif(data, type) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      let arr = JSON.parse(JSON.stringify(data[0]));
      arr = arr.split(",");
      let rule = data[1];
      arr = arr.filter(item => item !== null && item !== undefined && item !== "");
      return this.countMatches(arr, rule, type);
    },
    getFixed(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      if (isInteger(data[0]) && isInteger([1]) && data.length === 2) {
        str = Number(data[0]).toFixed(Number(data[1]));
      }

      return str ? str : "";
    },
    getInt(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      if (data.length === 1) {
        if (isInteger(data[0])) {
          str = Math.floor(Number(data[0]));
        }
      }
      return str ? str : "";
    },
    getLarge(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });
      let arr = JSON.parse(JSON.stringify(data[0]));
      arr = arr.split(",");
      arr.sort((a, b) => b - a);
      let n = data[1];
      return arr[n - 1];
    },

    getLeft(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      if (data.length > 0) {
        str = data[0].slice(0, data[1]);
      }
      return str ? str : "";
    },
    getLen(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      if (data.length === 1) {
        str = data[0].length;
      }
      return str ? str : "";
    },
    getLower(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      if (data.length === 1) {
        str = data[0].toLowerCase();
      }
      return str ? str : "";
    },
    getLatLon(address) {
      let that = this;
      return new Promise((resolve, reject) => {
        $.ajax({
          type: "get",
          dataType: "jsonp",
          data: {
            key: "4W2BZ-EPWCB-N5BUH-JRN5A-JGM5T-FSBCD", // 填申请到的腾讯key
            address: address, //具体的地址
            output: "jsonp", //返回格式：支持JSON/JSONP，默认JSON
          },
          jsonp: "callback",
          // jsonpCallback: "QQmap",
          url: "https://apis.map.qq.com/ws/geocoder/v1/?",
          success: function (json) {
            if (json.status == 0) {
              resolve(json.result.location);
            }
          },
          error: function (err) {
            that.$message.error("异常错误，请刷新浏览器后重试");
          },
        });
      });
    },
    getDistance(data) {
      if (data.length > 1) {
        let tencent = {};
        let oldSummerPalace = {};
        if (data[0] && data[1]) {
          this.getLatLon(data[0]).then((location) => {
            tencent = new TMap.LatLng(location.lat, location.lng);
            this.getLatLon(data[1]).then((location) => {
              oldSummerPalace = new TMap.LatLng(location.lat, location.lng);
              let path = [tencent, oldSummerPalace];
              var distance = TMap.geometry.computeDistance(path);

              return distance;
            });
          });
        }
      }
    },

    daysBetween(date1, date2) {
      const oneDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
      const date1Ms = date1.getTime(); // 转换为毫秒
      const date2Ms = date2.getTime(); // 转换为毫秒
      const diffDays = Math.round(Math.abs((date2Ms - date1Ms) / oneDay));
      return diffDays;
    },
    getDays(data) {
      if (data.length > 1) {
        const date1 = new Date(data[0]);
        const date2 = new Date(data[1]);
        let days = this.daysBetween(date1, date2);
        return days;
      }
    },

    getIndex(data) {
      let arr = JSON.parse(JSON.stringify(data[0]));
      arr = arr.split(",");
      let index = data[1];
      let length = arr.length;
      let idx = index >= 0 ? Number(index) - 1 : length + Number(index) - 1;
      return arr[idx];
    },
    getDate(data) {
      if (this.isTimestamp(data[0])) {
        return data[0]
      } else {
        return new Date(data[0]).getTime()
      }

    },
    getDateDelta(data) {

      let timestamp = Number(data[1]) * 1000 * 60 * 60 * 24

      if (this.isTimestamp(data[0])) {
        let dataTimestamp = data[0] + timestamp

        return this.formatTimestamp(dataTimestamp)

      } else {
        let dataTimestamp = ""

        if (data[0] === 'NOW()') {
          dataTimestamp = new Date().getTime() + timestamp

        } else {
          dataTimestamp = new Date(data[0]).getTime() + timestamp
        }

        if (this.isTimestamp(dataTimestamp)) {
          return this.formatTimestamp(dataTimestamp)
        }
      }
    },
    getDay(data) {

      if (!data[0]) {
        return ""
      }
      if (this.isTimestamp(data[0])) {


        return String(new Date(data[0]).getDate())

      } else {
        let dataTimestamp = ""

        if (data[0] === 'NOW()') {
          dataTimestamp = new Date().getTime();

        } else {
          dataTimestamp = new Date(data[0]).getTime()
        }

        if (this.isTimestamp(dataTimestamp)) {
          return String(new Date(dataTimestamp).getDate())
        }
      }
    },
    getHour(data) {

      if (!data[0]) {
        return ""
      }
      if (this.isTimestamp(data[0])) {


        return String(new Date(data[0]).getHours())

      } else {
        let dataTimestamp = ""

        if (data[0] === 'NOW()') {
          dataTimestamp = new Date().getTime();

        } else {
          dataTimestamp = new Date(data[0]).getTime()
        }

        if (this.isTimestamp(dataTimestamp)) {
          return String(new Date(dataTimestamp).getHours())
        }
      }
    },
    getIsoWeekNum(data) {

      if (!data[0]) {
        return ""
      }
      if (data[0] === 'NOW()') {
        return this.getISOWeekNumber(this.formatTimestamp(new Date().getTime()))
      } else {
        return this.getISOWeekNumber(data[0])
      }

    },
    getMinute(data) {

      if (!data[0]) {
        return ""
      }
      if (this.isTimestamp(data[0])) {


        return String(new Date(data[0]).getMinutes())

      } else {
        let dataTimestamp = ""

        if (data[0] === 'NOW()') {
          dataTimestamp = new Date().getTime();

        } else {
          dataTimestamp = new Date(data[0]).getTime()
        }

        if (this.isTimestamp(dataTimestamp)) {
          return String(new Date(dataTimestamp).getMinutes())
        }
      }
    },
    getMonth(data) {

      if (!data[0]) {
        return ""
      }
      if (this.isTimestamp(data[0])) {


        return String(new Date(data[0]).getMonth() + 1)

      } else {
        let dataTimestamp = ""

        if (data[0] === 'NOW()') {
          dataTimestamp = new Date().getTime();

        } else {
          dataTimestamp = new Date(data[0]).getTime()
        }

        if (this.isTimestamp(dataTimestamp)) {
          return String(new Date(dataTimestamp).getMonth() + 1)
        }
      }
    },
    getYear(data) {

      if (!data[0]) {
        return ""
      }
      if (this.isTimestamp(data[0])) {


        return String(new Date(data[0]).getFullYear())

      } else {
        let dataTimestamp = ""

        if (data[0] === 'NOW()') {
          dataTimestamp = new Date().getTime();

        } else {
          dataTimestamp = new Date(data[0]).getTime()
        }

        if (this.isTimestamp(dataTimestamp)) {
          return String(new Date(dataTimestamp).getFullYear())
        }
      }
    },
    getWeekDay(data) {

      if (!data[0]) {
        return ""
      }
      const d = new Date(data[0]);
      return d.getDay().toString();

    },
    getDateFormat(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      if (data.length === 2 && data[0]) {

        let arr = []
        data[1].split("-").forEach(item => {
          if (item === 'y') {
            arr.push(String(new Date(data[0]).getFullYear()))
          } else if (item === 'm') {
            arr.push(String(new Date(data[0]).getMonth() + 1))
          } else if (item === 'd') {
            arr.push(String(new Date(data[0]).getDate()))
          }
        })
        str = arr.join("-")
      }
      return str ? str : "";
    },
    getToDay(data) {

      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      if (data.length === 0) {
        str = this.formatTimestamp(new Date().getTime());

      }

      return str
    },
    getText(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      if (data.length === 1) {
        str = data[0].toString()
      } else if (data.length === 2) {
        str = moment(data[0], 'YYYY-MM-DD HH:mm:ss').format(data[1]).toString()

      }

      return str
    },
    getValue(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });
      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      if (data.length === 1) {
        try {
          str = Number(data[0])
        } catch {
          str = ""
        }
      }

      return str
    },
    getSumpRoduct(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      if (data.length > 0) {
        let arr = []
        data.forEach(item => {
          if (item && item.includes(",")) {
            arr.push(item.slice(0, item.length - 1).split(","))
          } else {
            arr.push(item)
          }

        })

        str = this.sumProduct(...arr)

      }

      return str
    },
    getLength(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });
      if (data.length > 0) {
        let arr = []
        data.forEach(item => {
          if (item && item.includes(",")) {
            arr.push(item.slice(0, item.length - 1).split(","))
          } else {
            arr.push(item)
          }

        })

        str = arr[0].length

      }


      return str
    },
    getIsempty(data) {
      let str = null;
      let typeList = [];
      funcList.forEach((item) => {
        typeList.push(item.type);
      });

      data.forEach((item, index) => {
        if (Array.isArray(item)) {
          let type = item[item.length - 1];
          if (typeList.indexOf(type) !== -1) {
            item.splice(item.length - 1, 1);
            this.$set(data, index, this.getFormulaResult(type, item));
          }
        }
      });

      if (!data[0] || data[0].length === 0) {
        str = "1"

      } else {
        str = "0"
      }

      return str
    },
    getNow(data) {

      return moment().format('YYYY-MM-DD HH:mm:ss');

    },
    sumProduct(...arrays) {

      const length = arrays.reduce((maxArr, currentArr) => {
        return currentArr.length > maxArr.length ? currentArr : maxArr;
      }, []).length
      let result = 0;
      let isBool = false
      for (let i = 0; i < length; i++) {
        let product = 1;

        for (let j = 0; j < arrays.length; j++) {

          if (Array.isArray(arrays[j])) {
            isBool = true
            product *= arrays[j][i] ? Number(arrays[j][i]) : 0;
          } else {
            product *= arrays[j] ? Number(arrays[j]) : 1;
          }

        }

        result += product;
      }

      return isBool ? result : ""
    },
    getISOWeekNumber(date) {
      const targetDate = new Date(date);
      targetDate.setHours(0, 0, 0, 0);

      // 设置日期为该年的第一个周四
      targetDate.setDate(targetDate.getDate() + (4 - (targetDate.getDay() || 7)));

      // 获取该年1月1日的星期一
      const firstThursday = targetDate;

      // 计算该日期是该年ISO第几周
      const startOfYear = new Date(firstThursday.getFullYear(), 0, 1);
      const diff = targetDate - startOfYear;

      // 计算 ISO 周数 (1 周为 7 天)
      const oneWeek = 1000 * 60 * 60 * 24 * 7;
      return Math.ceil(diff / oneWeek);
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;

    },
    isTimestamp(value) {
      // 判断是否是一个数字且是一个有效的正整数
      return !isNaN(value) && Number.isInteger(Number(value)) && value >= 0;
    },
    setForMulaData(data) {

      for (let i in this.formula) {
        let str = "";
        let arr = [];

        if (Array.isArray(this.formula[i].formula)) {
          this.formula[i].formula.forEach((item) => {

            if (this.getFiedlsMula(data, item, str, this.formula[i])) {

              arr.push(this.getFiedlsMula(data, item, str, this.formula[i]));

            }
          });
        }

        var keysArray = Object.keys(data);

        if (keysArray.indexOf(this.formula[i].vModel) === -1) {

          let formulaData = this.getArrData(arr);

          let data = this.getTableArrData(formulaData);

          let result = this.getTableFiledsChangeData(
            data,
            this.formula[i].type
          );

          let query = {
            vModel: this.formula[i].vModel,
            str: result,
            type: this.formula[i].type,
          };
          this.$bus.$emit("setTableFormula", query);
        } else {

          let formulaData = this.getArrData(arr);
          this.getFormulaResult(this.formula[i].type, formulaData);

          this.formData.fields.forEach((item) => {
            if (item.__vModel__ === this.formula[i].vModel) {
              if (this.formulaName !== item.__vModel__) {
                if (formulaData || this.formula[i].type === 'TODAY') {
                  let result = this.getFormulaResult(
                    this.formula[i].type,
                    formulaData
                  );

                  if ((result && result !== Infinity && result !== -Infinity && result !== NaN && result !== 'NaN') || result === "") {
                    this.$set(item.__config__, "defaultValue", result);

                    this.postData[this.formula[i].vModel].value = result;
                    this.rulesData[this.formula[i].vModel] = result;
                    if (
                      this.$refs[this.formula[i].vModel] &&
                      this.$refs[this.formula[i].vModel][0]
                    ) {
                      this.$refs[this.formula[i].vModel][0].clearValidate();
                    }
                  }
                }
              } else {

              }
            }
          });
        }
      }
    },
    getFormulaResult(type, data) {
      let str = "";

      if (type === "CONCATENATE") {
        str = this.getConcatEnate(data);
      } else if (type === "SUM") {
        str = this.getSum(data);
      } else if (type === "RIDE") {
        str = this.getRide(data);
      } else if (type === "DISCUSS") {
        str = this.getDiscuss(data);
      } else if (type === "MINUS") {
        str = this.getMinus(data);
      } else if (type === "AVERAGE") {
        str = this.getAverage(data);
      } else if (type === "MAX") {
        str = this.getMax(data);
      } else if (type === "MIN") {
        str = this.getMin(data);
      } else if (type === "DATEDIF") {
        str = this.getDateDif(data);
      } else if (type === "RMBCAP") {
        str = this.getRmbCap(data);
      } else if (type === "IF") {
        str = this.getIf(data);
      } else if (type === "OR") {
        str = this.getOr(data);
      } else if (type === "AND") {
        str = this.getAnd(data);
      } else if (type === "ABS") {
        str = this.getAbs(data);
      } else if (type === "RADIANS") {
        str = this.getRadians(data);
      } else if (type === "COS") {
        str = this.getCos(data);
      } else if (type === "COT") {
        str = this.getCot(data);
      } else if (type === "SIN") {
        str = this.getSin(data);
      } else if (type === "ROUND") {
        str = this.getRound(data);
      } else if (type === "COUNT") {
        str = this.getCountr(data);
      } else if (type === "COUNTIF" || type === "-COUNTIF") {
        str = this.getCountif(data, type);
      } else if (type === "FIXED") {
        str = this.getFixed(data);
      } else if (type === "INT") {
        str = this.getInt(data);
      } else if (type === "LARGE") {
        str = this.getLarge(data);
      } else if (type === "JOIN") {
        str = this.getJoin(data);
      } else if (type === "LEFT") {
        str = this.getLeft(data);
      } else if (type === "LEN") {
        str = this.getLen(data);
      } else if (type === "LOWER") {
        str = this.getLower(data);
      } else if (type === "TEXTLOCATION") {
        str = this.getTextlocation(data);
      } else if (type === "IFS") {
        str = this.getIfs(data);
      } else if (type === "DISTANCE") {
        if (data.length > 1 && data[0] && data[1]) {
          str = this.getDistance(data);

        }
      } else if (type === "DAYS") {
        str = this.getDays(data);
      } else if (type === "INDEX") {
        str = this.getIndex(data);
      } else if (type === "DATE") {
        str = this.getDate(data);
      } else if (type === "DATEDELTA") {
        str = this.getDateDelta(data);
      } else if (type === "DAY") {
        str = this.getDay(data);
      } else if (type === "HOUR") {
        str = this.getHour(data);
      } else if (type === "ISOWEEKNUM") {
        str = this.getIsoWeekNum(data);
      } else if (type === "MINUTE") {
        str = this.getMinute(data);
      } else if (type === "MONTH") {
        str = this.getMonth(data);
      } else if (type === "NOW") {
        str = this.getNow(data);
      } else if (type === "YEAR") {
        str = this.getYear(data);
      } else if (type === "WEEKDAY") {
        str = this.getWeekDay(data);
      } else if (type === "DATEFORMAT") {
        str = this.getDateFormat(data);
      } else if (type === "TODAY") {
        str = this.getToDay(data);
      } else if (type === "TEXT") {
        str = this.getText(data);
      } else if (type === "VALUE") {
        str = this.getValue(data);
      } else if (type === "SUMPRODUCT") {
        str = this.getSumpRoduct(data);
      } else if (type === "ISEMPTY") {
        str = this.getIsempty(data);
      } else if (type === "LENGTH") {
        str = this.getLength(data);
      }
      return str;
    },
    getFiedsData(data, type, el, node, vModel) {
      let str = "";
      let tableStr = "";
      let tableList = [];
      let arr = []
      var keysArray = Object.keys(data);

      if (keysArray.indexOf(vModel) !== -1) {

        keysArray.forEach((itemA) => {
          if (el === itemA) {

            if (data[itemA].value || data[itemA].value === 0 || data[itemA].value === '0') {
              if (
                type === "JOIN" ||
                type === "LARGE" ||
                type === "COUNTIF" || type === "-COUNTIF" ||
                type === "INDEX" || type === 'SUMPRODUCT' || type === 'LENGTH'
              ) {

                if (data[itemA].tag === 'menberSingle' || data[itemA].tag === 'menberCheck') {
                  str = str + this.getMenberDict(data[itemA].value) + ",";
                } else if (data[itemA].tag === 'departSingle' || data[itemA].tag === "departCheck") {
                  str = str + this.getDepartDict(data[itemA].value) + ",";
                } else {

                  if (this.getInputNumberFields(vModel) === "percentage" && this.getInputNumberFields(itemA) !== 'percentage') {
                    str = str + math.multiply(data[itemA].value, 100) + ","

                  } else if (this.getInputNumberFields(itemA) === 'percentage' && this.getInputNumberFields(vModel) !== 'percentage') {
                    str = str + math.divide(data[itemA].value, 100) + ","

                  } else {
                    str = str + data[itemA].value + ",";
                  }

                }

              } else {

                if (data[itemA].tag === 'menberSingle' || data[itemA].tag === 'menberCheck') {
                  str = str + this.getMenberDict(data[itemA].value)
                } else if (data[itemA].tag === 'departSingle' || data[itemA].tag === "departCheck") {
                  console.log(data[itemA].value, "111111111")
                  str = str + this.getDepartDict(data[itemA].value)
                  console.log(str, "str")
                } else {

                  if (this.getInputNumberFields(vModel) === "percentage" && this.getInputNumberFields(itemA) !== 'percentage') {
                    str = str + math.multiply(data[itemA].value, 100)

                  } else if (this.getInputNumberFields(itemA) === 'percentage' && this.getInputNumberFields(vModel) !== 'percentage') {
                    str = str + math.divide(data[itemA].value, 100)

                  } else {
                    str = str + data[itemA].value
                  }
                }
              }

            }
          }

          if (itemA.indexOf("vwrow") !== -1) {
            console.log(2, "22222222")
            let rowArr = data[itemA].value;

            if (Array.isArray(rowArr) && rowArr.length > 0) {
              rowArr.forEach((itemRow) => {
                if (itemRow[el]) {
                  if (el === itemRow[el].keys) {

                    if (itemRow[el].value || itemRow[el].value === 0 || itemRow[el].value === '0') {
                      if (
                        type === "JOIN" ||
                        type === "LARGE" ||
                        type === "COUNTIF" || type === "-COUNTIF" ||
                        type === "INDEX" || type === 'SUMPRODUCT' || type === 'LENGTH'
                      ) {

                        if (itemRow[el].tag === 'menberSingle' || itemRow[el].tag === 'menberCheck') {
                          str = str + this.getMenberDict(itemRow[el].value) + ","
                        } else if (itemRow[el].tag === 'departSingle' || itemRow[el].tag === "departCheck") {
                          str = str + this.getDepartDict(itemRow[el].value) + ","
                        } else {
                          if (this.getInputNumberFields(vModel) === "percentage" && this.getInputNumberFields(el) !== 'percentage') {
                            str = str + math.multiply(itemRow[el].value, 100) + ","

                          } else if (this.getInputNumberFields(el) === 'percentage' && this.getInputNumberFields(vModel) !== 'percentage') {
                            str = str + math.divide(itemRow[el].value, 100) + ","

                          } else {
                            str = str + itemRow[el].value + ","
                          }

                        }

                      } else {

                        if (itemRow[el].tag === 'menberSingle' || itemRow[el].tag === 'menberCheck') {
                          str = str + this.getMenberDict(itemRow[el].value)
                        } else if (itemRow[el].tag === 'departSingle' || itemRow[el].tag === "departCheck") {
                          str = str + this.getDepartDict(itemRow[el].value)
                        } else {
                          if (this.getInputNumberFields(vModel) === "percentage" && this.getInputNumberFields(el) !== 'percentage') {
                            str = str + math.multiply(itemRow[el].value, 100)

                          } else if (this.getInputNumberFields(el) === 'percentage' && this.getInputNumberFields(vModel) !== 'percentage') {
                            str = str + math.divide(itemRow[el].value, 100)

                          } else {
                            str = str + itemRow[el].value
                          }

                        }
                      }

                    }
                  }
                }
              });
            }
          }
        });

        return str;
      } else {

        keysArray.forEach((itemA) => {
          if (el === itemA) {

            if (data[itemA].value || data[itemA].value === 0 || data[itemA].value === '0') {
              if (this.getInputNumberFields(vModel) === "percentage" && this.getInputNumberFields(el) !== 'percentage') {
                tableStr = tableStr + math.multiply(data[itemA].value, 100)

              } else if (this.getInputNumberFields(el) === 'percentage' && this.getInputNumberFields(vModel) !== 'percentage') {
                tableStr = tableStr + math.divide(data[itemA].value, 100)
              } else {
                tableStr = tableStr + data[itemA].value;
              }
            }
          }
          if (itemA.indexOf("vwrow") !== -1) {
            let rowArr = data[itemA].value;
            if (Array.isArray(rowArr) && rowArr.length > 0 && rowArr[0][vModel]) {
              rowArr.forEach((itemRow, itemIndex) => {
                if (itemRow.key !== vModel) {
                  let rowStr = "";
                  for (let j in itemRow) {
                    if (vModel !== j) {
                      if (itemRow[j].value && el === j) {

                        if (itemRow[j].tag === 'menberSingle' || itemRow[j].tag === 'menberCheck') {
                          rowStr = rowStr + this.getMenberDict(itemRow[j].value)
                        } else if (itemRow[j].tag === 'departSingle' || itemRow[j].tag === "departCheck") {
                          rowStr = rowStr + this.getDepartDict(itemRow[j].value)
                        } else {
                          if (this.getInputNumberFields(vModel) === "percentage" && this.getInputNumberFields(j) !== 'percentage') {
                            rowStr = rowStr + math.multiply(itemRow[j].value, 100)

                          } else if (this.getInputNumberFields(j) === 'percentage' && this.getInputNumberFields(vModel) !== 'percentage') {

                            rowStr = rowStr + math.divide(itemRow[j].value, 100)
                          } else {
                            rowStr = rowStr + itemRow[j].value
                          }

                        }

                        // rowStr = rowStr + itemRow[j].value;
                      }
                    }
                  }
                  tableList.push(rowStr);
                }
                // let data = {
                //   vModel: vModel,
                //   text: rowStr,
                //   index: itemIndex,
                // };

                // this.$bus.$emit("setTableFormula", data);
              });
            }
          }
        });

        if (keysArray.indexOf(el) === -1) {

          if (el !== undefined && el[0] !== "v" && el[1] !== "w") {

            if (this.getInputNumberFields(vModel) === "percentage") {
              return math.multiply(el, 100)
            } else {
              return el
            }
          } else {
            return tableList;
          }
        } else {
          return tableStr;
        }

        // return tableStr ? tableStr : tableList;
      }
    },

    isNUmber(num) {
      return /^[-+]?(\d+(\.\d*)?|\.\d+)$/.test(num);
    },
    getMenberDict(data) {
      let arr = [];

      data.forEach((item) => {
        this.allMenberList.forEach((itemA) => {
          if (item.id === itemA._id) {
            arr.push(itemA.name);
          }
        });
      });

      return arr.join(",");
    },
    getDepartDict(data) {
      let arr = [];

      data.forEach((item) => {
        this.allDepartList.forEach((itemA) => {
          if (item.id === itemA._id) {
            arr.push(itemA.name);
          }
        });
      });

      return arr.join(",");
    },
    getExpression(expression) {
      const string = expression;
      const operators = [">=", "<=", "!=", ">", "<", "="];
      let lastIndexOfOperator = -1;
      let selectedOperator = "";
      for (let operator of operators) {
        let index = string.lastIndexOf(operator);
        if (index > lastIndexOfOperator) {
          lastIndexOfOperator = index;
          selectedOperator = operator;
        }
      }

      let isOperator = expression[lastIndexOfOperator - 1];
      let isOperator2 = expression[lastIndexOfOperator + 1];
      if (operators.indexOf(isOperator) !== -1) {
        selectedOperator = isOperator + selectedOperator;
        lastIndexOfOperator = lastIndexOfOperator - 1;
      }

      return [
        expression.substring(0, lastIndexOfOperator),
        selectedOperator,
        expression.substring(
          lastIndexOfOperator + selectedOperator.length,
          expression.length
        ),
      ];
    },
    parseCondition(expression) {

      if (expression.indexOf(")") !== -1) {
        // 使用正则表达式匹配并切割字符串
        const regex = /(.+\([^)]+\))(<=|>=|!=|<|>|=)(\w+)/;
        const matches = this.getExpression(expression);

        let beforeOperator = matches[0];
        let afterOperator = matches[2];
        let operator = matches[1];

        return {
          operator,
          beforeOperator,
          afterOperator,
        };
      }

      const operators = ["=", "<", ">"];
      const operators2 = [">=", "<=", "!="];
      let operatorIndex = "";
      operators.forEach((item) => {
        if (expression.includes(item)) {
          operatorIndex = item;
        }
      });
      operators2.forEach((item) => {
        if (expression.includes(item)) {
          operatorIndex = item;
        }
      });

      if (operatorIndex) {
        let operator = operatorIndex;

        let [beforeOperator, afterOperator] = expression
          .split(operator)
          .map((part) => part.trim());

        return {
          operator,
          beforeOperator,
          afterOperator,
        };
      }

      return "";
    },

    compute(x, y) {
      if (!x) {
        x = 0;
      }
      if (!y) {
        y = 0;
      }
      const xx = new Decimal(x);
      const yy = new Decimal(y);
      return xx.plus(yy).toNumber();
    },
    average(arr) {
      if (arr.length === 0) {
        return 0;
      } else {
        let sum = new Decimal(0);
        let count = 0;
        arr.forEach((item) => {
          item = new Decimal(item);

          count = this.compute(count, item);
        });

        return calculate.divide(count, arr.length).toFixed(2);
      }
    },
    smallToBig(money) {
      if (!money || !this.isNUmber(money)) {
        return "";
      }
      // 汉字的数字
      const cnNums = [
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖",
      ];
      // 基本单位
      const cnIntRadice = ["", "拾", "佰", "仟"];
      // 对应整数部分扩展单位
      const cnIntUnits = ["", "万", "亿", "兆"];
      // 对应小数部分单位
      const cnDecUnits = ["角", "分", "", "", "", ""];
      // 整数金额时后面跟的字符
      const cnInteger = "整";
      // 整型完以后的单位
      const cnIntLast = "元";
      // 最大处理的数字
      const maxNum = 9999999999999999.99;
      // 金额整数部分
      let integerNum;
      // 金额小数部分
      let decimalNum;
      // 输出的中文金额字符串
      let chineseStr = "";
      // 分离金额后用的数组，预定义
      let parts;
      if (money === "") {
        return "";
      }
      money = parseFloat(money);
      if (money >= maxNum) {
        // 超出最大处理数字
        return "";
      }
      if (money === 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      // 转换为字符串
      money = money.toString();
      if (money.indexOf(".") === -1) {
        integerNum = money;

        decimalNum = "";
      } else {
        parts = money.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }
      // 获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        let zeroCount = 0;
        const IntLen = integerNum.length;
        for (let i = 0; i < IntLen; i++) {
          const n = integerNum.substr(i, 1);
          const p = IntLen - i - 1;
          const q = p / 4;
          const m = p % 4;
          if (n === "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            // 归零
            zeroCount = 0;
            //alert(cnNums[parseInt(n)])
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m === 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      // 小数部分
      if (decimalNum !== "") {
        const decLen = decimalNum.length;
        for (let i = 0; i < decLen; i++) {
          const n = decimalNum.substr(i, 1);
          if (n !== "0") {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (chineseStr === "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum === "") {
        chineseStr += cnInteger;
      }

      return chineseStr;
    },
    getDateDiffer(date1, date2, type) {
      const MILLI_SECONDS_PER_SECOND = 1000;
      const SECONDS_PER_MINUTE = 60;
      const MINUTES_PER_HOUR = 60;
      const HOURS_PER_DAY = 24;
      const DAYS_PER_MONTH = 30;
      const MONTHS_PER_YEAR = 12;
      const diff = date1 - date2;

      const seconds = (diff / MILLI_SECONDS_PER_SECOND).toFixed(2);
      const minutes = (seconds / SECONDS_PER_MINUTE).toFixed(2);

      const hours = (minutes / MINUTES_PER_HOUR).toFixed(2);
      const days = (hours / HOURS_PER_DAY).toFixed(2);
      const months = (days / DAYS_PER_MONTH).toFixed(2);
      const years = (months / MONTHS_PER_YEAR).toFixed(2);

      if (type === "y") {
        return years || "";
      } else if (type === "M") {
        return months || "";
      } else if (type === "d") {
        return days || "";
      } else if (type === "h") {
        return hours || "";
      } else if (type === "m") {
        return minutes || "";
      } else if (type === "s") {
        return seconds || "";
      } else {
        return "";
      }
    },
    getInputNumberFields(vModel) {

      let data = {}
      this.formData.fields.forEach(item => {
        if (item.__config__.tagIcon === 'row') {
          item.__config__.children.forEach(item2 => {
            if (item2.__vModel__ === vModel) {
              data = item2
            }
          })
        } else {
          if (vModel === item.__vModel__) {
            data = item
          }
        }

      })

      if (data.__config__.label === '本人占比') {

      }
      if (data.__config__.tagIcon === "inputNumber") {
        return data.numberType
      }
      return false

    },
  },
}
