<template>
  <el-upload
    :ref="data.__vModel__"
    :file-list="fileList"
    :action="data.action"
    :multiple="data.multiple"
    :accept="data.accept"
    :before-upload="beforeUploadFun"
    :limit="limit"
    :on-success="successFun"
    :on-remove="removeFun"
    name="file"
    :list-type="data.accept === 'image' ? 'picture' : ''"
    :disabled="data.disabled"
    :on-preview="previewFun"
    :on-exceed="exceedFun"
    :data="{
      app_id: appId,
      form_id: formId,
    }"
  >
    <el-button
      size="small"
      type="primary"
      icon="el-icon-upload"
      :disabled="data.disabled"
      >{{ "点击上传" }}</el-button
    >
    <div slot="tip" class="el-upload__tip">
      只能上传不超过 {{ data.__config__.fileSize || 2
      }}{{ data.__config__.sizeUnit || "M" }} 的{{
        data.accept === "image"
          ? "图片"
          : data.accept === "file"
          ? "文件"
          : "视频"
      }}
    </div>
    <!-- <div slot="tip" class="el-upload__tip">
      只能上传不超过2M的{{ data.accept }}文件
    </div> -->
  </el-upload>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    formId: {
      type: String,
    },
    appId: {
      type: String,
    },
  },
  name: "MyUpload",
  data() {
    return {
      fileList: [],
      attachmen_id: "",
      limit: 99,
    };
  },
  watch: {
    data: {
      handler(newVal) {
        if (!newVal.multiple) {
          this.limit = 1;
        } else {
          this.limit = 99;
        }
      },
      deep: true,
    },
  },

  components: {},
  mounted() {},
  created() {
    setTimeout(() => {
      if (
        this.data.__config__.defaultValue &&
        this.data.__config__.defaultValue.length > 0
      ) {
        console.log(this.data, "哈哈哈");
        this.data.__config__.defaultValue.forEach((item) => {
          this.fileList.push(item);
        });
      }
    }, 500);
    console.log(this.data);
    this.$set(
      this.data,
      "action",
      process.env.NODE_ENV === "development"
        ? "/Api/api/upload/upload"
        : `${window.location.origin}/api/upload/upload`
    );
  },
  methods: {
    beforeUploadFun(file) {
      const allowHook = {
        video: ["mp4", "ogv", "ogg", "webm", "wav", "mp3", "acc", "amr"],
        file: [
          "doc",
          "docx",
          "xlsx",
          "xls",
          "pdf",
          "txt",
          "zip",
          "pptx",
          "json",
          "js",
        ],
        img: ["jpg", "jpeg", "png", "gif"],
      };

      if (this.data.accept === "image") {
        console.log(allowHook.img.indexOf(file.name.split(".")[1]));
        if (
          allowHook.img.indexOf(
            file.name.split(".")[file.name.split(".").length - 1]
          ) === -1
        ) {
          this.$message.error("只能上传图片");
          return false;
        }
      }
      if (this.data.accept === "file") {
        if (
          allowHook.file.indexOf(
            file.name.split(".")[file.name.split(".").length - 1]
          ) === -1
        ) {
          this.$message.error("只能上传文件");
          return false;
        }
      }
      if (this.data.accept === "video") {
        if (
          allowHook.video.indexOf(
            file.name.split(".")[
              file.name.split(".")[file.name.split(".").length - 1]
            ]
          ) === -1
        ) {
          this.$message.error("只能上传视频");
          return false;
        }
      }

      if (!this.data.__config__.fileSize) {
        return;
      }

      let fileSize;
      if (this.data.__config__.sizeUnit == "GB") {
        fileSize = file.size / 1024 / 1024 / 1024;
      } else if (this.data.__config__.sizeUnit == "MB") {
        fileSize = file.size / 1024 / 1024;
      } else if (this.data.__config__.sizeUnit == "KB") {
        fileSize = file.size / 1024;
      }
      let isRightSize = fileSize < this.data.__config__.fileSize * 1;
      if (!isRightSize) {
        this.$message.error(
          "文件大小超过 " +
            this.data.__config__.fileSize +
            this.data.__config__.sizeUnit
        );
        return false;
      }
    },
    exceedFun() {
      this.$message.warning("只能上传一个文件");
    },
    successFun(response, file, fileList) {
      console.log(response, file, fileList);
      if (response.code === -1) {
        // 上传失败，删除文件
        const index = fileList.findIndex((item) => item.uid === file.uid);
        if (index > -1) {
          fileList.splice(index, 1);
        }
        this.$message.error("文件上传失败！");
      } else {
        this.fileList = fileList;

        this.sendValue();
      }
    },
    removeFun(file, fileList) {
      console.log(file, fileList);
      this.fileList = fileList;
      this.sendValue();
    },
    previewFun(file) {
      window.open(
        `${window.location.origin}/api/upload/handleRequest?attachmen_id=` +
          file.response.data.attachment_id
      );
    },
    sendValue() {
      let obj = {
        value: this.fileList,

        name: this.data.__vModel__,
      };

      this.$emit("value", obj);
    },
  },
};
</script>

<style  scoped>
::v-deep .el-upload-list__item-name {
  font-size: 14px;
}
</style>
