import store from "@/store";
import axios from "axios";
import router from "@/router";
import {
  Message,
  MessageBox
} from "element-ui";
let showLoginMessageBox = false;

// 创建axios实例

const service = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? "/Api" : window.location.origin, // api 的 base_urls
  // process.env.NODE_ENV === "development" ? "/Api" : "http://47.101.160.60",

  timeout: 50000, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${store.state.user.token}`;
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  (response) => {
    /**
     * code为非200是抛错
     */

    const res = response.data;

    if (response.status === 200 && response.config.responseType === "blob") {
      // 文件类型特殊处理
      return response;
    } else if (res.code !== 200) {
      // 101	登录已失效 102	没有权限 103	账号已被删除或禁用
      if (res.code === "200") {
        return res;
      }
      if (res.code === "500") {
        Message({
          message: res.msg,
          type: "error",
        });
        return res;
      } else if (res.code === 402) {
        if (
          res.msg &&
          Object.prototype.toString.call(res.msg) === "[object Array]"
        ) {
          res.msg = res.msg.reduce(function (prev, cur, index, array) {
            return prev + "\r\n" + cur;
          });
        }
        Message({
          showClose: true,
          duration: 0,
          customClass: "el-close-message",
          message: res.msg,
          type: "error",
        });
      } else if (res.code === 302) {
        if (
          window.location.href.split("#")[1].split("?")[0] ==
          "/noPermissionForm"
        ) {} else if (window.location.href.includes("todolist") && window.location.href.includes("company_id")) {

        } else {
          if (!showLoginMessageBox) {
            if (router.currentRoute.path != "/commission") {
              Message({
                message: "请登录账号",
                type: "error",
              });
              router.push("/login").catch((err) => {});
              store.dispatch("user/logoutAction");
            }

            showLoginMessageBox = true;
            setTimeout(() => {
              showLoginMessageBox = false;
            }, 3000);
            return res;
          }
        }
      } else if (res.code === -1) {
        Message({
          message: res.msg,
          type: "error",
        });
        return res;
      } else {
        if (res.msg) {
          Message({
            message: res.msg,
            type: "error",
          });
        }
      }
      return Promise.reject(res);
    } else {
      return res;
    }
  },
  (error) => {
    Message({
      message: "网络请求失败，请稍候再试",
      type: "error",
    });
    return Promise.reject(error);
  }
);

export default service;
