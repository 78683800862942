
<template>
  <div class="dataLoad-warp" @click="handleDataLoad">
    <div>
      {{ data.__config__.label }}
    </div>
    <el-drawer
      title="数据加载"
      :append-to-body="true"
      :before-close="handleClose"
      :visible.sync="dataloaddrawer"
      direction="btt"
      :modal-append-to-body="false"
    >
      <el-table
        :data="formattedTableData"
        ref="singleSelectTable"
        style="width: 100%"
        height="600"
        header-fixed
      >
        <el-table-column label="选择" width="55">
          <template slot-scope="scope">
            <el-radio
              v-model="selectedRows"
              :label="scope.$index"
              @change="handleRadioChange(scope.row)"
              >{{ null }}</el-radio
            >
          </template>
        </el-table-column>
        <el-table-column
          v-for="column in dataColumns"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
        >
          <template slot-scope="scope">
            <div v-if="Array.isArray(scope.row[column.prop])">
              <div v-for="item in scope.row[column.prop]" :key="item.menberId">
                <div v-if="item.type == 'img'">
                  <img :src="item.imgsrc" alt="" />
                </div>
                <div v-else>
                  {{ item.name || item }}
                </div>
              </div>
            </div>
            <div v-else>
              {{ scope.row[column.prop] }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pagination.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getDataLoadForm } from "@/api/app";
import { mapGetters } from "vuex";
import { log } from "mathjs";

export default {
  props: {},
  data() {
    return {
      dataloaddrawer: false,
      selectedRows: null,
      dataColumns: [],
      dataTableList: [],
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
      },
      formattedTableData: [], // 转换后的表格数据
    };
  },
  props: ["data", "formDataFields", "formId"],
  components: {},
  created() {},
  methods: {
    getFormList(pagination) {
      let data = {
        field: this.data.__vModel__,
        form_id: this.formId,
        page: pagination.page,
        limit: pagination.limit,
      };
      getDataLoadForm(data).then((res) => {
        if (res.code == 200) {
          this.pagination.total = res.total;
          let list = res.data.data;
          console.log(list);
          let propArray = [];
          let dataArray = [];
          this.data.loadDataList.showFieldsList.forEach((item) => {
            list.forEach((item2) => {
              if (item.__vModel__ in item2) {
                propArray.push({
                  prop: item.__vModel__,
                  label: item.__config__.label,
                });
                dataArray.push({ tag: item.__config__.tag, ...item2 });
              }
            });
          });
          let dataList = this.uniqueByIdAndPreferDatePicker(dataArray);
          this.data.loadDataList.showFieldsList.forEach((item) => {
            if (item.__config__.tag === "menberSingle") {
              dataList.forEach((item1) => {
                let arr = item1[item.__vModel__];
                this.allMenberList.forEach((item2) => {
                  if (arr instanceof Array && arr[0] == item2._id) {
                    arr[0] = { name: item2.name, menberId: item2._id };
                  }
                });
                console.log(arr);
              });
            } else if (item.__config__.tag === "menberCheck") {
              dataList.forEach((item1) => {
                let arr = item1[item.__vModel__];
                this.allMenberList.forEach((item2) => {
                  if (arr instanceof Array) {
                    arr.forEach((item3, index) => {
                      if (item3 == item2._id) {
                        arr[index] = { name: item2.name, menberId: item2._id };
                      }
                    });
                  }
                });
              });
            } else if (item.__config__.tag === "departSingle") {
              dataList.forEach((item1) => {
                let arr = item1[item.__vModel__];
                this.allDepartList.forEach((item2) => {
                  if (arr instanceof Array && arr[0] == item2._id) {
                    arr[0] = { name: item2.name, menberId: item2._id };
                  }
                });
              });
            } else if (item.__config__.tag === "departCheck") {
              dataList.forEach((item1) => {
                let arr = item1[item.__vModel__];
                this.allDepartList.forEach((item2) => {
                  if (arr instanceof Array) {
                    arr.forEach((item3, index) => {
                      if (item3 == item2._id) {
                        arr[index] = { name: item2.name, menberId: item2._id };
                      }
                    });
                  }
                });
              });
            } else if (item.__config__.tag === "img-upload") {
              dataList.forEach((item1) => {
                let arr = item1[item.__vModel__];

                if (arr.length > 0) {
                  arr.forEach((item2, index) => {
                    item2["type"] = "img";
                    item2[
                      "imgsrc"
                    ] = `${window.location.origin}/api/upload/handleRequest?attachmen_id=${item2.response.data.attachment_id}`;
                  });
                }
              });
            } else if (item.__config__.tag === "user_id") {
              dataList.forEach((item1) => {
                this.allMenberList.forEach((item2) => {
                  if (item1[item.__vModel__] == item2._id) {
                    item1[item.__vModel__] = item2.name;
                  }
                });
              });
            }
          });
          this.dataTableList = dataList;
          //prop字段去重
          let headCol = res.data.head;
          this.dataColumns = [];
          headCol.forEach((item) => {
            this.dataColumns.push({
              label: item.__config__.label,
              prop: item.__vModel__,
            });
          });
          this.data.loadDataList.showFieldsList.forEach((item) => {
            if (item.__vModel__ == "user_id" || item.__vModel__ == "add_time") {
              this.dataColumns.push({
                label: item.__config__.label,
                prop: item.__vModel__,
              });
            }
          });

          console.log(this.dataColumns);
          // 转换列数据
          this.formattedTableData = this.dataTableList.map((row) => {
            let formattedRow = {};
            this.dataColumns.forEach((col) => {
              formattedRow[col.prop] = row[col.prop] || [];
              this.data.loadDataList.choseList.forEach((item) => {
                if (item.sourceValue in row) {
                  formattedRow[item.sourceValue] = row[item.sourceValue] || [];
                }
              });
            });
            return formattedRow;
          });
        }
      });
    },
    //数组去重针对成员部门单选多选进行优先保留
    uniqueByIdAndPreferDatePicker(arr) {
      const seenIds = new Set();
      const result = [];

      for (const item of arr) {
        if (!seenIds.has(item._id)) {
          // 如果当前_id还未被见过，直接添加到结果中
          result.push(item);
          seenIds.add(item._id);
        } else {
          // 如果当前_id已经被见过，检查是否需要替换
          const existingItem = result.find((x) => x._id === item._id);
          if (
            existingItem &&
            (item.tag === "menberCheck" ||
              item.tag === "menberSingle" ||
              item.tag === "user_id" ||
              item.tag === "departCheck" ||
              item.tag === "departSingle") &&
            (existingItem.tag !== "menberCheck" ||
              existingItem.tag !== "menberSingle" ||
              existingItem.tag !== "departCheck" ||
              existingItem.tag === "user_id" ||
              existingItem.tag !== "departSingle")
          ) {
            // 如果新项的tag是'datePicker'，而现有项的tag不是，则替换现有项
            const index = result.indexOf(existingItem);
            result[index] = item;
          }
          // 如果新项的tag不是'datePicker'，或者现有项的tag已经是'datePicker'，则不做任何操作
        }
      }
      return result;
    },

    handleDataLoad() {
      if (
        this.data.loadDataList.showFieldsList.length == 0 ||
        this.data.loadDataList.choseList.length == 0 ||
        this.data.loadDataList.formName == ""
      ) {
        this.$message({
          message: "请联系应用管理员，设置数据加载的显示字段",
          type: "warning",
        });
      } else {
        this.dataloaddrawer = true;
        this.getFormList(this.pagination);
      }
    },
    handleClose() {
      this.dataloaddrawer = false;
    },

    handleRadioChange(row) {
      //   `${window.location.origin}/api/upload/handleRequest?attachmen_id=` +
      //   file.response.data.attachment_id
console.log(row,'row');
      let handleArray = [];
      for (let key in row) {
        this.data.loadDataList.choseList.forEach((item) => {
          if (item.sourceValue == key) {
            let rowUse = row[key];
            if (Array.isArray(rowUse)) {
              rowUse.forEach((item1, index) => {
                let newObject = {
                  name: item.__config__.label,
                  __vModel__: item.__vModel__,
                  labelValue: [],
                };
                if (item1.menberId) {
                  newObject.labelValue.push({
                    id: item1.menberId,
                    label: item1.name,
                  });
                  handleArray.push(newObject);
                } else {
                  console.log(item, item1, rowUse, "rowUse");
                  if (
                    item.__config__.tag === "menberSingle" ||
                    item.__config__.tag === "menberCheck"
                  ) {
                    this.allMenberList.forEach((item2) => {
                      if (item2._id == item1) {
                        newObject.labelValue.push({
                          id: item2._id,
                          label: item2.name,
                        });
                      }
                    });
                    handleArray.push(newObject);
                  } else if (
                    item.__config__.tag === "departSingle" ||
                    item.__config__.tag === "departCheck"
                  ) {
                    this.allDepartList.forEach((item2) => {
                      if (item2._id == item1) {
                        newObject.labelValue.push({
                          id: item2._id,
                          label: item2.name,
                        });
                      }
                    });
                    handleArray.push(newObject);
                  } else {
                    newObject.labelValue.push(item1);
                    handleArray.push(newObject);
                  }
                }
              });

              let mergedObjects = {};
              handleArray.forEach((item) => {
                console.log(rowUse, item);
                let vModel = item.__vModel__;
                if (!mergedObjects[vModel]) {
                  mergedObjects[vModel] = {
                    ...item,
                    labelValue: [...item.labelValue],
                  };
                } else {
                  mergedObjects[vModel].labelValue = [
                    ...mergedObjects[vModel].labelValue,
                    ...item.labelValue,
                  ];
                }
              });
              handleArray = Object.values(mergedObjects);
            } else {
              handleArray.push({
                name: item.__config__.label,
                labelValue: rowUse,
                __vModel__: item.__vModel__,
                type: 2,
              });
            }
          }
        });
      }
      console.log(handleArray);
      this.$emit("databack", handleArray);
      this.dataloaddrawer = false;
    },
    handleSizeChange(val) {
      this.pagination.limit = val;
      this.getFormList(this.pagination);
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getFormList(this.pagination);
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(["allMenberList", "allDepartList"]),
  },
};
</script>

<style lang="scss" scoped>
.dataLoad-warp {
  width: 350px;
  max-width: 100%;
  background: #fff;
  color: #999;
  height: 34px;
  line-height: 32px;
  border-radius: 4px !important;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s;
  border: 1px dashed #e0e0e0;
}
::v-deep .el-drawer {
  height: 80% !important;
}
::v-deep .el-drawer__header {
  text-align: center;
  color: #000;
}

.el-radio__original {
  display: none !important; /* 隐藏原生 radio 输入,但仍然允许交互 */
}

.el-pagination {
  text-align: center;
  margin-top: 20px;
  .el-icon-circle-close {
    display: none;
  }
}
</style>
