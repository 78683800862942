import { render, staticRenderFns } from "./perspective.vue?vue&type=template&id=038f7a30&scoped=true"
import script from "./perspective.vue?vue&type=script&lang=js"
export * from "./perspective.vue?vue&type=script&lang=js"
import style0 from "./perspective.vue?vue&type=style&index=0&id=038f7a30&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "038f7a30",
  null
  
)

export default component.exports