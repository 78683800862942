<template>
  <div class="content">
    <el-input
      v-if="
        data.__config__.tagIcon == 'input' ||
        data.__config__.tagIcon == 'password'
      "
      v-model="data.__config__.defaultValue"
      :placeholder="data.placeholder"
      :maxlength="data.maxlength"
      :show-word-limit="data['show-word-limit']"
      :readonly="data.readonly"
      :disabled="data.disabled"
      :clearable="data.clearable"
      :prefix-icon="data['prefix-icon']"
      :suffix-icon="data['suffix-icon']"
      :style="data.style"
      :show-password="data['show-password']"
      @change="sendValue"
      @blur="blurFun"
    >
      <template slot="prepend" v-if="data.__slot__.prepend">{{
        data.__slot__.prepend
      }}</template>
      <template slot="append" v-if="data.__slot__.append">{{
        data.__slot__.append
      }}</template>
    </el-input>

    <el-input
      v-else-if="data.__config__.tagIcon == 'textarea'"
      :type="data.__config__.tagIcon"
      v-model="data.__config__.defaultValue"
      :placeholder="data.placeholder"
      :show-word-limit="data['show-word-limit']"
      :readonly="data.readonly"
      :disabled="data.disabled"
      :style="data.style"
      :autosize="data.autosize"
      @change="sendValue"
      @blur="blurFun"
    >
    </el-input>

    <el-button
      @click="FrontEndEvent_1(data)"
      v-show="data.__config__.extendData.trigger === 2"
      type="primary"
      >{{ data.__config__.extendData.btnName }}</el-button
    >
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  name: "MyInput",
  data() {
    return {};
  },
  watch: {
    data: {
      handler(val) {
        console.log("limian", val);
      },
      deep: true,
    },
  },
  components: {},
  mounted() {
    console.log(this.data.autosize, "autosize");
    this.sendValue();
  },
  created() {
    if (this.data.valueType === "dataActive") {
      this.$bus.$emit("dataLink", this.data);
    }
    if (this.data.valueType === "formula") {
      this.$bus.$emit("formula", this.data);
    }
  },
  methods: {
    sendValue() {
      let obj = {
        name: this.data.__vModel__,

        value: this.data.__config__.defaultValue,
      };
      this.$emit("value", obj);
    },
    // 这个是扩展设置的触发方法 如果trigger是1就触发失去焦点事件
    blurFun() {
      if (
        this.data.__config__.extendData.trigger == 1 &&
        this.data.__config__.defaultValue &&
        this.data.__config__.extendData.Frontendeventdata == true
      ) {
        let obj = {
          __vModel__: this.data.__vModel__,
          value: this.data.__config__.defaultValue,
          state: this.data.__config__.extendData.state,
          Frontendeventdata: this.data.__config__.extendData.Frontendeventdata,
        };
        this.$emit("FrontEndEvent_1", obj);
      } else {
        console.log("不用触发");
      }
    },
    FrontEndEvent_1() {
      let obj = {
        __vModel__: this.data.__vModel__,
        value: this.data.__config__.defaultValue,
        state: this.data.__config__.extendData.state,
        Frontendeventdata: this.data.__config__.extendData.Frontendeventdata,
      };
      this.$emit("FrontEndEvent_1", obj);
    },
  },
};
</script>

<style  scoped lang="scss">
.content {
  display: flex;

  ::v-deep .el-button {
    margin-left: 20px;
  }
}
</style>
