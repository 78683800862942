<!--  -->
<template>
  <div>
    <el-cascader
      style="width: 100%"
      :options="options"
      clearable
      v-model="address"
      @change="sendValue"
      ref="cascader"
      :disabled="data.disabled"
    >
    </el-cascader>
    <el-input
      style="margin-top: 10px"
      type="textarea"
      :rows="3"
      placeholder="请输入详细地址"
      v-model="detailAddr"
      @blur="inputBlur"
    >
    </el-input>
  </div>
</template>

<script>
import { cityCode } from "@/utils/address";
export default {
  data() {
    return {
      options: cityCode,
      address: [],
      detailAddr: "",
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    data: {
      handler(val) {
        //this.sendValue(val);
      },
      deep: true,
    },
  },
  methods: {
    resetCasadeSelector() {
      if (this.$refs.cascader) {
        this.address = [];
        this.$refs.cascader.$refs.panel.activePath = [];
        this.$refs.cascader.$refs.panel.calculateCheckedNodePaths();
      }
    },
    inputBlur() {
      if (this.address.length === 0 && !this.detailAddr) {
        let obj = {
          name: this.data.__vModel__,
          value: "",
        };
        this.$emit("value", obj);
        return false;
      }
      if (this.address.length > 0) {
        this.$set(this.data.__config__, "defaultValue", []);
        this.address.forEach((item, index) => {
          this.$set(this.data.__config__.defaultValue, index, item);
        });
        if (this.detailAddr) {
          this.data.__config__.defaultValue.push(this.detailAddr);
        }
      } else {
        this.$set(this.data.__config__, "defaultValue", []);
        if (this.detailAddr) {
          this.data.__config__.defaultValue.push(this.detailAddr);
        }
      }

      let obj = {
        name: this.data.__vModel__,
        value: this.data.__config__.defaultValue,
      };
      this.$emit("value", obj);
    },
    sendValue(val) {
      if (this.address.length === 0 && !this.detailAddr) {
        let obj = {
          name: this.data.__vModel__,
          value: "",
        };
        this.$emit("value", obj);
        return false;
      }
      if (this.address.length > 0) {
        this.$set(this.data.__config__, "defaultValue", []);
        this.address.forEach((item, index) => {
          this.$set(this.data.__config__.defaultValue, index, item);
        });
        if (this.detailAddr) {
          this.data.__config__.defaultValue.push(this.detailAddr);
        }
      } else {
        this.$set(this.data.__config__, "defaultValue", []);
        if (this.detailAddr) {
          this.data.__config__.defaultValue.push(this.detailAddr);
        }
      }
      let obj = {
        name: this.data.__vModel__,
        value: this.data.__config__.defaultValue,
      };
      this.$emit("value", obj);
    },
    cityChange(data) {
      data.forEach((item) => {
        item.value = item.label;
        if (item.children) {
          this.cityChange(item.children);
        }
      });
    },
  },
  mounted() {
    this.resetCasadeSelector();
    setTimeout(() => {
      if (this.data.__config__.defaultValue) {
        if (this.data.__config__.defaultValue.length === 1) {
          this.detailAddr = this.data.__config__.defaultValue[0];
        } else if (this.data.__config__.defaultValue.length === 3) {
          this.data.__config__.defaultValue.forEach((item, index) => {
            this.$set(this.address, index, item);
          });
        } else if (this.data.__config__.defaultValue.length > 3) {
          this.data.__config__.defaultValue.forEach((item, index) => {
            if (index < 3) {
              this.$set(this.address, index, item);
            } else {
              this.detailAddr = item;
            }
          });
        }
      }
    }, 500);
    this.$bus.$on("addr", (data) => {
      this.address = [];
      this.detailAddr = "";

      if (this.data.__config__.defaultValue) {
        if (this.data.__config__.defaultValue.length === 1) {
          this.detailAddr = this.data.__config__.defaultValue[0];
        } else if (this.data.__config__.defaultValue.length === 3) {
          this.data.__config__.defaultValue.forEach((item, index) => {
            this.$set(this.address, index, item);
          });
        } else if (this.data.__config__.defaultValue.length > 3) {
          this.data.__config__.defaultValue.forEach((item, index) => {
            if (index < 3) {
              this.$set(this.address, index, item);
            } else {
              this.detailAddr = item;
            }
          });
        }
      }
    });
  },
  created() {
    this.cityChange(cityCode);
    console.log(this.data.valueType, "打印地址");
    if (this.data.valueType === "dataActive") {
      this.$bus.$emit("dataLink", this.data);
    }
  },
};
</script>
<style lang='scss' scoped>
.address {
  width: 100%;
  height: 32px;
  background: #ffffff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  font-size: 14px;
  line-height: 32px;
  text-indent: 10px;
  font-weight: 400;
  color: #c0c4cc;
}
</style>
