<!--  -->
<template>
  <div>
    <div class="depart-check" v-if="dataList.length === 0 && !showTrends">
      {{ placeholder }}
    </div>
    <div class="depart-check" v-else>
      <div v-for="(item, index) in dataList" :key="index" class="depart-list">
        <div>{{ item.label }}</div>
      </div>
      <div class="depart-list" v-if="showTrends">
        <div>当前用户所属部门</div>
      </div>
      <div class="depart-list" v-if="higherDepart.isShow">
        <div>获取当前用户的上级部门</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [],
    };
  },

  mounted() {},
  props: [
    "numberType",
    "isThousandth",
    "FixedNumber",
    "isDisabled",
    "value",
    "placeholder",
    "showTrends",
    "higherDepart",
  ],
  watch: {
    value(val) {
      this.dataList = val;
    },
  },
  methods: {},
  created() {},
};
</script>
<style lang='scss' scoped>
.depart-check {
  width: 100%;

  background: #ffffff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  font-size: 14px;
  line-height: 34px;
  text-indent: 10px;
  font-weight: 400;
  color: #c0c4cc;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 34px;
}
.depart-list {
  div {
    margin: 0 5px;
    color: #409eff;
  }
}
</style>
