<!--  -->
<template>
  <div>
    <div class="title">
      {{ data.__config__.rowTitle }}
    </div>

    <el-table
      :data="tableData"
      :key="tableKey"
      style="margin-bottom: 10px"
      border
      ref="myTable"
      :header-cell-class-name="starAdd"
      :header-cell-style="{ background: '#F8F8F9' }"
    >
      <el-table-column
        type="index"
        width="100"
        align="center"
        label="序号"
        fixed="left"
      >
        <template slot-scope="scope">
          <div class="operate">
            <div class="operate-index">
              {{ scope.$index + 1 }}
            </div>
            <div class="operate-btn" v-if="rule.delete || rule.add">
              <i
                v-if="rule.delete"
                class="el-icon-delete"
                @click="del(scope.row, scope.$index)"
              ></i>
              <el-popover
                placement="bottom"
                width="140"
                trigger="click"
                :ref="`popoverBtn-${scope.$index}`"
              >
                <div
                  class="list-ul"
                  v-for="(item, index) in addTableData"
                  :key="index"
                >
                  <div
                    class="list-li"
                    @click="handleAddBtn(scope.$index, scope, index)"
                  >
                    {{ item }}
                  </div>
                </div>
                <i class="el-icon-more" slot="reference" v-if="rule.add"></i>
              </el-popover>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :prop="item.__vModel__"
        v-for="(item, index) in data.__config__.children"
        width="260"
        :label="item.__config__.label"
        align="center"
        v-if="item.__config__.isDisplay"
      >
        <template
          slot-scope="scope"
          v-if="
            (tableData[scope.$index][item.__vModel__]['isshow'] !== false ||
              tableData[scope.$index][item.__vModel__]['isshow'] ===
                undefined) &&
            tableData[scope.$index][item.__vModel__].isDisplay &&
            item.__config__.tag !== 'relationData' &&
            item.__config__.tag !== 'relationSearch'
          "
        >
          <Module-screen
            :module-list="item"
            @tableValue="handleValue"
            :index="scope.$index"
            :formId="formId"
            :appId="appId"
            :draftData="scope.row[item.__vModel__]"
          ></Module-screen>
        </template>
        <el-table-column
          v-if="item.__config__.tag === 'relationData'"
          v-for="(itemA, indexA) in item.gl_field_filter"
          :prop="itemA.__vModel__"
          width="260"
          :label="itemA.__config__.label"
          align="center"
          :key="indexA"
        >
          <template slot-scope="scope">
            <div>
              <i
                class="el-icon-connection Ricon"
                v-if="indexA === 0"
                style="
                  float: left;
                  margin-left: 10px;
                  margin-top: 8px;
                  font-size: 16px;
                "
                @click="openDataselect(item, itemA, scope.$index, scope.row)"
              ></i>
              {{
                getRelaTabs(scope.row[item.__vModel__].gl_field_filter, itemA)
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.__config__.tag === 'relationSearch'"
          v-for="(itemB, indexB) in item.gl_field_filter"
          :prop="itemB.__vModel__"
          width="260"
          :label="itemB.__config__.label"
          align="center"
          :key="indexB"
        >
          <template slot-scope="scope">
            <div>
              {{
                getRelaTabs(scope.row[item.__vModel__].gl_field_filter, itemB)
              }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <el-button @click="add" type="primary" v-if="rule.add">添加</el-button>

    <el-dialog
      title="选择数据"
      :visible.sync="dataVisible"
      width="60%"
      append-to-body
      :modal-append-to-body="false"
      class="dialogBox"
    >
      <relatedDatatable
        :valueDataId="valueDataId"
        :queryParams="queryParams"
        :dataVisible="dataVisible"
        @handleClose="handleClose"
        @handleSubmit="handleSubmit"
        @getData="getData"
        :formId="gl_formId"
        :appId="data.gl_table_appId"
        v-if="isComponentVisible"
      >
      </relatedDatatable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ModuleScreen from "./../tableComponents/moduleScreen.vue";
import relatedDatatable from "@/components/relatedDatatable/index";
import myRelationSearch from "./../tableComponents/myRelationSearch.vue";
import { getTableRule, getRelatedData } from "@/api/app";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tableKey: 0,
      tableData: [],
      isDrat: false,
      formulaName: "",
      tableKey: 0,
      addTableData: ["复制到下一行", "复制到最后一行"],
      rule: {
        add: true,
        insert: true,
        edit: true,
        delete: true,
      },
      index: null,

      // 关联数据弹窗
      dataVisible: false,
      queryParams: {},
      gl_formId: "",
      gl_table_appId: "",
      valueDataId: "",
      selectList: [],
      itemId: "",
      itemIndex: "",
      isComponentVisible: false,
      primaryKey: "",
      // 关联查询定时器
      timeoutId: "",
      // 关联查询的匹配条件
      searchData: {},
      pageNum: 1,
      pageSize: 10,
      searchValueData: {},
      sign: false,
    };
  },
  name: "Table",
  components: {
    ModuleScreen,
    relatedDatatable,
    myRelationSearch,
  },
  props: {
    data: {
      type: Object,
    },
    formId: {
      type: String,
    },
    appId: {
      type: String,
    },
    tableRuleType: {
      type: String,
    },
    flowRecord: {
      type: Array,
    },
    formDataFields: {
      type: Object,
    },
  },
  watch: {
    valueDataId: {
      handler(val) {
        if (!val) {
          val = "";
        }
      },
    },
    tableData: {
      handler(val) {
        let obj = {
          name: this.data.__config__.componentName,
          value: val,
          isTable: true,
        };
        this.$emit("value", obj);
      },
      deep: true,
    },

    formDataFields: {
      immediate: false,
      handler(val) {
        // 监听数据 如果5秒内数据没有发生改变的话 就执行操作
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }
        // 判断后台配置里面有没有传入条件  然后用后台传入条件的值去匹配前台输入的值
        if (this.data.__config__.children) {
          this.data.__config__.children.forEach((item) => {
            if (item.__config__.tag === "relationSearch") {
              this.searchData = item;
              if (item.relationData.conditionList.length > 0) {
                item.relationData.conditionList.forEach((itemA) => {
                  val.fields.forEach((itemB) => {
                    if (itemA.filterData.zd_data === itemB.__vModel__) {
                      if (itemB.__config__.defaultValue) {
                        itemA.filterData.value = itemB.__config__.defaultValue;
                      }
                    }
                  });
                });
                this.queryParams.filter = item.relationData;
              }

              // 判断后台配置里面有没有传入条件  然后用后台传入条件的值去匹配前台输入的值
              if (this.searchData.relationData.conditionList.length > 0) {
                const allConditionsValid =
                  this.searchData.relationData.conditionList.every((item) => {
                    return this.formDataFields.fields.some((item1) => {
                      return (
                        (item.filterData.zd_data === item1.__vModel__ &&
                          item1.__config__.defaultValue) ||
                        (item.filterData.filterType === "2" &&
                          item.filterData.value)
                      );
                    });
                  });
                if (allConditionsValid) {
                  this.timeoutId = setTimeout(() => {
                    this.searchgetData();
                  }, 3000);
                }
              }
            }
          });
        }
      },

      deep: true,
    },
  },
  created() {
    if (this.tableRuleType === "1") {
      getTableRule(this.formId, "").then((res) => {
        res.data.forEach((item) => {
          if (item.fileds === this.data.__config__.componentName) {
            this.rule = item.rule;
          }
        });
      });
    }

    if (
      this.tableRuleType === "2" &&
      this.flowRecord &&
      this.flowRecord.length > 0
    ) {
      let setup_id = this.flowRecord[this.flowRecord.length - 1].step_id;
      getTableRule(this.formId, setup_id).then((res) => {
        res.data.forEach((item) => {
          if (item.fileds === this.data.__config__.componentName) {
            this.rule = item.rule;
          }
        });
      });
    }
  },
  mounted() {
    this.$bus.$on("setTableLink", (data) => {
      this.tableData.forEach((item) => {
        for (let i in item) {
          if (data.name === item[i].keys) {
            if (item[i].tag === "el-select") {
              this.data.__config__.children.forEach((res) => {
                if (res.__vModel__ === item[i].keys) {
                  res.__slot__.options = [];
                  data.value.forEach((itemId) => {
                    res.__slot__.options.push({
                      value: itemId,
                    });
                  });
                }
              });
            } else {
              this.$set(item[i], "value", data.value[0]);
            }
          }
        }
      });
    });
    this.$bus.$on("setTableColumn", (data) => {
      this.$set(this.tableData[this.index][data.name], "value", data.value[0]);
    });
    setTimeout(() => {
      if (
        this.data.__config__.defaultValue &&
        this.data.__config__.defaultValue.length > 0
      ) {
        this.tableData = JSON.parse(
          JSON.stringify(this.data.__config__.defaultValue)
        );

        this.tableData.forEach((item, index) => {
          if (!this.rule.edit) {
            this.$set(itemA, "disabled", true);
          }
          this.data.__config__.children.forEach((itemA) => {
            if (item[itemA.__vModel__]) {
              item[itemA.__vModel__] = {
                value: item[itemA.__vModel__].value
                  ? item[itemA.__vModel__].value
                  : "",
                required: itemA.__config__.required,
                label: itemA.__config__.label,
                keys: itemA.__vModel__,
                isDisplay: itemA.__config__.isDisplay,
                tag: itemA.__config__.tag,
                disabled: this.rule.edit ? itemA.disabled : true,
                gl_field_filter:
                  itemA.__config__.tag === "relationData" ||
                  itemA.__config__.tag === "relationSearch"
                    ? itemA.gl_field_filter
                    : [],
              };
            } else {
              item[itemA.__vModel__] = {
                value: "",
                required: itemA.__config__.required,
                label: itemA.__config__.label,
                keys: itemA.__vModel__,
                isDisplay: itemA.__config__.isDisplay,
                tag: itemA.__config__.tag,
                disabled: this.rule.edit ? itemA.disabled : true,
                gl_field_filter:
                  itemA.__config__.tag === "relationData" ||
                  itemA.__config__.tag === "relationSearch"
                    ? itemA.gl_field_filter
                    : [],
              };
            }
          });
          let obj = JSON.parse(JSON.stringify(item));
          for (let i in obj) {
            if (obj[i].tag == "relationData") {
              if (obj[i].value.valueData) {
                obj[i].gl_field_filter.forEach((item2, index2) => {
                  for (let j in obj[i].value.valueData) {
                    if (item2.__vModel__ === j) {
                      this.$set(
                        obj[i].gl_field_filter[index2].__config__,
                        "defaultValue",
                        obj[i].value.valueData[j]
                      );
                    }
                  }
                });
              }
            }
          }
          this.$set(this.tableData, index, obj);
        });
      } else {
        let obj = {};

        this.data.__config__.children.forEach((itemA) => {
          if (!this.rule.edit) {
            this.$set(itemA, "disabled", true);
          }
          obj[itemA.__vModel__] = {
            value: "",
            required: itemA.__config__.required,
            label: itemA.__config__.label,
            keys: itemA.__vModel__,
            isDisplay: itemA.__config__.isDisplay,
            tag: itemA.__config__.tag,
            disabled: this.rule.edit ? itemA.disabled : true,
            gl_field_filter:
              itemA.__config__.tag === "relationData" ||
              itemA.__config__.tag === "relationSearch"
                ? itemA.gl_field_filter
                : [],
          };
        });
        this.tableData.push(obj);
        this.$nextTick(() => {
          this.$refs.myTable.doLayout(); // 强制重新计算表格布局
        });
      }
    }, 500);
    this.$bus.$on("tableXygz", (data, xygzParent, index) => {
      data.forEach((item) => {
        let arr = item.value;
        if (xygzParent === this.data.__config__.componentName) {
          this.tableData.forEach((item2, index2) => {
            if (index === index2) {
              for (let i in item2) {
                if (arr.includes(item2[i].keys)) {
                  this.$set(item2[i], "isshow", item.isshow);

                  if (!item.isshow) {
                    if (this.rules[item2.__vModel__]) {
                      this.rules[item2.__vModel__].forEach((item4) => {
                        this.$set(item4, "required", false);
                      });
                    }
                  } else {
                    if (this.rules[item2.__vModel__]) {
                      this.rules[item2.__vModel__].forEach((item4) => {
                        this.$set(item4, "required", item4.required);
                      });
                    }
                  }
                }
                if (arr.includes(item2[i].keys)) {
                  this.$set(item2[i], "required", true);
                } else {
                  this.$set(item2[i], "required", false);
                }
              }
            }
          });
        }
      });
    });
    setTimeout(() => {
      this.$bus.$on("setTableFormula", (data) => {
        this.tableData.forEach((item, index) => {
          for (let i in item) {
            if (item[i].keys === data.vModel) {
              //if (this.formulaName !== data.vModel) {
              if (data.type === "JOIN" || data.type === "TEXTLOCATION") {
                this.$set(item[i], "value", data.str);
              } else {
                if (data.str.length === this.tableData.length) {
                  this.$set(item[i], "value", data.str[index]);
                } else {
                  this.$set(item[i], "value", data.str[0]);
                }
              }
              if (item[i].tag === "datePicker" && data.str[0]) {
                this.tableKey++;
              }

              //}
            }
          }
        });
      });
    }, 1500);
  },
  computed: {
    ...mapGetters(["allMenberList", "allDepartList"]),
  },
  methods: {
    menberCheckDict(data) {
      let arr = [];
      data.forEach((item) => {
        this.allMenberList.forEach((itemA) => {
          if (item === itemA._id) {
            arr.push(itemA.name);
          }
        });
      });

      return arr.join(",");
    },
    departCheckDict(data) {
      let arr = [];
      data.forEach((item) => {
        this.allDepartList.forEach((itemA) => {
          if (item === itemA._id) {
            arr.push(itemA.name);
          }
        });
      });

      return arr.join(",");
    },
    // 关联查询匹配条件
    searchgetData() {
      this.queryParams.pageNum = this.pageNum;
      this.queryParams.pageSize = this.pageSize;
      this.queryParams.fields = this.searchData.gl_field_filter;
      this.queryParams.app_id = this.searchData.gl_table_appId;
      this.queryParams.form_id = this.searchData.gl_table_value;
      this.queryParams.multi = false;
      getRelatedData(this.queryParams).then((res) => {
        if (res.code === 200) {
          this.searchValueData = res.data.data;
          let gl_data = {
            entryId: this.searchData.gl_table_value,
            id: this.searchValueData._id,
            valueData: this.searchValueData,
            multi: this.queryParams.multi,
          };

          let obj = {
            name: "",
            value: gl_data,
            isTable: true,
            index: this.index,
          };
          this.tableData.forEach((item, index) => {
            for (let i in item) {
              if (!this.sign && item[i].keys === this.searchData.__vModel__) {
                item[i].gl_field_filter.forEach((itemA) => {
                  for (let i in this.searchValueData) {
                    if (itemA.__vModel__ === i) {
                      this.$set(
                        itemA.__config__,
                        "defaultValue",
                        this.searchValueData[i]
                      );
                    }
                  }
                });
              } else {
                if (
                  this.index === index &&
                  item[i].keys === this.searchData.__vModel__
                ) {
                  item[i].gl_field_filter.forEach((itemA) => {
                    for (let i in this.searchValueData) {
                      if (itemA.__vModel__ === i) {
                        this.$set(
                          itemA.__config__,
                          "defaultValue",
                          this.searchValueData[i]
                        );
                      }
                    }
                  });
                }
              }
              obj.name = item[i].keys;
              this.$set(item[i], "obj", obj);
            }
          });
          this.$set(this, "tableData", [...this.tableData]);
          this.handleValue(obj);
        }
      });
    },
    openDataselect(item, itemA, index, row) {
      this.isComponentVisible = true;
      //打开弹窗的时候存到是第几条数据的哪个字段
      this.gl_formId = item.gl_table_value;
      this.itemId = item.__vModel__;
      this.itemIndex = index;
      this.primaryKey = item.primaryKey;
      this.queryParams = {
        app_id: item.gl_table_appId,
        form_id: item.gl_table_value,
        fields: item.gl_field_filter,
      };
      if (item.relationData.conditionList.length > 0) {
        item.relationData.conditionList.forEach((item) => {
          this.formDataFields.fields.forEach((item1) => {
            if (item.filterData.zd_data === item1.__vModel__) {
              if (item1.__config__.defaultValue) {
                item.filterData.value = item1.__config__.defaultValue;
              }
            }
          });
        });
        this.queryParams.filter = item.relationData;
      }
      for (let i in row) {
        item.relationData.conditionList.forEach((item) => {
          if (item.filterData.zd_data === i) {
            if (row[i].value) {
              item.filterData.value = row[i].value;
            }
          }
        });
        this.queryParams.filter = item.relationData;
      }

      this.tableData.forEach((item, tableIndex) => {
        for (let i in item) {
          if (tableIndex === this.itemIndex && i === this.itemId) {
            if (item[i].obj && item[i].obj.value.valueData) {
              this.valueDataId = item[i].obj.value.valueData._id;
            }
          }
        }
      });
      this.dataVisible = true;
    },
    /**表格里面的关联查询和关联数据拿到数据之后有些tag组件的数据需要进行处理 */
    getRelaTabs(item, itemId) {
      for (let val of item) {
        if (
          val.__config__.tag === "menberCheck" ||
          val.__config__.tag === "menberSingle"
        ) {
          if (val.__vModel__ === itemId.__vModel__) {
            if (val.__config__.defaultValue.length > 0) {
              return this.menberCheckDict(val.__config__.defaultValue);
            }
          }
        } else if (
          val.__config__.tag === "departCheck" ||
          val.__config__.tag === "departSingle"
        ) {
          if (val.__vModel__ === itemId.__vModel__) {
            if (val.__config__.defaultValue.length > 0) {
              return this.departCheckDict(val.__config__.defaultValue);
            }
          }
        } else if (val.__config__.tag === "el-checkbox-group") {
          if (val.__vModel__ === itemId.__vModel__) {
            return val.__config__.defaultValue.join(",");
          }
        } else if (val.__config__.tag === "el-cascader") {
          if (val.__vModel__ === itemId.__vModel__) {
            return val.__config__.defaultValue.join("/ ");
          }
        } else if (val.__config__.tag === "addr") {
          if (val.__vModel__ === itemId.__vModel__) {
            if (Array.isArray(val.__config__.defaultValue)) {
              return val.__config__.defaultValue.join("/");
            }
          }
        } else {
          if (val.__vModel__ === itemId.__vModel__) {
            if (val.__config__.defaultValue) {
              return val.__config__.defaultValue; // 找到后直接返回
            }
          }
        }
      }
      return "";
    },

    handleSubmit() {
      this.valueData = JSON.parse(JSON.stringify(this.selectList));

      this.dataVisible = false;
      this.isComponentVisible = false;
      this.gl_data = {
        entryId: this.gl_formId,
        id: this.valueData._id,
        primaryKey: this.primaryKey,
        valueData: this.valueData,
      };
      let obj = {
        name: this.itemId,
        value: this.gl_data,
        isTable: true,
        index: this.itemIndex,
      };
      let list = [];
      this.tableData.forEach((item, index) => {
        let data = JSON.parse(JSON.stringify(item));
        for (let i in data) {
          if (index === this.itemIndex && i === this.itemId) {
            data[i].gl_field_filter.forEach((itemA) => {
              for (let j in this.valueData) {
                if (itemA.__vModel__ === j) {
                  this.$set(
                    itemA.__config__,
                    "defaultValue",
                    this.valueData[j]
                  );
                }
              }
            });
          }
        }
        this.$set(this.tableData, index, data);
      });

      //console.log(this.tableData, "qutamde")
      //this.$set(this, "tableData", [...this.tableData]);
      this.handleValue(obj);
    },
    handleClose() {
      this.isComponentVisible = false;
      this.dataVisible = false;
    },
    getData(val) {
      this.selectList = val;
    },
    handleAddBtn(scopeIndex, scope, index) {
      if (index === 0) {
        this.tableData.splice(
          scopeIndex + 1,
          0,
          JSON.parse(JSON.stringify(scope.row))
        );
      } else if (index === 1) {
        this.tableData.push(JSON.parse(JSON.stringify(scope.row)));
      }
      // else {
      //         let obj = {};
      //         this.data.__config__.children.forEach((itemA) => {
      //           obj[itemA.__vModel__] = {
      //             value: itemA.__config__.defaultValue,
      //             required: itemA.__config__.required,
      //             label: itemA.__config__.label,
      //             keys: itemA.__vModel__,
      //             isDisplay: itemA.__config__.isDisplay,
      //           };
      //         });
      //         if (index === 3) {
      //           this.tableData.splice(
      //             scopeIndex + 1,
      //             0,
      //             JSON.parse(JSON.stringify(obj))
      //           );
      //         } else if (index === 2) {
      //           this.tableData.splice(scopeIndex, 0, JSON.parse(JSON.stringify(obj)));
      //         }
      //       }
      this.$refs["popoverBtn-" + scopeIndex].doClose();
    },
    handleValue(val) {
      this.index = val.index;
      this.formulaName = val.name;
      // if (this.tableData[val.index][val.name].gl_field_filter.length > 0) {
      //   this.tableData[val.index][val.name].gl_field_filter.forEach((item, index) => {
      //     if (index === val.index) {
      //       for (let i in val.value) {
      //         if (item.__vModel__ === i) {
      //           this.$set(item.__config__, "defaultValue", val.value[i])
      //         }

      //       }
      //     }
      //   })
      // }
      // this.$set(this, "tableData", [...this.tableData]);
      this.$set(this.tableData[val.index][val.name], "value", val.value);
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.sign = false;
      if (this.data.__config__.children) {
        this.data.__config__.children.forEach((item) => {
          if (
            item.__config__.tag === "relationSearch" ||
            item.__config__.tag === "relationData"
          ) {
            this.searchData = item;
            if (item.relationData.conditionList.length > 0) {
              item.relationData.conditionList.forEach((res) => {
                if (res.filterData.zd_data === val.name) {
                  this.sign = true;
                  if (res.filterData.filterType === "1") {
                    res.filterData.value = this.tableData[val.index][val.name]
                      .value
                      ? this.tableData[val.index][val.name].value
                      : "";
                  }
                }
              });
              this.queryParams.filter = item.relationData;
            }
          }
        });
        if (
          this.sign &&
          this.searchData &&
          this.searchData.__config__ &&
          this.searchData.__config__.tag === "relationSearch"
        ) {
          this.timeoutId = setTimeout(() => {
            this.searchgetData();
          }, 3000);
        }
      }
    },
    starAdd(obj) {
      let filed = obj.column.property;
      let dom = "";
      this.data.__config__.children.forEach((item) => {
        if (filed === item.__vModel__) {
          dom = item;
        }
      });
      if (dom && dom.__config__.required) {
        return "star";
      }
    },
    del(item, index) {
      this.tableData.splice(index, 1);
    },

    add() {
      let obj = {};
      this.data.__config__.children.forEach((itemA) => {
        if (!this.rule.edit) {
          this.$set(itemA, "disabled", true);
        }
        let list = [];
        if (itemA.gl_field_filter) {
          list = JSON.parse(JSON.stringify(itemA.gl_field_filter));
          list.forEach((res) => {
            if (Array.isArray(res.__config__.defaultValue)) {
              this.$set(res.__config__, "defaultValue", []);
            } else {
              this.$set(res.__config__, "defaultValue", "");
            }
          });
        }

        obj[itemA.__vModel__] = {
          value: itemA.__config__.defaultValue,
          required: itemA.__config__.required,
          label: itemA.__config__.label,
          keys: itemA.__vModel__,
          isDisplay: itemA.__config__.isDisplay,
          tag: itemA.__config__.tag,
          disabled: this.rule.edit ? itemA.disabled : true,
          gl_field_filter:
            itemA.__config__.tag === "relationData" ||
            itemA.__config__.tag === "relationSearch"
              ? list
              : [],
        };
      });

      if (
        !this.sign &&
        this.searchData &&
        this.searchData.__config__ &&
        this.searchData.__config__.tag === "relationSearch"
      ) {
        setTimeout(() => {
          this.searchgetData();
        }, 3000);
      }
      this.$set(this.tableData, this.tableData.length, obj);
      this.tableKey++;
    },
  },
};
</script>
<style lang='scss' scoped>
.Ricon:hover {
  color: #409eff;
}

::v-deep table th.star div::after {
  content: "*";
  color: red;
}

::v-deep .el-table__fixed-right {
  // 10 是我这里全局定义的滚动条的高度
  height: 100% !important;
}

::v-deep .el-dialog__body {
  height: 60vh !important;
  // padding: 20px 30px !important;
  box-sizing: border-box !important;
}

::v-deep .el-dialog__header {
  position: relative;
}

::v-deep .el-dialog__headerbtn {
  position: absolute;
  top: 0;
  right: 20px;
}

::v-deep .el-table__fixed-body-wrapper {
  // height: calc(100% - 48px);
}

::v-deep .el-table__body {
  // height: 100%;
}

.title {
  color: #303133;
  font-size: 14px;
  font-weight: bold;
  margin: 10px;
}

.operate {
  cursor: pointer;
}

.operate-index {
  display: block;
}

.operate-btn {
  display: none;
}

.operate:hover {
  .operate-index {
    display: none;
  }

  .operate-btn {
    display: block;
  }
}

.el-icon-more {
  transform: rotate(90deg);
  margin-left: 10px;
}

.el-icon-more:hover {
  color: #409eff;
}

.el-icon-delete:hover {
  color: red;
}

.list-li {
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  padding: 0 10px;
}

.list-li:hover {
  background: #f0f4fa;
}
</style>
