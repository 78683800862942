<!-- 关联数据的数据选择弹窗 -->
<!--  -->
<template>
  <div class="table-warp">
    <div class="table-search">
      <div class="search-btngroup">
      </div>
      <div class="search-info">
        <el-popover placement="bottom" width="200" trigger="click" v-model="searchShow">
          <div class="search-form">
            <div class="selectBox" @click="changeAll">
              搜索全部
              <i class="el-icon-check" v-show="querySearch.issearchAllFields"></i>
            </div>
            <div class=" selectBox">
              搜索特定字段
              <i :class="filedsListShow == false ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                @click="seacrhfiledsListOpen"></i>
            </div>
            <div v-if="filedsListShow" class="rangeBox">
              <el-checkbox-group v-model="querySearch.searchList" @change="searchListChange">
                <el-checkbox :label="item.__vModel__" v-for="(item, index ) in querysearchList" :key="index">{{
                  item.__config__.fullLabel ?
                  item.__config__.fullLabel : item.__config__.label }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div slot="reference" style="justify-content: unset">
            <el-input placeholder="搜索数据" v-model="querySearch.keyword" clearable @click="seacrhOpen"
              @keyup.enter.native="handleEnter">
              <i slot="suffix" class="el-icon-arrow-down" style="margin-top: 10px"></i>
            </el-input>
          </div>
        </el-popover>

        <!-- 筛选 -->
        <el-popover placement="bottom" width="550" trigger="click" ref="sort" v-model="screenShow" @show="getFilesList"
          style="margin-left: 20px;">
          <div class="screen_form">
            <screenIndex :screenFileds="screenFileds" :formId="formId" @screenSubmit="screenSubmit"
              :screenShow="screenShow" :appId="appId"></screenIndex>
          </div>
          <div class="sort" slot="reference" style="justify-content: unset">
            <i class="iconfont icon-shaixuan1"></i>
            <div>筛选</div>
          </div>
        </el-popover>

      </div>
    </div>
    <!-- :height="tableHeight" -->
    <el-table ref="table" :data="tableData" :row-key="getRowKey" width="100%" height="400px"
      @selection-change="handleSelectionChange" :header-cell-style="{
        background: '#F0F4FA',
        color: '#303133',
        borderRadius: '4px',
      }">
      <el-table-column type="selection" align="center" fixed="left">
      </el-table-column>
      <el-table-column label="提交人" prop="user_id" align="center" key="user" fixed="left" v-if="isShowUserName">
      </el-table-column>
      <el-table-column :label="item.label" align="center" v-for="(item, index) in filed" :key="index" width="200"
        :prop="item.fileds">
        <template slot-scope="scope">
          <div class="col-class" :title="getData(scope.row, item)">
            <img style="height: 32px" v-if="item.tagIcon === 'Sign'" :src="getData(scope.row, item)" />
            <div v-else-if="item.tagIcon === 'Editor'" v-html="getData(scope.row, item)"></div>
            <div v-else>{{ getData(scope.row, item) }}</div>
          </div>
        </template>
        <template v-if="item.tagIcon === 'row'">
          <el-table-column :label="item2.label" width="200" align="center" v-for="(item2, index2) in item.child"
            :key="index2" :prop="item2.fileds">
            <template slot-scope="scope">
              <div class="row-warp">
                <div class="row-class" :title="getTabTableData(item3, item2)"
                  v-for="(item3, index3) in scope.row[item.fileds]" :key="index3">
                  <div v-if="item2.tagIcon === 'Sign'">
                    <img style="height: 32px" :src="getTabTableData(item3, item2, scope.row[item.fileds])
                      " />
                  </div>
                  <div v-else>
                    {{ getTabTableData(item3, item2, scope.row[item.fileds]) }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
        </template>

        <template v-if="item.tagIcon === 'table'">
          <el-table-column v-for="(  tabItem, tabIndex  ) in   item.child  " :key="tabIndex" align="center"
            :label="tabItem.name">
            <el-table-column v-for="(  tabChildItem, tabChildIndex  ) in   tabItem.child  " :key="tabChildIndex"
              width="200" align="center" :label="tabChildItem.label">
              <template slot-scope="scope">
                <div v-if="tabChildItem.tagIcon === 'Sign'">
                  <img style="height: 32px" :src="getData(
                    scope.row[item.fileds],
                    tabChildItem,
                    tabChildIndex
                  )
                    " />
                </div>
                <div v-else-if="tabChildItem.tagIcon === 'Editor'" v-html="getData(scope.row[item.fileds], tabChildItem, tabChildIndex)
                  "></div>
                <div v-else>
                  {{
                    getData(scope.row[item.fileds], tabChildItem, tabChildIndex)
                  }}
                </div>
              </template>
              <div v-if="tabChildItem.tagIcon === 'row'">
                <el-table-column :label="tableItem.label" width="200" align="center"
                  v-for="(tableItem, tableIndex) in tabChildItem.child" :key="tableIndex">
                  <template slot-scope="scope">
                    <div class="row-warp">
                      <div class="row-class" :title="getTabTableData(item3, tableItem)" v-for="(  item3, index3  ) in 
    
    scope.row[item.fileds][
      tabChildItem.fileds
    ]
  
  " :key="index3">
                        <div v-if="tableItem.tagIcon === 'Sign'">
                          <img style="height: 32px" :src="getTabTableData(item3, tableItem)" />
                        </div>
                        <div v-else>
                          {{ getTabTableData(item3, tableItem) }}
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </div>
            </el-table-column>
          </el-table-column>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" key="time" prop="add_time" align="center" fixed="right" width="200"
        v-if="isShowAddTime">
      </el-table-column>
      <!-- <el-table-column min-width="-1" align="center" fixed="right" v-if="tableData.length < 2">
      </el-table-column> -->
    </el-table>

    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
        :page-sizes="[10, 20, 30, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="total" background>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getAppFormData,
  getAppFormDataDetail,
  geteWorkFlowsForm,
  getAppFormView,
  getRelatedData

} from "@/api/app";
import screenIndex from "./components/screenIndex.vue";
export default {
  components: {
    screenIndex
  },
  data () {
    return {
      exportAllCheck: true,
      handleType: "0",
      exportFiledsList: [],
      sortNum: 0,
      preFormInfo: {},
      flow: false,
      name: "",
      filed: [],
      tableData: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      ids: "",
      userName: "",
      showDialog: false,
      manageData: {},
      tableHeight: null,

      formData: {},
      flowsFiled: [],
      nodeControls: [],
      nodeControlsList: [],
      nodeSubmitConditions: [],
      show: false,
      // 显示字段选择列表
      showFiledList: [],
      isIndeterminate: true,
      // 是否展示提交人
      isShowUserName: false,
      // 是否展示提交时间
      isShowAddTime: false,
      // 做筛选的字段
      screenFileds: [],
      // 筛选的弹窗是否打开
      screenShow: false,
      // 选中的行
      selectedRows: [],
      // 搜索条件弹窗是否打开
      searchShow: false,
      querySearch: {
        // 是否搜索全部字段
        issearchAllFields: true,
        searchList: [],
        keyword: "",
      },
      queryFilter: {
        rel: "and",
      },
      filedsListShow: false,
      querysearchList: []
    };
  },
  computed: {
    ...mapGetters(["formInfo", "allMenberList", "allDepartList"]),
  },
  props: ["queryParams", "dataVisible", "formId", "appId", "valueDataId"],

  watch: {
    dataVisible: {
      handler (val) {
        if (val) {
          this.getList()
          if (this.queryParams.fields.length > 0) {
            this.queryParams.fields.forEach(item => {
              if (item.__config__.tagIcon == "user_id") {
                this.isShowUserName = true
              }
              if (item.__config__.tagIcon == "add_time") {
                this.isShowAddTime = true
              }
            })
          }
        }
      },
      immediate: true,
    },
    "queryParams.fields": {
      handler (val) {
        if (val) {
          this.querysearchList = []
          val.forEach(item => {
            if (item.__config__.tagIcon === "row") {
              // if (item.__config__.children) {
              //   item.__config__.children.forEach((itemA) => {
              //     itemA.__config__.fullLabel = `${itemA.rowTitle}--${itemA.__config__.label}`;
              //     itemA.__config__.id
              //     this.querysearchList.push(itemA)
              //   })
              // }
            } else {
              this.querysearchList.push(item)
            }
          })
        }
      },
      immediate: true
    },
    'valueDataId': {
      handler (val) {
        if (val) {
          console.log(val, "这个Id这个Id")
          this.$nextTick(() => {
            this.tableData.forEach(row => {
              if (row._id === val) {
                this.$refs.table.toggleRowSelection(row, true);
              } else {
                this.$refs.table.toggleRowSelection(row, false);
              }
            });
          });
        }
      }
    }
  },

  mounted () {
    setTimeout(() => {
      if (this.valueDataId) {
        this.tableData.forEach(row => {
          if (row._id === this.valueDataId) {
            this.$refs.table.toggleRowSelection(row, true);
          } else {
            this.$refs.table.toggleRowSelection(row, false);
          }
        });
      }
    }, 1000)


    let _this = this;
    window.onresize = () => {
      if (_this.resizeFlag) {
        clearTimeout(_this.resizeFlag);
      }
      _this.resizeFlag = setTimeout(() => {
        _this.getTableHeight();
        _this.resizeFlag = null;
      }, 100);
    };
  },
  methods: {
    getFilesList () {
      this.screenFileds = [];
      let data = this.queryParams.fields
      let typeList = [
        "el-line",
        "el-upload",
        "addr",
        "el-serial",
        "el-position",
        "el-editor",
        "el-sign",
        "row",
        "table",
      ];
      data.forEach((item) => {
        if (
          typeList.indexOf(item.__config__.tag) === -1 &&
          typeList.indexOf(item.__config__.tagIcon) === -1
        ) {
          this.screenFileds.push(item);
        }
      });
    },

    getTableHeight () {
      let tableH = 350; //距离页面下方的高度
      let tableHeightDetil = window.innerHeight - tableH;
      if (tableHeightDetil <= 300) {
        this.tableHeight = 300;
      } else {
        this.tableHeight = window.innerHeight - tableH;
      }
    },
    getMenberDict (data) {
      let arr = [];
      data.forEach((item) => {
        this.allMenberList.forEach((itemA) => {
          if (item === itemA._id) {
            arr.push(itemA.name);
          }
        });
      });

      return arr.join(",");
    },
    getDepartDict (data) {
      let arr = [];

      data.forEach((item) => {
        this.allDepartList.forEach((itemA) => {
          if (item === itemA._id) {
            arr.push(itemA.name);
          }
        });
      });

      return arr.join(",");
    },
    getTabTableData (row, item) {
      if (item.tag === "datePicker") {
        for (let i in row) {
          if (i === item.fileds) {
            return row[i];
          }
        }
      } else if (item.tag === "menberCheck" || item.tag === "menberSingle") {
        for (let i in row) {
          if (i === item.fileds) {
            if (row[i].length > 0) {
              return this.getMenberDict(row[i]);
            }
          }
        }
      } else if (item.tag === "departCheck" || item.tag === "departSingle") {
        for (let i in row) {
          if (i === item.fileds) {
            if (row[i].length > 0) {
              return this.getDepartDict(row[i]);
            }
          }
        }
      } else if (item.tag === "el-upload") {
        for (let i in row) {
          if (i === item.fileds) {
            let arr = [];
            if (row[i].length > 0) {
              row[i].forEach((item) => {
                arr.push(item.name);
              });
            }
            return arr.join(",");
          }
        }
      } else if (item.tag === "el-cascader") {
        for (let i in row) {
          if (i === item.fileds) {
            return row[i].join("/");
          }
        }
      } else if (item.tag === "addr") {
        for (let i in row) {
          if (i === item.fileds) {
            if (Array.isArray(row[i])) {
              return row[i].join("/");
            }
          }
        }
      } else if (item.tag === "el-checkbox-group") {
        for (let i in row) {
          if (i === item.fileds) {
            return row[i].join(",");
          }
        }
      } else if (item.tag === "el-select" || item.tag === "el-checkbox-group") {
        for (let i in row) {
          if (i === item.fileds) {
            if (Array.isArray(row[i])) {
              return row[i].join(",");
            } else {
              return row[i];
            }
          }
        }
      } else {
        for (let i in row) {
          if (i === item.fileds) {
            return row[i];
          }
        }
      }
    },
    handleSelectionChange (val) {
      let lastItem = val.slice(-1)[0];
      this.$nextTick(() => {
        this.tableData.forEach(row => {
          if (row._id === lastItem._id) {
            this.$refs.table.toggleRowSelection(row, true);
          } else {
            this.$refs.table.toggleRowSelection(row, false);
          }
        });
      });
      let list = []
      list.push(lastItem._id)
      this.selectedRows = list

      this.$emit("getData", lastItem)
    },
    getRowKey (row) {
      return row._id;
    },
    getData2 (row, item, index) {
      if (item.tag === "datePicker") {
        for (let i in row) {
          for (let j in row[i]) {
            if (j === item.fileds) {
              return row[i][j];
            }
          }
        }
      } else if (item.tag === "menberCheck" || item.tag === "menberSingle") {
        for (let i in row) {
          for (let j in row[i]) {
            if (j === item.fileds) {
              if (row[i][j].length > 0) {
                return this.getMenberDict(row[i][j]);
              }
            }
          }
        }
      } else if (item.tag === "departCheck" || item.tag === "departSingle") {
        for (let i in row) {
          for (let j in row[i]) {
            if (j === item.fileds) {
              if (row[i][j].length > 0) {
                return this.getDepartDict(row[i][j]);
              }
            }
          }
        }
      } else if (item.tag === "el-upload") {
        for (let i in row) {
          for (let j in row[i]) {
            if (j === item.fileds) {
              let arr = [];
              if (row[i][j].length > 0) {
                row[i][j].forEach((item) => {
                  arr.push(item.name);
                });
              }
              return arr.join(",");
            }
          }
        }
      } else if (item.tag === "el-cascader") {
        for (let i in row) {
          for (let j in row[i]) {
            if (j === item.fileds) {
              return row[i][j].join("/");
            }
          }
        }
      } else if (item.tag === "addr") {
        for (let i in row) {
          for (let j in row[i]) {
            if (j === item.fileds) {
              if (Array.isArray(row[i][j])) {
                return row[i][j].join("/");
              }
            }
          }
        }
      } else if (item.tag === "el-checkbox-group") {
        for (let i in row) {
          for (let j in row[i]) {
            if (j === item.fileds) {
              return row[i][j].join(",");
            }
          }
        }
      } else if (item.tag === "el-select" || item.tag === "el-checkbox-group") {
        for (let i in row) {
          for (let j in row[i]) {
            if (j === item.fileds) {
              if (Array.isArray(row[i][j])) {
                return row[i][j].join(",");
              } else {
                return row[i][j];
              }
            }
          }
        }
      } else {
        for (let i in row) {
          for (let j in row[i]) {
            if (j === item.fileds) {
              return row[i][j];
            }
          }
        }
      }
    },

    getData (row, item) {
      if (item.tag === "menberCheck" || item.tag === "menberSingle") {
        for (let i in row) {
          if (item.fileds === i) {
            return this.getMenberDict(row[i]);
          }
        }
      } else if (item.tag === "departCheck" || item.tag === "departSingle") {
        for (let i in row) {
          if (item.fileds === i) {
            return this.getDepartDict(row[i]);
          }
        }
      } else if (item.tag === "el-upload" || item.tag === "img-upload") {
        for (let i in row) {
          if (item.fileds === i) {
            let arr = [];
            if (row[i] && row[i].length > 0) {
              row[i].forEach((item) => {
                arr.push(item.name);
              });
            }
            return arr.join(",");
          }
        }
      } else if (item.tag === "el-cascader") {
        for (let i in row) {
          if (item.fileds === i) {
            return row[i].join("/");
          }
        }
      } else if (item.tag === "el-select") {
        for (let i in row) {
          if (item.fileds === i) {
            if (Array.isArray(row[i])) {
              return row[i].join(",");
            } else {
              return row[i];
            }
          }
        }
      } else if (item.tag === "datePicker") {
        for (let i in row) {
          if (item.fileds === i) {
            if (Array.isArray(row[i])) {
              return row[i].join(",");
            } else {
              return row[i];
            }
          }
        }
      } else if (item.tag === "el-checkbox-group") {
        for (let i in row) {
          if (item.fileds === i) {
            return row[i].join(",");
          }
        }
      } else if (item.tag === "addr") {
        for (let i in row) {
          if (item.fileds === i) {
            if (Array.isArray(row[i])) {
              return row[i].join("/");
            }
          }
        }
      } else {
        for (let i in row) {
          if (item.fileds === i) {
            return row[i];
          }
        }
      }
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.getList(this.formId);
    },
    handleCurrentChange (val) {
      this.pageNum = val;
      this.getList(this.formId);
    },
    screenSubmit (data) {
      this.queryFilter.cont = data.filterList
      setTimeout(() => {
        this.getList();
      }, 500);
    },
    getList () {
      this.queryParams.pageNum = this.pageNum
      this.queryParams.pageSize = this.pageSize
      this.queryParams.multi = true
      if (this.querySearch.searchList.length > 0) {
        this.querySearch.issearchAllFields = false
      } else {
        this.querySearch.issearchAllFields = true
      }
      this.queryParams.querySearch = this.querySearch
      this.queryParams.queryFilter = this.queryFilter


      getRelatedData(this.queryParams).then((res) => {
        if (res.code == 200) {
          setTimeout(() => {
            this.tableData = res.data.data;
            this.filed = res.data.head;
            this.total = res.data.total;
            this.$nextTick(() => {
              this.$refs.table.doLayout();
            });
            this.$nextTick(() => {
              this.tableData.forEach(row => {
                if (this.selectedRows.indexOf(row._id) != -1) {
                  this.$refs.table.toggleRowSelection(row, true);
                }
              });
            });
          }, 200);
        }
      });
    },
    getAddData () {
      if (!this.formId) {
        return false;
      }
      getAppFormView(this.$route.query.id, this.formId).then((res) => {
        if (res.data.content) {
          if (typeof res.data.content === "string") {
            this.formData = JSON.parse(res.data.content)[0];
          } else {
            this.formData = res.data.content[0];
          }

          this.show = true;
        } else {
          this.show = false;
        }
        if (res.data.work_flows) {
          geteWorkFlowsForm(this.formId).then((res) => {
            geteWorkFlowsForm(this.formId).then((res) => {
              if (res.code == 200) {
                if (res.data.nodeList && res.data.nodeList.length > 0) {
                  let startPermission = "";
                  res.data.nodeList.forEach((item) => {
                    if (item.id === "start") {
                      startPermission = item;
                    }
                  });
                  this.flowsFiled = startPermission.basicfieldPermission;
                  let controls = startPermission.nodeControls;
                  if (startPermission.nodeSubmitOperation == 2) {
                    this.nodeSubmitConditions =
                      startPermission.nodeSubmitConditions;
                  }
                  let obj = {};
                  controls.forEach((item) => {
                    obj[item.type] = item.value;
                  });
                  this.nodeControls = obj;
                  this.nodeControlsList = controls;
                  // this.flowsFiled = res.data.nodeList[0].basicfieldPermission;
                  // let controls = res.data.nodeList[0].nodeControls;
                  // if (res.data.nodeList[0].nodeSubmitOperation == 2) {
                  //   this.nodeSubmitConditions =
                  //     res.data.nodeList[0].nodeSubmitConditions;
                  // }
                  // let obj = {};
                  // controls.forEach((item) => {
                  //   obj[item.type] = item.value;
                  // });
                  // this.nodeControls = obj;
                }
              }
            });
          });
        }
      });
    },

    seacrhOpen () {
      this.searchShow = true
    },
    seacrhfiledsListOpen () {
      this.filedsListShow = !this.filedsListShow

    },
    changeAll () {
      this.querySearch.issearchAllFields = true
      this.querySearch.searchList = []
    },
    searchListChange () {
      if (this.querySearch.searchList.length > 0) {
        this.querySearch.issearchAllFields = false
      } else {
        this.querySearch.issearchAllFields = true
      }
    },
    // 搜索事件
    handleEnter () {
      this.getList()
    }
  },
  created () {
    this.getAddData();
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-input__prefix {
  .el-input__suffix {
    position: absolute;
    top: 10px !important;
  }
}


::v-deep .el-table__header .el-table__cell .el-checkbox {
  display: none;
}

.search-form {
  .selectBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .selected {
    background-color: #eee;
  }

  .rangeBox {

    ::v-deep .el-checkbox,
    .el-checkbox__input {
      display: flex !important;
      align-items: center;
    }
  }

}

.screen_form {
  margin-left: 20px;
}


::v-deep .el-radio-button__inner {
  width: 50px;
  height: 30px;
  padding: 8px 10px !important;
}

::v-deep .el-checkbox {
  margin-bottom: 5px;
}

::v-deep .add-form-box {
  .el-dialog__body {
    height: 90vh;
    padding: unset !important;
  }

  .el-dialog {
    position: absolute;
    bottom: 0;
    margin: 0 auto !important;
    height: 90vh;
  }

  // .el-dialog__wrapper {
  //   z-index: 90 !important;
  // }
}

::v-deep .submit-form {
  .el-dialog__wrapper {
    overflow: auto !important;
  }
}

::v-deep .el-tabs__content {
  height: 100% !important;
}

.table-warp {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.tab-row-item {
  border-bottom: 1px solid #ebeef5;
  padding: 10px 0;
  box-sizing: border-box;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-li {
  margin-bottom: 20px;

  &>div:nth-child(1) {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 400;
    color: #303133;
  }
}

.row-warp {
  height: 100%;

  .row-class:last-child {
    border: unset !important;
  }
}

::v-deep .cell {
  height: 100% !important;
}

::v-deep .el-dialog__wrapper {
  overflow: unset !important;
}

.row-class {
  border-bottom: 1px solid #ebeef5;

  box-sizing: border-box;
  height: 36px;
  line-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.col-class {
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;

  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep .cell {
  padding: unset !important;
}

::v-deep .el-table__cell {
  padding: unset !important;
}

::v-deep .el-table th.el-table__cell>.cell {
  padding: 12px 0 !important;
}

.upload-warp {
  display: flex;
  align-items: center;
}

.form-btn {
  display: flex;
  align-items: center;

  &>div:nth-child(2) {
    height: 32px;
    cursor: pointer;
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;

    span {
      font-size: 14px;
      margin-left: 5px;
      display: inline-block;
      font-weight: 400;
      color: #303133;
    }
  }
}

.sort-li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 0 10px;
  box-sizing: border-box;
  line-height: 2px;
  cursor: pointer;

  i {
    color: #5aabff;
  }
}

.sort-li-active {
  background: #f0f4fa;
  border-radius: 4px;
}

.sort-li:hover {
  background: #f0f4fa;
  border-radius: 4px;
}

.table-warp {
  width: 100%;

  box-sizing: border-box;

  .table-title {
    border-bottom: 1px solid #ebeef5;

    .title-name {
      display: inline-block;
      font-size: 16px;
      border-bottom: 3px solid #409eff;
      font-weight: bold;
      color: #303133;
      border-radius: 1px;
      padding: 12px 0;
      box-sizing: border-box;
      height: 100%;
    }
  }

  .table-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 0;
    box-sizing: border-box;

    .el-button {
      margin-right: 5px;
    }

    .search-info {
      display: flex;
      align-items: center;

      .screen,
      .sort {
        display: flex;
        align-items: center;
        width: 75px;
        cursor: pointer;
        justify-content: center;

        div {
          margin-left: 3px;
          display: block;
          font-size: 14px;
          font-weight: 400;
          color: #303133;

        }
      }
    }
  }

  .pagination {
    text-align: right;
    padding: 20px;
    box-sizing: border-box;
  }
}

.export-fileds-ul {
  border: 1px solid #e0e0e0;
  height: 280px;

  overflow: auto;
}

.export-fileds-li {
  cursor: pointer;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
  background-color: #f2f6ff;

  ::v-deep .el-checkbox {
    margin-bottom: 0px;
  }

  .fileds-name {
    margin-left: 10px;
    margin-top: 2px;
    font-weight: 700;
  }
}

.export-fileds-list {
  cursor: pointer;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  display: flex;
  align-items: center;

  ::v-deep .el-checkbox {
    margin-bottom: 0px;
  }

  .fileds-name {
    margin-left: 10px;
    margin-top: 2px;
    font-size: 14px;
  }

  ::v-deep .el-checkbox__label {
    display: none;
  }
}

.add-form-box {
  ::v-deep .el-dialog {
    // margin: 10vh 0 0 0 !important;
  }

  ::v-deep .menber-select-dialog {
    .el-dialog__body {
      height: auto !important;
    }
  }

  ::v-deep .depart-select-dialog {
    .el-dialog__body {
      height: auto !important;
    }
  }
}
</style>
