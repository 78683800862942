<template>
  <div>
    <div>
      <!-- <i class="el-icon-connection Ricon" v-if="index === 0"
        style="float: left; margin-left: 10px; margin-top: 8px;font-size: 16px;" @click="openDataselect"></i> -->
      <!-- {{ getValue(itemData) }} -->
    </div>

    <el-dialog title="选择数据" :visible.sync="dataVisible" width="30%" append-to-body :modal-append-to-body="false"
      class="dialogBox">
      <relatedDatatable :valueDataId="valueDataId" :queryParams="queryParams" :dataVisible="dataVisible"
        @handleClose="handleClose" @handleSubmit="handleSubmit" @getData="getData" :formId="gl_formId"
        :appId="data.gl_table_appId">
      </relatedDatatable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import relatedDatatable from "@/components/relatedDatatable/index"
export default {
  props: ['data', 'itemData', 'index', 'dataVisible'],
  components: {
    relatedDatatable
  },

  data () {
    return {
      // dataVisible: false,
      queryParams: {},
      gl_formId: "",
      gl_table_appId: "",
      valueDataId: "",
      selectList: []

    }
  },
  watch: {
    data: {
      handler (val) {
      }
    }
  },
  mounted () {
  },
  methods: {
    handleClose () {
      this.dataVisible = false
    },
    handleSubmit () {
      this.valueData = this.selectList
      this.dataVisible = false
      this.gl_data = {
        entryId: this.gl_formId,
        id: this.valueData._id,
        primaryKey: this.data.primaryKey,
        valueData: this.valueData
      }
      let obj = {
        name: this.data.__vModel__,
        value: this.gl_data
      }
      this.$emit("tableValue", obj)
    },
    openDataselect () {
      this.gl_formId = this.data.gl_table_value
      this.queryParams = {
        app_id: this.data.gl_table_appId,
        form_id: this.data.gl_table_value,
        fields: this.data.gl_field_filter,
      }
      if (this.data.relationData.conditionList.length > 0) {
        this.data.relationData.conditionList.forEach(item => {
          this.formDataFields.fields.forEach(item1 => {
            if (item.filterData.zd_data === item1.__vModel__) {
              if (item1.__config__.defaultValue) {
                item.filterData.value = item1.__config__.defaultValue
              }
            }
          })
        })
        this.queryParams.filter = this.data.relationData
      }

      if (this.valueData) {
        this.valueDataId = this.valueData._id
      }
      this.dataVisible = true
    },
    getData (val) {
      this.selectList = val
    },

    getValue (item) {
      let obj = this.valueData;
      if (item.__config__.tagIcon === "row") {
        for (let i in obj) {
          if (i === item.__config__.componentName) {
            return obj[i];
          }
        }
      } else if (item.__config__.tag === "menberCheck" || item.__config__.tag === "menberSingle") {
        for (let i in obj) {
          if (i === item.__vModel__) {
            return this.menberCheckDict(obj[i]);
          }
        }
        return "";
      } else if (item.__config__.tag === "departCheck" || item.__config__.tag === "departSingle") {
        for (let i in obj) {
          if (i === item.__vModel__) {
            return this.departCheckDict(obj[i]);
          }
        }
        return "";
      } else if (item.__config__.tag === "el-cascader") {
        for (let i in obj) {
          if (i === item.__vModel__) {
            return obj[i].join("/");
          }
        }
      } else if (item.__config__.tag === "addr") {
        for (let i in obj) {
          if (i === item.__vModel__) {
            if (Array.isArray(obj[i])) {
              return obj[i].join("/");
            }
          }
        }
      } else if (item.__config__.tag === "el-select") {
        for (let i in obj) {
          if (i === item.__vModel__) {
            if (Array.isArray(obj[i])) {
              return obj[i].join(",");
            } else {
              return obj[i];
            }
          }
        }
      } else if (item.__config__.tag === "el-checkbox-group") {
        for (let i in obj) {
          if (i === item.__vModel__) {
            return obj[i].join(",");
          }
        }
      } else if (item.__config__.tag === "el-upload") {
        for (let i in obj) {
          if (i === item.__vModel__) {
            return obj[i];
          }
        }
      } else if (item.__config__.tag === "img-upload") {
        for (let i in obj) {
          if (i === item.__vModel__) {
            let list = []
            if (obj[i]) {
              obj[i].forEach((itemA) => {
                itemA.urls = `${window.location.origin}/api/upload/handleRequest?attachmen_id=` + itemA.response.data.attachment_id
                list.push({
                  url: itemA.urls,
                  name: itemA.name
                })
              });
            }
            return list;
          }
        }

      } else if (item.__config__.tagIcon === "table") {
        return "标签页";
      } else {
        for (let i in obj) {
          if (i === item.__vModel__) {
            return obj[i];
          }
        }
      }
    },

  }
}
</script>
<style lang="scss" scoped>
.Ricon:hover {
  color: #409eff;
}
</style>