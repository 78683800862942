<template>
  <div>
    qq
  </div>
</template>
<script>
export default {
  props: ['data'],
  data () {
    return {

    }
  },
  watch: {
    "data": {
      handler (val) {
        console.log(val, "datadata")
      }
    }
  },
  mounted () {
    console.log(this.data, "datadata")
  }
}
</script>